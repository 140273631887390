import React from "react";

import "./LandingCTA.css";

function LandingCTA(props) {

  return (
    <a className="cta-card-link" href={props.href} onClick={props.onClick} data-pointerz-id={props?.pointerzId}>
      <div className={props.showDropShadow ? `cta-card warning shadow` : `cta-card`}>
        <div className="item-container">
          <div className="avatar">
            <img src={props.image} alt={props.title} />
          </div>
          <div className="wrapper">
          {props.showCount && 
          <>
            <div className="count">{props.count}</div>
            <div className="title">{props.title}</div>
          </>
          }
          {!props.showCount &&
            <div className="title-no-count">{props.title}</div>
          }
          </div>
        </div>
      </div>
    </a>
  )
}

export default LandingCTA;
