import "./SelectedCountBadge.css";
function Badge({ value, showBounce }) {
    return (

        <div className={ showBounce ? 'selected-count-badge animate-bounce' : 'selected-count-badge' }>
            {value}
        </div>
    )
};

export default Badge;
