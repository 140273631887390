

export var InputMasks = {




	Numeric: function (val) {

		//val = val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		// keep only digits NO comma punctuation
		val = val.replace(/[^\d]/g, '')
		//console.log("val", val)

		return val




	},

	// CurrencyWith2DecimalPlaces: function(val) {

	// 	// first, keep only digits 
	// 	val = val.replace(/[^\d]/g,'')
	// 	console.log("val", val)

	// 	// next, split out the forced 2 decimal places
	// 	// e.g. 123456 is split as left: 1234, right: 56
	// 	let left_side = val.substring(0, val.length -2)
	// 	let right_side = val.substring(val.length -2)

	// 	// next, for the left side, apply commas
	// 	left_side = left_side.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")

	// 	console.log("left", left_side, "right", right_side)

	// 	// combined results
	// 	return left_side + "." + right_side

	// },

	CurrencyWithoutDecimalPlaces: function (val) {

		// first, keep only digits 
		val = val.replace(/[^\d]/g, '')
		//console.log("val", val)

		val = val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		return val


	},

	Phone: function (val) {

		try {

			if (val.length === 0) {
				return ""
			}

			if (val.length > 12) {
				val = val.substring(0, 12)
			}

			const match = val
				.replace(/\D+/g, '').replace(/^1/, '')
				.match(/([^\d]*\d[^\d]*){1,10}$/)[0]
			const part1 = match.length > 2 ? `${match.substring(0, 3)}` : match
			const part2 = match.length > 3 ? `-${match.substring(3, 6)}` : ''
			const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''
			return `${part1}${part2}${part3}`

		}
		catch (ex) {
			return ""
		}


	}



}