/*

This component needs an array of items that contain id and title properties.
if the list is longer than 5, use an ellipsis dropdown to hide things using this pattern: 
first > second > {dropdown of others } > prev > current
Example:
Filter Options > Daily Summary > ... > Dinner > Steaks

Rendering items in the return() from state instead of props.items will allow the list to be static for each Offcanvas report in the stack. 
This eliminates the "flicker" with the list of breadcrumbs when reports are removed from the stack

*/

import React, { useEffect, useState } from "react";

import {
    Dropdown,
} from "react-bootstrap";

import "./Styles/Breadcrumbs.css";

function Breadcrumbs(props) {

    // useState to, in effect, "freeze" the breadcrumb list for this instance of the report
    const [items, setItems] = useState(props.items);

    useEffect(() => {
        console.log("props.items", props.items);
    }, []);

    useEffect(() => {

        console.log("force rerender");
    }, [props.forceRerender]);

    function buildItemList(items) {

        let list = [];
        let dropdownItems = [];
        let prev = null;

        if(props.filterOptionsAtRoot) {
            let root = { index: -1, id: 0, title: "Filter Options" }
            list.push(<li key={'filter-options'} className="breadcrumb-item"><a href="#" onClick={() => props?.onSelectBreadcrumb(root)}>Filter Options</a></li>)
        }

        if(items.length < 5) {

            items.map((item, index) => {
                // console.log("items item", item)
                if(index !== items.length -1) {
                    list.push(<li key={index} className="breadcrumb-item"><a href="#" onClick={() => props?.onSelectBreadcrumb(item)}>{item.title}</a></li>)
                }
                else {
                    list.push(<li key={index} className={"breadcrumb-item active"}>{item.title}</li>)
                }
            })

        }
        // lets break things up a bit...
        else if(items.length >= 5) {

            items.map((item, index) => {
                if(index === 0 || index === 1) {
                    list.push(<li key={index} className="breadcrumb-item"><a href="#" onClick={() => props?.onSelectBreadcrumb(item)}>{item.title}</a></li>)
                }
                // get the previous
                else if(index === items.length -2) {
                    prev = <li key={index} className="breadcrumb-item"><a href="#" onClick={() => props?.onSelectBreadcrumb(item)}>{item.title}</a></li>;
                }
                // dont include the current and previous...
                else if(index !== items.length -2 && index < items.length -1) {
                    dropdownItems.push(<Dropdown.Item key={index} href="#" onClick={() => props?.onSelectBreadcrumb(item)}>{item.title}</Dropdown.Item>)
                }
            })

            // build the dropdown if necessary...
            if(dropdownItems.length) {
                list.push(
                    <li key={'dropdown'} className="breadcrumb-item">
                        <div className="dropdown">
                            <div className="dropdown-button">...</div>
                            <div className="dropdown-content">
                                {dropdownItems}
                            </div>
                        </div>
                    </li>
                );
            }

            // finally, push in the current item at the end
            list.push(prev)

            list.push(<li key={'last'} className={"breadcrumb-item active"}>{items[items.length-1].title}</li>)
        }

        return list;
    }

    return (
        <nav className="breadcrumb-nav">
            <ol className="breadcrumb">
                {buildItemList(items)} 
            </ol>
        </nav>
    );
}

export default Breadcrumbs;