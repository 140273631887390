import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Form,
    ProgressBar,
    Modal,
    Table,
    Offcanvas,
    Tabs,
    Tab,
    ButtonGroup
} from "react-bootstrap";

import { ArrowLeftCircle } from "react-feather";

import cloneDeep from "lodash/cloneDeep";

import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
import ButtonGroupToggle from "../../Components/Form/ButtonGroupToggle/ButtonGroupToggle";
import PillButtonGroup from "../../Components/Form/PillButtonGroup/PillButtonGroup";

import "./Styles.css";
import "../../Styles/OffcanvasShared.css";


const defaultTree = {
    "type": "",
    "useTips": "false",
    "tipTypes": [],
    "tipTypesCombined": "false",
    "tipTypesToCombine": []

}


function PayrollExport() {

    const [treeData, setTreeData] = useState(defaultTree);

    useEffect(() => {

        document.title = 'Payroll Export | PowerCenter';
    }, []);

    useEffect(() => {

        console.log("treeData", JSON.stringify(treeData, null, 2))

    }, [treeData]);

    function updateTree(prop, value) {
        console.log("updateTree", prop, value)
        setTreeData({ ...treeData, [prop]: value })

        setTimeout(() => {
            window.scrollBy({
                top: 300,
                behavior: "smooth",
            });
        }, 200)
    }

    return (
        <Container fluid className="export">

            <TopNav dataTransferSuccess={true} />

            <Row className="justify-content-md-center mb-5 pt-3">

                <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                    <div className="mb-3">
                        <Button variant="link" className="btn-link-off" onClick={() => console.log("back")}><ArrowLeftCircle size={20} color={'gray'} style={{ marginRight: 5 }} />Back to list</Button>
                    </div>

                    <section className="mb-3">
                        <h4>Payroll Export Settings</h4>
                        <p className="mb-0">To create a customized payroll export file, use these settings to
                            select the columns and formats that align with your payroll system's requirements.</p>
                    </section>

                    <section className="mb-3">

                        <h4>Configuration</h4>

                        <p>This section collects essential information about your export file's settings, including its name, a brief description, and the associated filename.</p>

                        <p><i>* Suggested option for demo purposes.</i></p>

                        <form className="mt-4">
                            <div className="mt-4 mb-3">
                                <div className="form-label fw-bold">Which type of export would you like to do?</div>
                                <PillButtonGroup
                                    // if you want to pre-select one of the options in your list
                                    defaultOption={null}
                                    allowMultiple={false}
                                    options={[{ label: "Payroll*", value: "payroll" }, { label: "Employee", value: "employee" }]}
                                    onSelectOption={(list) => updateTree('type', list[0].value)}
                                    classOptions={{ selected: "success", notSelected: "outline-secondary" }}
                                />
                            </div>

                            {treeData?.type === "payroll" &&
                                <div className="mt-4 mb-3">
                                    <div className="form-label fw-bold">Do you use tips?</div>
                                    <PillButtonGroup
                                        // if you want to pre-select one of the options in your list
                                        defaultOption={null}
                                        allowMultiple={false}
                                        options={[{ label: "Yes*", value: "true" }, { label: "No", value: "false" }]}
                                        onSelectOption={(list) => updateTree('useTips', list[0].value)}
                                        classOptions={{ selected: "success", notSelected: "outline-secondary" }}
                                    />

                                </div>
                            }

                            {treeData?.type === "payroll" && treeData?.useTips === "true" &&
                                <div className="mt-4 mb-3">
                                    <div className="form-label fw-bold">Which tip types do you want to include?</div>
                                    <PillButtonGroup
                                        // if you want to pre-select one of the options in your list
                                        defaultOption={null}
                                        allowMultiple={true}
                                        options={[{ label: "Cash Tips", value: "cash" }, { label: "Credit Card Tips", value: "credit-card" }, , { label: "Shared Tips", value: "shared" }]}
                                        onSelectOption={(list) => updateTree('tipTypes', list)}
                                        classOptions={{ selected: "success", notSelected: "outline-secondary" }}
                                    />

                                </div>
                            }

                            {treeData?.type === "payroll" && treeData?.tipTypes?.length !== 0 &&
                                <div className="mt-4 mb-3">
                                    <div className="form-label fw-bold">Do you want your tips combined into one value or separated?</div>
                                    <PillButtonGroup
                                        // if you want to pre-select one of the options in your list
                                        defaultOption={null}
                                        allowMultiple={false}
                                        options={[{ label: "Combined*", value: "true" }, { label: "Separated", value: "false" }]}
                                        onSelectOption={(list) => updateTree('tipTypesCombined', list[0].value)}
                                        classOptions={{ selected: "success", notSelected: "outline-secondary" }}
                                    />

                                </div>
                            }

                            {treeData?.type === "payroll" && treeData?.tipTypesCombined === "true" &&
                                <div className="mt-4 mb-3">
                                    <div className="form-label fw-bold">Which tips types would you like combined?</div>
                                    <p>Selected tips to combine will be shown as one value on the export</p>
                                    <PillButtonGroup
                                        // if you want to pre-select one of the options in your list
                                        defaultOption={null}
                                        allowMultiple={true}
                                        options={[{ label: "Cash Tips", value: "cash" }, { label: "Credit Card Tips", value: "credit-card" }, , { label: "Shared Tips", value: "shared" }]}
                                        onSelectOption={(list) => updateTree('tipTypesToCombine', list)}
                                        classOptions={{ selected: "success", notSelected: "outline-secondary" }}
                                    />

                                </div>
                            }

                            {treeData?.type === "payroll" && treeData?.tipTypesToCombine?.length > 0 &&
                                <div className="mt-4 mb-3">
                                    <div className="form-label fw-bold">Are there any Earnings Codes associated with tips?</div>
                                    <PillButtonGroup
                                        // if you want to pre-select one of the options in your list
                                        defaultOption={null}
                                        allowMultiple={false}
                                        options={[{ label: "Yes*", value: "true" }, { label: "No", value: "false" }]}
                                        onSelectOption={(list) => updateTree('earningsCodesYesNo', list[0].value)}
                                        classOptions={{ selected: "success", notSelected: "outline-secondary" }}
                                    />

                                </div>
                            }

                            {treeData?.type === "payroll" && treeData?.earningsCodesYesNo === "true" &&
                                <div className="mt-4 mb-3">
                                    <div className="form-label fw-bold">Enter your Earnings Code values below</div>
                                    <p>For each selected Earnings Code, please enter your preferred Earnings Code value.</p>

                                    <form className="mt-4">

                                        {treeData?.tipTypesToCombine?.map((ec, ecNdx) => (
                                            <div key={ecNdx} className="mb-3">
                                                <label htmlFor={ec.label} className="form-label">{ec.label}</label>
                                                <input type="text" className="form-control w-25" id={ec.label} placeholder="" />
                                            </div>
                                        ))}



                                    </form>

                                </div>
                            }

                        </form>

                    </section>

                    {treeData?.type === "payroll" && treeData?.earningsCodesYesNo === "true" &&
                        <section className="mb-3">
                            <h4>File Delivery Method</h4>
                            <p className="mb-0">In this section, the user can configure their file delivery requirements.</p>
                        </section>
                    }


                </Col>

            </Row>


        </Container>
    );
}

export default PayrollExport;