import React, { useState, useEffect } from "react";

import {
  Offcanvas,
  Row,
  Col
} from "react-bootstrap";

import cloneDeep from "lodash/cloneDeep";

import ColorPalette from "../ColorPalette/ColorPalette";
import ColorPaletteList from "../ColorPalette/ColorPaletteList";

import { logActivity } from "../../Services/Activity";

// color palette source
import paletteList from "../../Fixtures/color-palettes.json";

import "./OffcanvasColorPalette.css";

function OffcanvasHelp(props) {

  const [colorPalettes, setColorPalettes] = useState([]);
  const [selectedPalette, setSelectedPalette] = useState(null);

  useEffect(() => {

    console.log("set colors", paletteList)

    setColorPalettes(paletteList);
    setSelectedPalette(paletteList[0]);

    // just to demo persisting the selected palette
    const savedPalette = JSON.parse(localStorage.getItem("colorPalette"));
    console.log("saved palette?", savedPalette)
    if (savedPalette) {
      let found = paletteList.find((s) => s.id === savedPalette.id);
      if (found) {
        // Important: we will want to present the colors in the order that they saved them...
        setSelectedPalette(savedPalette);

        console.log("saved", savedPalette)
        found.colors = savedPalette.colors;
        setColorPalettes(paletteList); // this updates the master list to include the user's customized order
      }
    }

    logActivity({ action: "colors" })


  }, []);

  useEffect(() => {

    if (selectedPalette) {
      console.log("changed", selectedPalette)

      props.onColorPaletteChange(selectedPalette)
    }

  }, [selectedPalette]);

  function onColorPaletteChange(palette) {
    //console.log("on select", palette);
    setSelectedPalette(palette);


  }

  function onMoveColor(palette, item, newIndex) {

    //console.log("onMoveColor", item, newIndex);
    let copyPalettes = cloneDeep(colorPalettes);
    let foundPalette = copyPalettes.find((s) => s.id === palette.id);
    if (foundPalette) {

      let list = [];
      // get rid of the color being moved
      let wo = foundPalette.colors.filter((s) => s !== item);
      //console.log("wo", JSON.stringify(wo));
      for (let i = 0; i < wo.length; i++) {
        // jam it back into its new index position
        if (i === newIndex) {
          list.push(item);
        }
        // push in the next one
        list.push(wo[i]);
      }
      // if it is being moved to the end...
      if (newIndex === foundPalette.colors.length - 1) {
        list.push(item);
      }

      //console.log("list", JSON.stringify(list));
      foundPalette.colors = list;

      //console.log("set", JSON.stringify(foundPalette));
      // just to demo persisting the selected palette
      localStorage.setItem("colorPalette", JSON.stringify(foundPalette));

      setColorPalettes(copyPalettes);

    }

  }

  function handleClose() {
    logActivity({ action: "colors closed" })
    props.onClose()
  }
  

  return (
    <Offcanvas
      show={props?.show}
      onHide={handleClose}
      placement="end"
      className="oc-shared width-50"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>Select a Color Palette</Offcanvas.Title>
        {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={props.onClose}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body>


        <Row>
          <Col xs lg="12">

            <section>

              <p className="mb-3">Select a color palette that you would like to use for your dashboards. Use drag-and-drop to rearrange the order of the colors.</p>

              <p className="mb-4"><strong>Please note:</strong> Selecting a color palette will apply to all of your dashboards.</p>

              <ColorPaletteList onColorPaletteChange={props.onColorPaletteChange} onTriggerDisplayRefresh={props.onTriggerDisplayRefresh} />
       
            </section>
          </Col>
        </Row>



      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffcanvasHelp;
