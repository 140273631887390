const conversionList = [
	{ from: "", to: "each" },
	{ from: "individual", to: "each" },
	{ from: "pound", to: "lb" },
	{ from: "pounds", to: "lb" },
	{ from: "bottles", to: "bottle" },
	{ from: "cans", to: "can" },
	{ from: "ounces", to: "oz" },
	{ from: "ounce", to: "oz" },
	{ from: "liters", to: "liter" },
	{ from: "l", to: "liter" },
	{ from: "cases", to: "case" },
	{ from: "bags", to: "bag" },
	{ from: "bunches", to: "bunch" },
]

export const uomConverter = function (uom) {

	// caused an error one time as null/undefined
	if(uom === null || uom === undefined) uom = "";

	let found = conversionList.find(function(item){
		return item.from === uom.toLowerCase()
	})

	if(found) { 
		return found.to
	}
	else {
		return uom; 
	}
}