import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import OffcanvasDatePicker from "../../Components/OffcanvasDatePicker/OffcanvasDatePicker";

import { BeatLoader } from "react-spinners";

import dayjs from "dayjs";

import "../../Styles/Offcanvas.css";

const list = [
  {
    eventId: 1,
    "title": "Daily Summary Report",
    "description": "Provides a comprehensive overview of daily activities, displaying regions or locations side by side.",
    startDate: "2023-05-06 11:41 AM",
    allDay: false,
    ready: false
  },
  {
    eventId: 2,
    "title": "Product Mix",
    "description": "Optimize employee work locations by assigning a home location for those who have worked at multiple places.",
    startDate: "2023-05-06 3:17 PM",
    allDay: false,
    ready: true
  },
  {
    eventId: 3,
    "title": "AvsT Summary",
    "description": "Concise summary comparing Average versus Target performance.",
    startDate: "2023-05-06 6:21 PM",
    allDay: false,
    ready: true
  }
];

const listGrouped = [
  {
    date: "Wednesday, May 10 2023",
    list
  },
  {
    date: "Thursday, May 11 2023",
    list
  }
];

function OffcanvasDownloads(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      //console.log("isLoading false", new Date());
    }, 1000);
  }, [props.forceRerender]);

  return (
    <Offcanvas
      show={props.show}
      onHide={props.onClose}
      placement="end"
      className="oc-custom"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>Reports Queued for Download</Offcanvas.Title>
        {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={props.onClose}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="calendar">
        <OffcanvasDatePicker
          onDateChange={(date) => console.log("calendar date change", date)}
        />
        {isLoading && (
          <div className="centered">
            <BeatLoader color={"#004b87"} />
          </div>
        )}

        <>
          {!isLoading && (
            <div className="list-container">
              {listGrouped &&
                listGrouped.map((group, index) => {
                  return (
                    <div key={index} className="group-container">
                      <div className="group-title">{group.date}</div>
                      {list &&
                        list.map((item, index) => {
                          return (
                            <div key={index} className="list-item">
                              <div className="item-container">
                                <div className="avatar">
                                  <img
                                    className="icon"
                                    src={item.ready ? "/img/icon-download-ready.svg" : "/img/icon-download-pending.svg"}
                                    alt="Download"
                                  />
                                </div>

                                <div className="title-container">
                                  <div className="title truncate-line-clamp">
                                    <span className="time">

                                      {item.title}

                                    </span>
                                  </div>

                                  <div className="description truncate-line-clamp">
                                    {item.description}
                                  </div>

                                  <span className="sub-title">

                                    Requested at&nbsp; 
                                    {item.allDay && <>All Day</>}
                                    {!item.allDay &&
                                      dayjs(item.startDate.toString()).format(
                                        "hh:mm A"
                                      )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          )}
        </>


        <img
          className="plus-button"
          src={"/img/icon-list-add.svg"}
          alt="Add"
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffcanvasDownloads;
