/*

NOTE: 
Anchor tags are intended for navigation.
Buttons should be used when performing actions within the app.
This component offers an anchor-like behavior for buttons 
and can contain things like icons, or text.

*/

import React from "react";
import { Button } from "react-bootstrap";

const ButtonLikeAnchor = ({ children, onClick, icon }) => {
  const buttonStyles = {
    cursor: "pointer",
    padding: 0,
    margin: 0,
  };

  return (
    <Button variant="none" style={buttonStyles} onClick={onClick}>
      {children}
    </Button>
  );
};

export default ButtonLikeAnchor;
