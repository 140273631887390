import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "./Styles/App.css";
import "./Styles/Animation.css";
import "./Styles/Button.css";
import "./Styles/ListItem.css";
import "./Styles/Table.css";
import "./Styles/Modal.css";
import "./Styles/Section.css";
import "./Styles/Pill.css";
import "./Styles/VerticalTab.css";

import PageNotFound from './Pages/404/404';
import Landing from './Pages/Landing/Index';
import Loading from './Pages/Loading/Index';
import Report from './Pages/Report/Index';
import Dashboards from './Pages/Dashboards/Index';
import Test from './Pages/Test/Index';
import PayrollExport from "./Pages/PayrollExport/Index";
import PayrollExportDecisionTree from "./Pages/PayrollExport/DecisionTree";
import Survey from "./Pages/Survey/Index";
import AssignShareSectionList from "./Pages/AssignSharePermissions/SectionList";
import UIGuide from "./Pages/UIGuide/Index";
import UpNext from "./Pages/UpNext/Index";
import UpNextResponsive from "./Pages/UpNext/Responsive";
import Maintenance from "./Pages/Maintenance/Index";
import VoiceInventory from "./Pages/VoiceInventory/VoiceInventory";

// import AssignSharePermissions from "./Pages/AssignSharePermissions/Index";
// import AssignShareGroupedList from "./Pages/AssignSharePermissions/GroupedList";
import Home from './Pages/Home/Index';
import Home2 from './Pages/Home/Index2';
// import Scrollers from "./Components/Scrollers/Scrollers";
// import Pointerz from "./Helpers/Pointerz";
// import Calendar from "./Pages/Calendar/Calendar";
// import Weekly from "./Pages/Calendar/Weekly";

import { generateGUID } from "./Helpers/GUID";
// import { logActivity } from "./Services/Activity";

function App() {

    // generate a visitor id to track views
    const vuid = localStorage.getItem('vuid')
    if (!vuid) {
        localStorage.setItem('vuid', generateGUID())
    }

    // let showPointerz = false;
    // localStorage.setItem('pointerz', showPointerz);
    // if(window.location.search.toLowerCase().indexOf('pointerz=true') !== -1) {
    //     showPointerz = true;
    //     if(window.location.search.toLowerCase().indexOf('pointerz=true&reset=true') !== -1) {
    //         localStorage.setItem('pointerz-viewed', []);
    //     }
    //     localStorage.setItem('pointerz', true);
    // }
    // turn off pointerz when showing this gag...
    // if(showPointerz) {
    //     const pointerz = new Pointerz();
    //     pointerz.init();
    // }

    // useEffect(() => {
    //     logActivity({ action: 'loaded' })
    // }, []);

    return (
        <>
            <BrowserRouter>
                <Routes>

                    <Route path="/" element={<Navigate to="/loading" />} />
                    <Route element={<Loading />} path="/loading" />

                    {/* This is the new combo landing page that lets the user build their own dashboard and display it as the landing page */}
                    <Route element={<Landing />} path="/home" />

                    {/* These are the current/old home page with KPI widgets */}
                    <Route element={<Home />} path="/home1" />
                    <Route element={<Home2 />} path="/home2" />

                    {/* The new generic reporting page  */}
                    <Route element={<Report />} path="/report" />
                    <Route element={<Dashboards />} path="/dashboards" />
                    <Route element={<AssignShareSectionList />} path="/admin/assign-share-list" />
                    <Route element={<UIGuide />} path="/ui-guide" />
                    <Route element={<UpNext />} path="/upnext" />
                    <Route element={<UpNextResponsive />} path="/upnext2" />
                    <Route element={<Maintenance />} path="/maintenance" />

                    {/* Prototypes for other teams */}
                    <Route element={<PayrollExport />} path="/payroll-export" />
                    <Route element={<PayrollExportDecisionTree />} path="/payroll-export-decision-tree" />
                    <Route element={<VoiceInventory />} path="/voice-inventory" />
                    <Route element={<Survey />} path="/survey" />

                    <Route
                        path="*"
                        element={<PageNotFound />}
                    />

                    <Route element={<Test />} path="/test" />

                    {/* <Route element={<Calendar />} path="/calendar" /> */}
                    {/* <Route element={<Weekly />} path="/weekly" /> */}
                    {/* <Route element={<AssignSharePermissions />} path="/admin/assign-share" /> */}
                    {/* <Route element={<AssignShareGroupedList />} path="/admin/assign-share-grouped" /> */}

                </Routes>
            </BrowserRouter>

            {/* Up and down scrollers instead of a scrollbar */}
            {/* <Scrollers /> */}
        </>
    );
}

export default App;