import React, { useState, useEffect } from 'react';
import cloneDeep from "lodash/cloneDeep";
import FieldCard from "./FieldCard";
import './Fields.css';

// const defaultList = [
//   { id: 1, title: 'POS Emp Name', description: 'The name of the employee in the point-of-sale system', checked: true, sampleData: ["Larry", "BOB", "george", "blank", "biLL"] },
//   { id: 2, title: 'Business Date', description: 'The business date for the employee compensation', checked: true, sampleData: ["2024-06-01", "6/1/2024", "06/01/2024", "blank", "NULL"] },
//   { id: 3, title: 'Base Rate', description: 'The base rate for employee compensation', checked: true, sampleData: ["11.50", "null", "15.25", "0", "-2.25"] },
//   { id: 4, title: 'Hours Worked', description: 'The hours recorded for an employee by location', checked: true, sampleData: ["null", "NULL", "30", "25000", "-400"] },
//   { id: 5, title: 'Location', description: 'The location that employee compensation applies to', checked: true, sampleData: [ "Wood River", "NULL", "blank", "Arlington", "springfield"] },
// ]
// const defaultList = [
//     { id: 1, title: 'pos_emp_name', customTitle: 'Employee Name', sampleData: ["Larry", "BOB", "george", "blank", "biLL"] },
//     { id: 2, title: 'bus_date', customTitle: '', sampleData: ["2024-06-01", "6/1/2024", "06/01/2024", "blank", "NULL"] },
//     { id: 3, title: 'base_rate', customTitle: '', sampleData: ["11.50", "null", "15.25", "0", "-2.25"] },
//     { id: 4, title: 'hrs_worked', customTitle: '', sampleData: ["null", "NULL", "30", "25000", "-400"] },
//     { id: 5, title: 'location', customTitle: '', sampleData: [ "Wood River", "NULL", "blank", "Arlington", "springfield"] },
// ]

const FieldList = ({ items, onFieldsChanged, onMoreClicked, onDeleteClicked, onEditCustomClicked }) => {
  //const [items, setItems] = useState(defaultFieldList);
  const [draggingIndex, setDraggingIndex] = useState(null);


  // if the list changes in any way, such as being reordered or an item is checked/unchecked,
  // bubble the updated list to the parent
  useEffect(() => {
    onFieldsChanged(items)
  }, [items, onFieldsChanged]);


  const handleDragStart = (index) => {
    setDraggingIndex(index);
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (draggingIndex === null || draggingIndex === index) return;
    const newItems = [...items];
    const [draggedItem] = newItems.splice(draggingIndex, 1);
    newItems.splice(index, 0, draggedItem);
    setDraggingIndex(index);
    // setItems(newItems);
    onFieldsChanged(newItems)
  };

  function onSelectionChanged(item) {

    let copyList = cloneDeep(items)
    let found = copyList.find(e => e.id === item.id)
    if(found) {
        found.customTitle = item.customTitle
    }
    onFieldsChanged(copyList)
}

  return (
    <div className="fcard">
      {items && items?.map((item, index) => (
        <div
          key={index}
          className={`draggable ${draggingIndex === index ? 'dragging' : ''}`}
          draggable
          onDragStart={() => handleDragStart(index)}
          onDragEnd={handleDragEnd}
          onDragOver={(e) => handleDragOver(e, index)}
        >
          <FieldCard field={item} onSelectionChanged={onSelectionChanged} onMoreClicked={onMoreClicked} onDeleteClicked={onDeleteClicked} onEditCustomClicked={onEditCustomClicked} />
        </div>
      ))}
      </div>
  );


};

export default FieldList;
