import React, { useState, useEffect } from "react";

import { Offcanvas, Button } from "react-bootstrap";

import OffcanvasDatePicker from "../OffcanvasDatePicker/OffcanvasDatePicker";

import { ChevronRight, ChevronLeft, Link, RefreshCw } from "react-feather";

// import "./App.css";
// import "./Offcanvas.css";
import "./OffcanvasTasklist.css";
// import "./ListItem.css";

import { BeatLoader } from "react-spinners";

import dayjs from "dayjs";

const list = [
  {
    count: 17,
    countCompleted: 1,
    Tasklist_ID: 30,
    Tasklist_Title: "Daily Open Tasks",
    Tasklist_Time_Formatted: "4:00 am"
  },
  {
    count: 32,
    countCompleted: 0,
    Tasklist_ID: 30,
    Tasklist_Title: "Status Update",
    Tasklist_Time_Formatted: "7:00 am"
  },
  {
    count: 17,
    countCompleted: 0,
    Tasklist_ID: 30,
    Tasklist_Title: "Food Cost Report",
    Tasklist_Time_Formatted: "6:00 pm"
  },
  {
    count: 24,
    countCompleted: 0,
    Tasklist_ID: 30,
    Tasklist_Title: "AM Tasks",
    Tasklist_Time_Formatted: "10:00 pm"
  }
];

const tasks = [
  {
    Step_ID: 1,
    Step_Title: "Aloha End of Day",
    Step_Description:
      "Verify that End of Day completed. Contact Wendy's help desk if there are problems...",
    CompletedByName: "John Smith",
    Completed_Date: "2023-05-11 4:59 pm",
    URL: null
  },
  {
    Step_ID: 2,
    Step_Title: "Verify Safe",
    Step_Description: "Verify the safe and record on Cash Control Envelope",
    CompletedByName: "",
    Completed_Date: "0001-01-01T00:00:00",
    URL: null
  },
  {
    Step_ID: 3,
    Step_Title: "VOC comments/data reviewed",
    Step_Description: "Review comments and VOC data from yesterday",
    CompletedByName: "",
    Completed_Date: "0001-01-01T00:00:00",
    URL: "http://www.weather.com"
  }
];

function OffcanvasTasklist(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [visiblePanel, setVisiblePanel] = useState(1);
  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      // console.log("isLoading false", new Date());
    }, 500);
  }, [props.forceRerender]);

  function showList(list) {
    setSelectedList(list);
    setVisiblePanel(2);
  }

  function onHide() {
    console.log("onHide");
    setVisiblePanel(1); // default back to panel 1
    setIsLoading(true);
    props.onClose();
  }

  return (
    <Offcanvas
      show={props.show}
      onHide={onHide}
      placement="end"
      className="oc-custom"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>Tasklist</Offcanvas.Title>
        {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          onClick={props.onClose}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="tasklist">
      <OffcanvasDatePicker
          onDateChange={(date) => console.log("calendar date change", date)}
        />
        {isLoading && (
          <div className="centered">
            <BeatLoader color={"#004b87"} />
          </div>
        )}

        {!isLoading && (
          <div
            className={`list-container panel1 ${
              visiblePanel === 1 ? "show" : "hide"
            }`}
          >
            {list &&
              list.map((item, index) => {
                return (
                  <div key={index} className="list-item" onClick={() => showList(item)}>
                    <div className="item-container">
                      <div className="avatar">
                        <img src="./img/icon-tasklist.svg" alt="Tasklist" />
                      </div>
                      <div className="title-container">
                        <div className="title">{item.Tasklist_Title}</div>
                        <div className="sub-title">
                          {item.Tasklist_Time_Formatted}
                        </div>
                        {/* <div className="description"></div> */}
                      </div>
                      <span className="count">
                        {item.countCompleted} of {item.count}
                      </span>
                      <Button variant="Hyperlink" className="chevron">
                        <ChevronRight color={"#004b87"} size={20} />
                      </Button>
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        <div
          className={`list-container panel2 ${
            visiblePanel === 2 ? "show" : "hide"
          }`}
        >
          {selectedList && (
            <div className="selected-list-container">
              <Button
                variant="link"
                style={{ padding: 0 }}
                onClick={() => setVisiblePanel(1)}
              >
                <ChevronLeft color={"#004b87"} size={30} />
              </Button>

              <span className="list-name">{selectedList.Tasklist_Title}</span>
              <span className="count">
                {selectedList.countCompleted} of {selectedList.count}
              </span>
              <Button variant="link" style={{ marginLeft: 12, padding: 0 }}>
                <RefreshCw color={"#004b87"} size={20} />
              </Button>
            </div>
          )}

          <div className="list-container">
            {tasks &&
              tasks.map((item, index) => {
                return (
                  <div key={index} className="list-item">
                    <div className="item-container">
                      <div className="avatar">
                        <img
                          className="icon"
                          src={`${
                            item.CompletedByName !== ""
                              ? "./img/icon-tasklist-check-on.svg"
                              : "./img/icon-tasklist-check-off.svg"
                          }`}
                          alt="Food"
                        />
                      </div>

                      <div className="title-container">
                        <div className="title truncate-line-clamp">
                          {item.Step_Title}
                        </div>

                        {item.URL && (
                          <div className="sub-title truncate-line-clamp">
                            {item.URL}
                          </div>
                        )}

                        <div className="description truncate-line-clamp">
                          {item.Step_Description}
                        </div>
                        {item.CompletedByName !== "" && (
                          <div className="sub-title truncate-line-clamp">
                            Completd by {item.CompletedByName} @
                            {dayjs(item.Completed_Date.toString()).format(
                              "h:mm A M/D/YY"
                            )}
                          </div>
                        )}
                      </div>

                      {item.URL && (
                        <Button variant="link" style={{ padding: 0 }}>
                          {/* <Link color={"#004b87"} size={12} /> */}
                          <ChevronRight color={"#004b87"} size={20} />
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <img
          className="plus-button"
          src={"./img/icon-list-add.svg"}
          alt="Create a Tasklist"
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffcanvasTasklist;
