import { dynamicSort } from "../../../Helpers/DynamicSort"
import { parseNameQtyUom } from "./StatementParser"

export const matchPhrase = function (products, phrase) {

	const spoken = phrase.toLowerCase()
	let parsed = parseNameQtyUom(spoken)

	let name = parsed.name
	let count = parsed.count
	let uom = parsed.uom
	let keywords = parsed.keywords //name.split(' ') // angry orchard
	let actual = null
	let matches = []

	let i = 0;
	while (i < products.length) {

		// if an exact match
		if (products[i].search.name === name && products[i].search.uom === uom) {

			let match = {
				id: products[i].id,
				name: products[i].name,
				uom: products[i].uom,
				score: 100,
				perc: 100,
				keywords: products[i].keywords,
				tag: "perfect match"
			}

			matches.push(match)
			actual = match
			break;
		}

		// a fuzzy match
		else if (products[i].search.name.indexOf(name) !== -1 && products[i].search.uom.indexOf(uom) !== -1) {

			let match = {
				id: products[i].id,
				name: products[i].name,
				uom: products[i].uom,
				score: 99,
				perc: 90,
				keywords: products[i].keywords,
				tag: "fuzzy match"
			}

			matches.push(match)
		}

		i++;
	}

	if (matches.length === 0) {
		i = 0;
		while (i < products.length) {

			let intersection = products[i].keywords.filter(x => keywords.indexOf(x) !== -1 && products[i].search.uom === uom);

			let perc = 0;
			if (products[i].keywords.length > keywords.length) {
				perc = intersection.length / products[i].keywords.length
			}
			else {
				perc = intersection.length / keywords.length;
			}

			if (intersection.length > 1) {
				let match = {
					id: products[i].id,
					name: products[i].name,
					uom: products[i].uom,
					score: intersection.length,
					perc: (perc * 100), //.toFixed(0),
					keywords: products[i].keywords,
					tag: "fuzzy match 2"
				}

				matches.push(match)
			}

			i++;
		}
	}

	// finally try matching on just keywords WITHOUT uom
	if (matches.length === 0) {
		i = 0;
		while (i < products.length) {
			let intersection = products[i].keywords.filter(x => keywords.indexOf(x) !== -1);
			let perc = 0;
			if (products[i].keywords.length > keywords.length) {
				perc = intersection.length / products[i].keywords.length
			}
			else {
				perc = intersection.length / keywords.length;
			}

			if (intersection.length > 1) {

				let match = {
					id: products[i].id,
					name: products[i].name,
					uom: products[i].uom,
					score: intersection.length,
					perc: (perc * 50), //.toFixed(0),
					keywords: products[i].keywords,
					tag: "w/o uom match"
				}

				matches.push(match)
			}

			i++;
		}
	}

	// sort the of matches from the highest score to the lowest
	matches.sort(dynamicSort('perc', -1))

	// assert some business rules to find the best match
	// if only 1 match...
	if (matches.length === 1) {
		actual = matches[0]
	}
	// if multiple matches, but the first has a 100% match score
	else if(actual === null && matches.length > 1 && matches[0].perc === 100) {
		actual = matches[0]
	}

	let result = {
		"spoken": spoken,
		"qty": count,
		"uom": uom,
		"name": name,
		"keywords": keywords,
		"actual": actual,
		"matches": matches,
	}

	return result
}

