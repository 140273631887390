import React from 'react'

const IconAlert = ({ className, color, opacity, size, strokeWidth }) => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="25" fill={color || "#333F48"} />
            <path d="M31 21C31 19.4087 30.3679 17.8826 29.2426 16.7574C28.1174 15.6321 26.5913 15 25 15C23.4087 15 21.8826 15.6321 20.7574 16.7574C19.6321 17.8826 19 19.4087 19 21C19 28 16 30 16 30H34C34 30 31 28 31 21Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M26.73 34C26.5542 34.3031 26.3019 34.5547 25.9982 34.7295C25.6946 34.9044 25.3504 34.9965 25 34.9965C24.6496 34.9965 24.3054 34.9044 24.0018 34.7295C23.6982 34.5547 23.4458 34.3031 23.27 34" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default IconAlert