import React from "react";

import { Offcanvas, Button, Table, Row, Col } from "react-bootstrap";
import { RotateCw } from "react-feather";

import { ChevronRight } from "react-feather";

import "./Styles/TablePlaceholder.css";

function TablePlaceholder(props) {
  return (
    <div className="table-placeholder">
      <Table responsive>
        {/* <thead>
          <tr>
            {Array.from({ length: 4 }).map((_, index) => (
              <th key={index}>
                <div className="th-filler">&nbsp;</div>
              </th>
            ))}
          </tr>
        </thead> */}
        <tbody>
          {Array.from({ length: props?.rows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: props?.cols }).map((_, index) => (
                <td key={index} className="table-placeholder-td">
                  <div className="td-filler">&nbsp;</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default TablePlaceholder;