

export function logActivity(data) {

    // for now, disable all logging
    // console.log("LOGGING DISABLED!!!!!", data)
    return;

    try {

        const vuid = localStorage.getItem('vuid')

        const ts = new Date().getTime()

        // if running at Heroku, keep it as a relative url
        let domain = ""
        if (window.location.href.includes('localhost')) {
            domain = "http://localhost:3100"
        }





        console.log("domain", domain)

        // get the pageName for logging
        const url = window.location.href;
        console.log("url", url);
        const parts = url.split('/');
        let pageName = parts[parts.length - 1];
        // the chats?location_ndx... will need the query stripped off
        if (pageName.includes('?')) {
            pageName = pageName.split('?')[0];
        }
        else if (pageName === '') {
            pageName = 'home';
        }
        // if promo (e.g. /promo/1234), then parse it differently
        else if (url.includes('promo')) {
            pageName = pageName = `${parts[parts.length - 2]}-${parts[parts.length - 1]}`;
        }
        console.log("pageName", pageName);

        const requestData = {
            vuid,
            action: data.action,
            page: pageName,
            ts
        }

        console.log("request", JSON.stringify(requestData, null, 2))

        // if not localhost, then log the page visit
        if (url.includes('localhost')) {
            console.log("skipping local logging")
            return
        }



        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestData)
        };
        fetch(domain + '/api/activity', options);

  

    }
    catch (ex) {
        console.log("error logging activity", ex)
    }

}