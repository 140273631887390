import { useState, useEffect } from "react";
import * as Icon from "react-feather";
import styles from "./LayoutMenu.css";

import gridLayoutOptions from "../../Fixtures/grid-layouts.json";

export default function LayoutMenu(props) {
  const [gridOptions, setGridOptions] = useState(gridLayoutOptions);
  const [selectedGroup, setSelectedGroup] = useState(gridLayoutOptions[0]);

  // this is used to allow the selectedDashboard to tell this component which one is currently selected
  useEffect(() => {
    if (props.selectedDashboard) {

      // find the grid options based on the number of dashboard widgets
      let group = gridOptions.find(element => element.count === props.selectedDashboard.components.length);

      if (group && group.layouts) {
        group.layouts.forEach(function(item) {
          item.selected = false;
          if (item.id === props.selectedDashboard.layout.join(',')) {
            item.selected = true;
          }
        });

        setSelectedGroup({ ...group });
      }
    }
  }, [props.selectedDashboard]);

  function onLayoutSelect(layout) {
    // this will refresh the visual selection in the UI
    let selectedGroupCopy = { ...selectedGroup };
    selectedGroupCopy.layouts.forEach(function(item) {
      item.selected = false;
      if (item.id === layout.id) {
        item.selected = true;
      }
    });
    setSelectedGroup({ ...selectedGroupCopy });

    // // tell the parent what has been selected...
    props.onLayoutSelect(layout.id.split(','));
  }

  return (
    <div className={styles.layoutMenu} style={{ display: props.visible ? "" : "none" }}>
      {/* <button className={"btn btn-default " + styles.menuX} onClick={() => props.onClose()}>
        <Icon.XCircle size={20} color={"darkgray"} strokeWidth={2} />
      </button> */}

      <div className="row">
        {selectedGroup &&
          selectedGroup.layouts.map((item, index) => {
            return (
              <div key={"grid_" + index} className="col-lg-4">
                <div className={"carousel-item-2"}>
                  <a href="#" onClick={() => onLayoutSelect(item)} style={{ textDecoration: "none" }}>
                    <div className={item.selected ? "card layout-70 selected" : "card layout-70"}>
                      <div className="row g-0">
                        {item.id.split(",").map((l, ndx) => (
                          <div key={"cell_" + ndx} className={`col-${l}`}>
                            <div className={item.selected ? "layout-cell selected" : "layout-cell"}>&nbsp;</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
