import React, { useState, useEffect, useRef } from "react";

import {

    Button,
    Row,
    Alert

} from "react-bootstrap";

import {
    Star, X
} from "react-feather";


import "./SelectedWidgetPanel.css";

function SelectedWidgetPanel({ id, selectedWidgets, show = false, onRemoveItem, onMouseLeft }) {


    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const prevShow = usePrevious({ show });

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    useEffect(() => {

        //console.log("loaded")

    }, []);

    useEffect(() => {

        // if (prevShow?.show !== show) {
        //     console.log("prev", prevShow, show)
        // }
        //if(prevShow) {
        toggleFeedbackForm()
        //}

    }, [show]);

    function toggleFeedbackForm() {


        const form = document.getElementById(id);

        // always scroll to the top when this is opened
        let element = document.getElementsByClassName('selected-container')[0];
        element.scrollTo(0, 0)

        //console.log("toggle show", show)
        if (show) {
            form.style.right = '0'; // Slide in
        }
        else {
            form.style.right = '-375px'; // Slide out
        }
    }

    function onSelect(item, index, mode) {
        onRemoveItem(item, index, mode)
    }

    return (


        <div id={id} className="selected-container" onPointerLeave={onMouseLeft}>

            <div className="selected-content">

                <div className="d-flex flex-row justify-content-between">
                    <h5 className="flex-grow-1">Selected Widgets</h5>
                    <Button variant="none" className="btn-link-off" onClick={onMouseLeft}>
                        <X size="24" />
                    </Button>
                </div>


                <hr />

                {selectedWidgets.length === 0 &&
                    <p>No widgets are currently selected.</p>
                }

                {selectedWidgets.length === 9 &&
                    <Alert variant={'warning'}>
                        You've reached the limit of 9 widgets. To add more, please remove one or more selected widgets.
                    </Alert>
                }

                <div>
                    {selectedWidgets.map((item, index) => {
                        return (
                            <div key={index} className="col-xs-12 search-item no-select">

                                <div className="d-flex gap-3">
                                    <div className="icon">
                                        <img src={`./img/widget-icons/${item.type}.svg`} alt={item.title} />
                                    </div>
                                    <div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h5>{item.title}</h5>
                                            </div>
                                            <div className="upper-right-corner">
                                                <Button variant="link" className="btn-link-off remove-button" onClick={() => onSelect(item, index, false)}><img src={"/img/widget-icons/minus-circle.svg"} alt="Favorite" /></Button>
                                            </div>
                                        </div>
                                        <div>{item.description || `Sorry, we don't have a description for ${item.title}`}</div>
                                    </div>
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>

        </div>

    )
};

export default SelectedWidgetPanel;