import React, { useState, useEffect, useRef } from "react";

import {
    Container,
    Row,
    Col,
    Offcanvas,
    Button,
    Form,
    InputGroup,
    Tab,
    Tabs,
    Badge,
    Modal,
    Accordion,
    Pagination,
    Dropdown
} from "react-bootstrap";

import {
    Plus, X, Search, ChevronDown, Star, ArrowRight, ArrowLeft
} from "react-feather";

import LayoutMenu from "../../../Components/LayoutMenu/LayoutMenuNew";
import WidgetList from "../WidgetList/WidgetList";

import cloneDeep from "lodash/cloneDeep";

import "./WizardModalStyles.css"



import widgetCategoryItems from "./widget-categories.json";
import widgetLibraryItems from "./widget-library-hybrid.json";

import { dynamicSort } from "../../../Helpers/DynamicSort";
import SelectedWidgetPanel from "./SelectedWidgetPanel/SelectedWidgetPanel";
import SelectedCountBadge from "./SelectedCountBadge/SelectedCountBadge";
import CustomBadge from "../../../Components/Badge/Badge";

import { logActivity } from "../../../Services/Activity";
import { getChartColors } from '../../../Helpers/ChartColors';



function DashboardWizard({ show, onHide }) {

    const excludeTypes = ["TablePivot", "TableGrouped", "TableAdvanced", "BarLineMixed", "KPI"];
    widgetLibraryItems = widgetLibraryItems.filter(s => !excludeTypes.includes(s.type));

    let host = "http://localhost:3100";
    // when running at Heroku
    if (document.location.href.indexOf('localhost') === -1) {
        host = ""; // port 3000 works in build version
    }
    // console.log("host", host)

    const searchInputRef = useRef();

    const [selectedCount, setSelectedCount] = useState(0);
    const [activeKey, setActiveKey] = useState(null);

    const [widgetCategories] = useState(widgetCategoryItems)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)

    const [filteredWidgets, setFilteredWidgets] = useState([])
    const [selectedWidgets, setSelectedWidgets] = useState([])
    const [expanded, setExpanded] = useState(false)
    const [showBounce, setShowBounce] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [maxCount, setMaxCount] = useState(18);

    const [showSelected, setShowSelected] = useState(false);
    const [stepNum, setStepNum] = useState(1)
    const [selectedLayout, setSelectedLayout] = useState([])
    const [chartColors, setChartColors] = useState(null)


    let drillingBySubCategory = false;

    useEffect(() => {

        let colors = getChartColors()
        setChartColors(colors)

        loadWidgets()

    }, []);

    useEffect(() => {
        if (show) {
            logActivity({ action: "wizard" })
        }
    }, [show]);

    function loadWidgets() {
        // const filtered = widgetLibraryItems.filter(
        //     (s) => s.type !== "TablePivot" && s.type !== "TableGrouped" && s.type !== "TableAdvanced" && s.type !== "TablePivot" && s.type !== "BarLineMixed"
        // );
        // const excludeTypes = ["TablePivot", "TableGrouped", "TableAdvanced", "BarLineMixed"];
        // const filtered = widgetLibraryItems.filter(s => !excludeTypes.includes(s.type));

        let sorted = widgetLibraryItems.sort(dynamicSort('title', 1)); // sort high to low
        setFilteredWidgets(sorted)
    }


    function onSelect(item, index, mode) {

        setShowBounce(true)

        let copyList = cloneDeep(selectedWidgets)
        if (mode === true) {

            if (copyList.length === 9) {
                setShowSelected(true)
            }
            else {
                copyList.push(item)
                setSelectedWidgets(copyList)
            }
        }
        else if (mode === false) {
            copyList = copyList.filter((s, sndx) => sndx !== index);
            setSelectedWidgets(copyList)
        }

        // close the Accordion if now there are no selected widgets
        if (copyList.length === 0) {
            setActiveKey("0")
            // this causes a close and reopen for some reason
            // setShowSelected(false)
        }

        setTimeout(() => {
            setShowBounce(false)
        }, 1000)

    }

    function getSelectedClassName(item) {

        if (selectedWidgets.find((s) => s.widgetId === item.widgetId)) {
            return "selected"
        }
        else {
            return ""
        }

    }

    function handleHide() {
        setSearchTerm('')
        setFilteredWidgets(widgetLibraryItems)
        setSelectedWidgets([])
        setSelectedCategory(null)
        setSelectedSubCategory(null)
        setStepNum(1)
        onHide()

        logActivity({ action: "wizard hidden" })
    }

    function onSelectCategory(cat) {

        // since the sub category buttons are inside of the parent container, prevent this from firing when selecting a sub category
        if (drillingBySubCategory === false) {
            setSearchTerm('')
            setSelectedSubCategory(null)

            if (cat.folderId === selectedCategory?.folderId) {
                setSelectedCategory(null)
                setFilteredWidgets(widgetLibraryItems)
            }
            else {
                setSelectedCategory(cat)
                console.log("cat", cat)
                let copyList = cloneDeep(widgetLibraryItems)
                copyList = copyList.filter(s => s.folderName === cat.name);
                console.log("copyList filtered", copyList)
                setFilteredWidgets(copyList)
            }
        }

    }
    function onSelectSubCategory(cat, sub) {
        drillingBySubCategory = true;
        setSelectedCategory(cat)
        setSelectedSubCategory(sub)

        console.log("cat", cat, "sub", sub)
        let copyList = cloneDeep(widgetLibraryItems)
        copyList = copyList.filter(s => s.subFolderName === sub.subFolderName);
        console.log("copyList filtered", copyList)
        setFilteredWidgets(copyList)

    }

    function onChangeSearchTerm(query) {
        setSelectedCategory(null)
        setSelectedSubCategory(null)
        setSearchTerm(query);
        if (query.length > 0) {
            let items = findItemsWithSearchTerm(query)
            setTotalCount(items.length);

            const sliced = items.slice(0, maxCount); // only display top maxCount
            sliced.sort(dynamicSort('title', 1)); // sort high to low
            setFilteredWidgets(sliced);
        }
        else {
            setFilteredWidgets(widgetLibraryItems)
        }
    }

    function findItemsWithSearchTerm(searchTerm) {
        // incase the user is using comma-delimited instead of space-delimited search terms (e.g. "sales, reports")
        const nocommas = searchTerm.replaceAll(',', ' ');
        // Split the search term into individual words
        const words = nocommas.toLowerCase().split(' ');

        // Filter the items array to find matches
        return widgetLibraryItems.filter(item => {
            // Combine all text elements search
            const combinedText = `${item.title?.toLowerCase()} ${item.description?.toLowerCase()} ${item.folderName?.toLowerCase()} ${item.subFolderName?.toLowerCase()}`;

            // Check if all words in the search term exist in the combined text
            return words.every(word => combinedText.includes(word));
        });
    }

    const onKeyDown = (event) => {
        console.log("event key", event.key);
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleHeaderClick = (key) => {
        setActiveKey(activeKey === key ? null : key); // Toggle accordion item on header click
    };

    function handleShowSelected() {

        logActivity({ action: "wizard-selected-tab" })
        setShowSelected(!showSelected)
        // setStepNum(2)

    }

    function onLayoutChange(layout) {
        setSelectedLayout(layout);
    }

    function onRearrangeWidgets(newList) {

      console.log("newlist", newList)

      setSelectedWidgets([...newList])

    }


    return (

        <Modal
            className="wizard"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            fullscreen={true}
            show={show}
            onHide={() => handleHide()}>
            <Modal.Header closeButton>
                <Modal.Title>Create a Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {stepNum === 1 &&
                    <>
                        <div className="text-center">
                            <h4>What kind of widgets would you like on your dashboard?</h4>
                        </div>

                        {selectedWidgets.length > 0 &&
                            <div className="vertical-tab-right no-select" onClick={() => handleShowSelected()}>
                                <SelectedCountBadge value={selectedWidgets.length} showBounce={showBounce} />
                                Selected
                            </div>
                        }
                        <SelectedWidgetPanel
                            id="dashboard-wizard-feedback"
                            selectedWidgets={selectedWidgets}
                            show={showSelected}
                            onRemoveItem={(item, index, mode) => onSelect(item, index, mode)}
                            onMouseLeft={() => setShowSelected(false)}
                        />


                        <div className="text-center">

                            <div className="search-box mt-4">
                                <Form className='d-flex mx-md-2 d-none d-sm-block border border-1 rounded'>
                                    <InputGroup className="flex-nowrap input-resize">
                                        <div className="bg-white rounded-start d-flex align-items-center p-2">
                                            <Search strokeWidth={2} size={20} />
                                        </div>
                                        <Form.Control
                                            type="search"
                                            placeholder="Search"
                                            className={'border-0 shadow-none'}
                                            aria-label="Search"
                                            value={searchTerm}
                                            onKeyDown={e => onKeyDown(e)}
                                            onChange={e => onChangeSearchTerm(e.target.value)}
                                            ref={searchInputRef}
                                        />
                                    </InputGroup>
                                </Form>
                            </div>

                            <div className="pill-container mt-4">
                                <div className="animate-slide-up mt-2 d-flex flex-row flex-wrap justify-content-center gap-2 mt-3 mb-3 pb-2">
                                    {widgetCategories.map((cat, index) => (
                                        <div key={index}
                                            className={selectedCategory?.folderId === cat.folderId ? 'item-pill selected' : 'item-pill'}
                                            onClick={() => onSelectCategory(cat)}
                                        >
                                            <>
                                                {cat.name}

                                                {cat.subs.length > 0 &&
                                                    <>
                                                        {/* <ChevronDown size="18" color={selectedCategory?.folderId === cat.folderId ? 'white' : 'silver'} style={{ marginLeft: 1, marginRight: -5 }} />
                                                <div className="dropdown-content">
                                                    {cat.subs.map((sub, subIndex) => (
                                                        <div key={subIndex} className="button-container">
                                                            <Button variant="link" className="btn-link-off" onClick={() => onSelectSubCategory(cat, sub)}>{sub.subFolderName}</Button>
                                                        </div>
                                                    ))}
                                                </div> */}

                                                        <Dropdown className="d-inline" autoClose="outside">
                                                            <Dropdown.Toggle variant="link" className="btn-link no-caret">
                                                                {/* {cat.label} */}
                                                                <ChevronDown size="22" color={selectedCategory?.folderId === cat.folderId ? 'white' : '#333f48'} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="p-3">
                                                                {cat.subs.map((sub, subIndex) => (
                                                                    <Dropdown.Item as="button" key={subIndex} onClick={() => onSelectSubCategory(cat, sub)} eventKey={subIndex} className="d-flex flex-row gap-3">

                                                                        {sub.subFolderName}

                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>

                                                    </>
                                                }
                                            </>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>


                        <div className="d-flex flex-row justify-content-center">
                            <div>Displaying {filteredWidgets.length} of {widgetLibraryItems.length} widgets</div>
                            {/* <Pagination>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Next />
                         </Pagination> */}
                        </div>


                        <div className="row mt-3 px-5">

                            <Col xs="12">
                                <Row className="gap-6">

                                    {filteredWidgets.map((item, index) => {
                                        return (

                                            <div key={index} className={'col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 search-item no-select ' + getSelectedClassName(item)}>
                                                <div className="d-flex gap-3">
                                                    <div>
                                                        <div className="d-flex">
                                                            <img src={`./img/widget-icons/${item.type}.svg`} alt={item.title} />
                                                            {selectedWidgets.find((s) => s.widgetId === item.widgetId) &&
                                                                <div className="selected-counter">{selectedWidgets.filter((s) => s.widgetId === item.widgetId).length}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex flex-row align-items-start justify-content-between"> {/* Updated alignment classes */}
                                                            <div className="flex-grow-1">
                                                                <h5>{item.title}</h5>
                                                            </div>
                                                            <div className="d-flex align-items-start upper-right-corner"> {/* Added a flex container around the button */}
                                                                <Button variant="link" className="btn-link-off add-button" onClick={() => onSelect(item, index, true)}>
                                                                    <img src={"/img/widget-icons/plus-circle.svg"} alt="Select" />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div>{item.description || `Sorry, we don't have a description for ${item.title}`}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })}
                                </Row>
                            </Col>




                        </div>
                    </>
                }
                {stepNum === 2 &&
                    <>

                        <div className="text-center mb-3">
                            <h4>Choose a layout and use drag & drop to rearrange your widgets.</h4>
                        </div>

                        <div className="vertical-tab-left no-select" onClick={() => setStepNum(1)}>
                            <SelectedCountBadge value={selectedWidgets.length} showBounce={showBounce} />
                            Selected
                        </div>

                        <div className="d-flex justify-content-center">
                            <LayoutMenu currentLayout={[1, 2, 3]} widgetCount={selectedWidgets.length} visible={true} onSelectLayout={(s) => onLayoutChange(s)} />
                        </div>

                        <div className="">
                            <WidgetList 
                                host={host} 
                                widgetList={selectedWidgets} 
                                currentLayout={selectedLayout} 
                                chartColors={chartColors} 
                                widgetHeight={150} 
                                onRemoveWidget={(widget, index) => onSelect(widget, index, false)}
                                onRearrangeWidgets={(newList) => onRearrangeWidgets(newList)} />
                        </div>
                    </>
                }

            </Modal.Body>

            {/* <div className={expanded ? 'expandable-container expanded' : 'expandable-container'}>
                <h5 className="header" onClick={() => setExpanded(!expanded)}>Selected Widgets <CustomBadge value={selectedWidgets.length} /></h5>
                <div className="widget-wrapper">
                    {selectedWidgets.map((s, ndx) => (
                        <div className="widget-preview">
                            <Button variant="link" className="btn-link-off" onClick={() => onSelect(s, ndx, false)}><X size={24} color={'silver'} /></Button>
                        </div>
                    ))}
                </div>
            </div> */}

            {/* This displays the selected widgets */}
            {/* <Accordion className="mt-3" activeKey={activeKey} onSelect={handleHeaderClick}>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Selected Widgets <CustomBadge value={selectedWidgets.length} showBounce={showBounce} /></Accordion.Header>
                    <Accordion.Body>
                        <Row className="gap-6">
                            {selectedWidgets.map((item, index) => {
                                return (
                                    <div key={index} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 search-item">
                                        <div className="d-flex gap-3">
                                            <div className="icon">
                                                <img src={`./img/widget-icons/${item.type}.svg`} alt={item.title} />
                                            </div>
                                            <div>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5>{item.title}</h5>
                                                    </div>
                                                    <div className="upper-right-corner">
                                                        <Button variant="link" className="btn-link-off" onClick={() => onSelect(item, index, false)}><img src={"/img/widget-icons/minus-circle.svg"} alt="Favorite" /></Button>
                                                    </div>
                                                </div>
                                                <div>{item.description || `Sorry, we don't have a description for ${item.title}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> */}


            <Modal.Footer className="d-flex justify-content-between">
                <div>
                    {stepNum === 2 && (
                        <Button variant="secondary" size="lg" onClick={() => setStepNum(1)}>
                            <ArrowLeft size="18" /> Back
                        </Button>
                    )}
                </div>
                <div className="d-flex gap-2">
                    <Button variant="light" size="lg" onClick={() => handleHide()}>
                        Cancel
                    </Button>
                    {stepNum === 1 &&
                        <Button variant="success" size="lg" onClick={() => setStepNum(2)}>
                            Continue <ArrowRight size="18" color="white" />
                        </Button>
                    }
                    {stepNum === 2 &&
                        <Button variant="success" size="lg" onClick={() => setStepNum(2)}>
                            Create Dashboard
                        </Button>
                    }
                </div>
            </Modal.Footer>

        </Modal>
    );
}

export default DashboardWizard;
