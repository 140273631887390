import React, { useState, useEffect } from "react";

import { Button } from "react-bootstrap";

import dayjs from "dayjs";

// import "./App.css";
// import "./OffcanvasDatePicker.css";

import { ChevronLeft, ChevronRight } from "react-feather";

import "./OffcanvasDatePicker.css";

function OffcanvasDatePicker(props) {
  const [availableDates, setAvailableDates] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("YYYY-MM-DD");
  const [selectedOffset, setSelectedOffset] = useState(0);

  useEffect(() => {
    const dates = buildDateArray(3);
    setDates(dates);
    //console.log("dates", JSON.stringify(dates, null, 2));

    // this builds an array of 30 days with 15 before and after today
    setAvailableDates(buildDateArray(15));

    setSelectedDate(dayjs(new Date().toString()).format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    //console.log("selectedDate changed", selectedDate);
    props.onDateChange(selectedDate);
  }, [props, selectedDate]);

  function buildDateArray(distance) {
    const today = new Date(); // Get today's date
    const daysInMilliseconds = 86400000; // Number of milliseconds in a day

    const dateArray = [];

    // Loop through 31 days (15 days before and after today)
    for (let i = -distance; i <= distance; i++) {
      const date = new Date(today.getTime() + i * daysInMilliseconds); // Get the date i days from today
      const dayNbr = dayjs(date).format("DD");

      const formatted = {
        nbr: dayNbr,
        index: i,
        abbrev: dayjs(date).format("ddd"), // e.g. Sat, Sun
        name: dayjs(date).format("dddd") // e.g. Saturday
      };

      dateArray.push({
        date: dayjs(date.toString()).format("YYYY-MM-DD"),
        formatted
      });
    }

    return dateArray;
  }

  function goBack() {
    setSelectedOffset(selectedOffset - 1);
    buildDateArray(3);
  }

  function goForward() {
    setSelectedOffset(selectedOffset + 1);
    buildDateArray(3);
  }

  return (
    <div className="sub-header-tall">
      <span className="top-padding" />
      <div className="sub-header-date">
        <div className="sub-header-date-nbr">
          {dayjs(selectedDate.toString()).format("DD")}
        </div>
        <div className="sub-header-date-day">
          <span>{dayjs(selectedDate.toString()).format("dddd")}</span>
          <span>
            {dayjs(selectedDate.toString()).format("MMMM")},{" "}
            {dayjs(selectedDate.toString()).format("YYYY")}
          </span>
        </div>
      </div>
      <div className="sub-header-datelist-container">
        <Button variant="link" style={{ padding: 0 }} onClick={goBack}>
          <ChevronLeft color={"white"} />
        </Button>

        {dates &&
          dates.map((date, index) => {
            return (
              <div key={index} className="date" onClick={() => setSelectedDate(date.date)}>
                <span className="day-name">{date.formatted.abbrev}</span>
                <span
                  className={`day-nbr ${
                    date.date === selectedDate ? "active" : ""
                  }`}
                >
                  {date.formatted.nbr}
                </span>
              </div>
            );
          })}

        <Button variant="link" style={{ padding: 0 }}>
          <ChevronRight color={"white"} onClick={goForward} />
        </Button>
      </div>
    </div>
  );
}

export default OffcanvasDatePicker;
