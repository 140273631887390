import React, { useState } from "react";
import copy from "copy-text-to-clipboard";
import { Button } from "react-bootstrap";
import { Link } from "react-feather";

import "./CopyLink.css";

const CopyLink = (props) => {
  const [isCopied, setIsCopied] = useState(false);
  function getLabel() {
    if (isCopied) return "Copied";
    else return "Copy";
  }

  function onCopy() {
    copy(props.link);
    setIsCopied(true);
  }

  return (
    <div className="mt-3 mb-2 copy-link">
      <div className="copy-link-container">
        <div className="icon-container">
          <Link
            size={24}
            color={isCopied ? "#67A617" : "#333f48"}
            style={{ marginRight: 20 }}
          />
        </div>
        <div className="link-container">{props.link}</div>
        <div className="button-container">
          <Button
            style={{ width: 100 }}
            variant={isCopied ? "success" : "primary"}
            size={"lg"}
            onClick={onCopy}
          >
            {getLabel()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CopyLink;
