import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
import LandingKPI from "../../Components/Landing/LandingKPI";
import LandingCTA from "../../Components/Landing/LandingCTA";
import LandingToggle from "../../Components/Landing/LandingToggle";
import Carousel from "../../Components/Carousel/Carousel";
// import KPI from "../Components/Landing/KPI";

import OffcanvasTasklist from "../../Components/OffcanvasTasklist/OffcanvasTasklist";
import OffcanvasNotifications from "../../Components/OffcanvasNotifications/OffcanvasNotifications";
import OffcanvasEmployeeRequests from "../../Components/OffcanvasEmployeeRequests/OffcanvasEmployeeRequests";
import OffcanvasCalendar from "../../Components/OffcanvasCalendar/OffcanvasCalendar";
import OffcanvasMenu from "../../Components/OffcanvasMenu/OffcanvasMenuNew";

import "./Styles.css";

import { FormatData } from "../../Helpers/KPIFormatter";

// load the sample data
import salesData from "../../Components/Landing/SampleData/sales.json";
import laborData from "../../Components/Landing/SampleData/labor.json";
import trafficData from "../../Components/Landing/SampleData/traffic.json";
import splhData from "../../Components/Landing/SampleData/splh.json";
import ppaData from "../../Components/Landing/SampleData/ppa.json";
import salesTrend from "../../Components/Landing/SampleData/sales-trend.json";
import laborTrend from "../../Components/Landing/SampleData/labor-trend.json";

// format the sample data
let salesFormatted = FormatData("sales-landing", "Sales", salesData);
let laborFormatted = FormatData("labor-landing", "Labor", laborData);
let trafficFormatted = FormatData("traffic-landing", "Traffic", trafficData);
let splhFormatted = FormatData("splh-landing", "SPLH", splhData);
let ppaFormatted = FormatData("ppa-landing", "PPA", ppaData);

const colors = {
  success: "#67A617", // green
  warning: "#F5AA04", // orange
  critical: "#E03C31" // red
};

const LineChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "bottom"
    },
    title: {
      display: false
      // text: "Sales, Labor, and Food Cost"
    }
  },
  interaction: {
    intersect: false
  },
  scales: {
    x: {
      display: true,
      title: {
        display: false
      }
    },
    y: {
      display: true,
      title: {
        display: false
        // text: "Value"
      },
      suggestedMin: 0
      // suggestedMax: 99123
    }
  }
};

// not crazy about this approach, but Line charts need the styling in the data object
salesTrend.datasets[0] = {
  labels: salesTrend.datasets[0].labels,
  data: salesTrend.datasets[0].data,
  borderColor: colors.success,
  fill: false,
  // this is the background for the points when hovered
  backgroundColor: colors.success,
  pointStyle: false,
  pointRadius: 0,
  pointHoverRadius: 15,
  tension: 0.3
};
laborTrend.datasets[0] = {
  labels: laborTrend.datasets[0].labels,
  data: laborTrend.datasets[0].data,
  borderColor: colors.success,
  fill: false,
  // this is the background for the points when hovered
  backgroundColor: colors.critical,
  pointStyle: false,
  pointRadius: 0,
  pointHoverRadius: 15,
  tension: 0.3
};

// the LandingKPI accepts an optional graph property (Sales & Labor only)
salesFormatted.graph = {
  label: "Sales Last 7 Days",
  isLoading: true,
  chart: <Line data={salesTrend} options={LineChartOptions} />
};
// the LandingKPI accepts an optional graph property (Sales & Labor only)
laborFormatted.graph = {
  label: "Labor Last 7 Days",
  isLoading: false,
  chart: <Line data={laborTrend} options={LineChartOptions} />
};

function Home() {

  const [dataTransferSuccess, setDataTransferSuccess] = useState(false);
  const [demoLoadingState, setDemoLoadingState] = useState(null);
  const [action, setAction] = useState(null);

  const [showMenu, setShowMenu] = useState(false);
  const [showEmployeeRequests, setShowEmployeeRequests] = useState(false);
  const [showEmployeeRequestsCTA, setShowEmployeeRequestsCTA] = useState(false);
  const [showTasklist, setShowTasklist] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [forceRerender, setForceRerender] = useState(new Date());

  useEffect(() => {
    document.title = 'PowerCenter';
  }, []);

  function demoIsLoading() {
    setDemoLoadingState(new Date());
  }

  function onActionPerformed() {
    setAction(null);
  }

  return (
    <Container fluid className="home">
      <TopNav dataTransferSuccess={dataTransferSuccess} action={action} onActionPerformed={onActionPerformed} />
      <div className="landing-container">

        <div className="d-flex">
          <LandingToggle onChangeToggle={demoIsLoading} />

          <Carousel
            increment={100}
            showEmployeeRequests={showEmployeeRequests}
            dataTransferSuccess={dataTransferSuccess}
            items={
              <>

                {/* <LandingCTA
                  title="Transmission Failures"
                  href="#"
                  image={
                    dataTransferSuccess
                      ? "./img/cta/cta-transfer-successful.svg"
                      : "./img/cta/cta-transfer-critical-white-on-red.svg"
                  }
                  onClick={() => setAction("show-data-transfer")}
                  // invert this prop
                  // showDropShadow={!dataTransferSuccess}
                  count={dataTransferSuccess ? 0 : 4}
                  pointerzId="data-transfer-cta"
                /> */}

                <LandingCTA
                  title="Tasklists"
                  href="#"
                  image="./img/cta/cta-tasklists-primary.svg"
                  onClick={() => {
                    setShowTasklist(true);
                    setForceRerender(new Date());
                  }}
                  // count={3}
                />


                {showEmployeeRequestsCTA &&
                <LandingCTA
                  title="Employee Requests"
                  href="#"
                  image="./img/cta/cta-employee-requests-primary.svg"
                  onClick={() => { 
                    setShowEmployeeRequests(true)
                    setForceRerender(new Date());
                  }}
                  //count={12}
                />
                }
                

                <LandingCTA
                  title="Calendar"
                  href="#"
                  image="./img/cta/cta-calendar-primary.svg"
                  onClick={() => { 
                      setShowCalendar(true)
                      setForceRerender(new Date());
                  }}
                  //count={7}
                />

                <LandingCTA
                  title="Favorites"
                  href="#"
                  image="./img/cta/cta-favorites-primary.svg"
                  onClick={() => { 
                      setShowMenu(true)
                      setForceRerender(new Date());
                  }}
                  //count={32}
                />

                <LandingCTA
                  title="Notifications"
                  href="#"
                  image="./img/cta/cta-notifications-primary.svg"
                  onClick={() => { 
                      setShowNotifications(true)
                      setForceRerender(new Date());
                  }}
                  //count={9}
                />

              </>
            }
          />
        </div>

        <Row>
          <Col lg={6}>
            <LandingKPI
              demoLoadingState={demoLoadingState}
              data={salesFormatted}
              className="kpi"
            />
          </Col>
          <Col lg={6}>
            <LandingKPI
              demoLoadingState={demoLoadingState}
              data={laborFormatted}
              className="kpi"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <LandingKPI
              demoLoadingState={demoLoadingState}
              data={trafficFormatted}
              className="kpi"
            />
          </Col>
          <Col lg={4}>
            <LandingKPI
              demoLoadingState={demoLoadingState}
              data={ppaFormatted}
              className="kpi"
            />
          </Col>
          <Col lg={4}>
            <LandingKPI
              demoLoadingState={demoLoadingState}
              data={splhFormatted}
              className="kpi"
            />
          </Col>
        </Row>
        <br />
        <Button
          variant="light"
          onClick={demoIsLoading}
          style={{ marginBottom: 20 }}
        >
          Simulate Data Loading
        </Button>
        &nbsp;
        <Button
          variant="light"
          onClick={() => setShowEmployeeRequestsCTA(!showEmployeeRequestsCTA)}
          style={{ marginBottom: 20 }}
        >
          Show/Hide Employee Requests
        </Button>
        &nbsp;
        <Button
          variant="light"
          onClick={() => setDataTransferSuccess(!dataTransferSuccess)}
          style={{ marginBottom: 20 }}
        >
          Toggle Data Transfer Success/Failure
        </Button>
      </div>


      <OffcanvasTasklist
        show={showTasklist}
        forceRerender={forceRerender}
        onClose={() => setShowTasklist(false)}
      />
      <OffcanvasNotifications
        show={showNotifications}
        forceRerender={forceRerender}
        onClose={() => setShowNotifications(false)}
      />
      <OffcanvasCalendar
        show={showCalendar}
        forceRerender={forceRerender}
        onClose={() => setShowCalendar(false)}
      />
      <OffcanvasEmployeeRequests
        show={showEmployeeRequests}
        forceRerender={forceRerender}
        onClose={() => setShowEmployeeRequests(false)}
      />
      {/* wrapping this in the same boolean var prevents React from an endless render loop */}
      {showMenu &&
      <OffcanvasMenu
          show={showMenu}
          searchTerm={''}
          selectedMenuItem={null}
          defaultCategoryIndex={1}
          onClose={() => setShowMenu(false)}
      />
      }


    </Container>
  );
}

export default Home;
