import React, { useState, useEffect, useRef } from 'react';

import { Container, Row, Col, Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { Mic, Check, Edit3, X, Search, Smile } from "react-feather";


import cloneDeep from "lodash/cloneDeep";

import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
import { matchPhrase } from "./Helpers/MatchHelper.js"
import MicrophoneAccess from "./MicrophoneAccess.js"

import products from "./Data/products.json";


import "./VoiceInventory.css"

const sampleData = [
    "applewood bacon 5 pounds",
    "smoked salmon 7 pounds",
    "shaved ham 3 pounds",
    "cilantro 9 ounces",
    "wild salmon 5 pounds",
    "salmon 9 pounds",
    "asheville rocket girl 27 cans",
    "angry orchard 50 bottles",
    "angry orchard green apple 37 bottles",
    "bacon bits 5 pounds",
    "bananas 20 pounds",
    "firefly vodka sweet tea 2 liters",
    "mozzarella scoops 2 pounds",
    "cucumbers 12 each"
]



function VoiceInventory() {
    const [statements, setStatements] = useState(sampleData);
    const [textareaValue, setTextareaValue] = useState(sampleData.join("\n"));
    const [displayData, setDisplayData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showRecordModal, setShowRecordModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)
    const [isListening, setIsListening] = useState(false)
    const [recordedPhrases, setRecordedPhrases] = useState([])
    const [animationPhrases, setAnimationPhrases] = useState([]);

    const searchInputRef = useRef();

    useEffect(() => {
        document.title = 'Voice Inventory | PowerCenter';
        matchSpokenPhrases()
    }, []);


    function matchSpokenPhrases() {
        let data = []
        statements.forEach(function (item) {
            let match = matchPhrase(products, item)
            data.push(match)
        })
        setDisplayData(data)
    }


    const onKeyDown = (event) => {
        // console.log("event key", event.key);
        if (event.key === 'Enter') {
            event.preventDefault();

            if (event.target.value.length >= 3) {
                addPhrase(event.target.value)
            }
        }
    };

    function addPhrase(phrase) {
        let match = matchPhrase(products, phrase)
        let copyList = cloneDeep(displayData)
        // add to the top of the list
        copyList.unshift(match)
        setAnimationPhrases([phrase])
        setTimeout(() => {
            setDisplayData(copyList)
        }, 100)

    }

    function onChangeSearchTerm(query) {
        setSearchTerm(query);
    }

    function onMicTouch() {
        setSearchTerm('')
        searchInputRef.current.focus()
    }


    function displayMatches(item) {
        if (item?.matches?.length) {
            return (
                <ul style={{ marginBottom: 0 }}>
                    {item.matches?.map((match, index) => (
                        <li key={index} style={{ fontSize: 14 }}>
                            {match?.perc.toFixed(0)}% match to {match?.name}, {match?.uom}
                        </li>
                    ))}
                </ul>
            )
        }
        else {
            return <ul style={{ marginBottom: 0 }}><li>No matches found</li></ul>
        }
    }

    function displayIcon(item) {

        if (item?.actual) {
            return (
                <div className={`avatar-container flex-shrink-0 match`}>
                    <Check color="white" size="22" />
                </div>
            )
        }
        else if (item?.matches?.length) {
            return (
                <div className={`avatar-container flex-shrink-0 close-match`}>
                    <Check color="white" size="22" />
                </div>
            )
        }
        else {
            return (
                <div className={`avatar-container flex-shrink-0`}>
                    <X color="white" size="22" />
                </div>
            )
        }

    }

    function onSelectItem(item) {
        setSearchTerm(item.spoken)
        setSelectedItem(item)
        setShowModal(true)
    }

    function onCloseModal() {
        setShowModal(false)
        setSearchTerm('')
    }

    function onCloseRecordModal() {
        setShowRecordModal(false)
    }

    function getSuccessCount() {

        let count = 0;
        displayData.forEach(function (item, index) {

            if (item?.actual) {
                count++;
            }
            else if (item?.matches?.length) {
                count++
            }
            else {

            }

        })

        let perc = (count / displayData.length) * 100
        let rounded = Math.round(perc)

        //return <>Using a combination of product name and unit-of-measure, we successfully matched {count} of {displayData?.length} phrases to an inventory item (<strong>{rounded}%</strong>) </>

        return { count, rounded }
    }


    function onStartListening() {
        setIsListening(true)
    }
    function onStopListening() {
        setIsListening(false)
    }
    function collectRecordedPhrases(list) {
        // let copyList = cloneDeep(recordedPhrases)
        // copyList.unshift(phrase)
        console.log("recorded phrases", list)
        setRecordedPhrases(list)
    }
    function processRecordedPhrases() {

        let aplist = []
        
        let copyList = cloneDeep(displayData)
        recordedPhrases.forEach(function (phrase) {
            console.log("processing", phrase)
            let match = matchPhrase(products, phrase)
            copyList.unshift(match)
            aplist.push(phrase)
        })
        setAnimationPhrases(aplist)
        setTimeout(() => {
            setDisplayData(copyList)
        }, 100)

        setShowRecordModal(false)


    }


    function onRemoveItem(item) {
        const filteredList = displayData.filter((s) => s.spoken !== item.spoken);
        setDisplayData(filteredList)
    }

    return (
        <Container fluid className="">

            <TopNav dataTransferSuccess={true} />

            <Row className="justify-content-md-center mb-3">
                <Col sm="12" md="12" lg="6" xl="6" xxl="6">

                    <section className="mt-3">

                        <h4>Voice Inventory POC</h4>

                        <p className="mt-3 mb-4">A proof-of-concept that demonstrates how spoken phrases that contain a combination of product name, count, and unit-of-measure can be successfully matched to an inventory item. </p>

                        <div className="d-flex flex-row gap-2">
                            <Form className='d-flex flex-grow-1 border border-1 rounded' style={{ borderRadius: 25 }}>
                                <InputGroup className="flex-nowrap input-resize">
                                    <Form.Control
                                        type="search"
                                        placeholder="Say or type something like 'smoked salmon 7 pounds'..."
                                        className={'border-0 shadow-none'}
                                        aria-label="Say something..."
                                        value={searchTerm}
                                        onKeyDown={e => onKeyDown(e)}
                                        onChange={e => onChangeSearchTerm(e.target.value)}
                                        ref={searchInputRef}
                                    />
                                    <div className="bg-white rounded-start d-flex align-items-center p-2">
                                        <Search strokeWidth={2} size={20} />
                                    </div>
                                </InputGroup>
                            </Form>
                            <div className="record-button-small" onClick={() => {
                                setShowRecordModal(true);
                                setRecordedPhrases([])
                            }}>
                                <Mic size={22} color="white" strokeWidth="2" />
                            </div>
                        </div>


                        {/* <div className="score-pill mt-3 mb-3">
                                <span className="score-text">{getSuccessCount().rounded}% Success Rate</span>
                            </div> */}

                        {/* <div class="score2">
                                <span class="score2-text">{getSuccessCount().rounded}%</span>
                            </div> */}

                        <div className="mt-3 mb-3 p-1" style={{ fontSize: 18 }}><span style={{ fontWeight: 'bold' }}>{getSuccessCount().rounded}%</span> success rate, matching {getSuccessCount().count} of {displayData?.length} items</div>




                        <section className="gray p-1">


                            <div className="list-container">
                                {displayData.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div
                                                key={index}
                                                className={`list-item mb-1 bg-white ${animationPhrases.includes(item.spoken) ? 'new-item' : ''}`}
                                            >
                                                <div className="item-container">

                                                    {displayIcon(item)}

                                                    <div className="title-container">
                                                        <div className="title truncate-line-clamp">
                                                            <i>"{item.spoken}"</i>
                                                        </div>
                                                        <div className="sub-title">Reporting a quantity of <strong>{item?.qty}</strong> for the <strong>{item?.uom}</strong> unit-of-measure</div>
                                                        <div className="description">

                                                            {displayMatches(item)}

                                                        </div>
                                                    </div>
                                                    <Button variant="Hyperlink" className="chevron"
                                                        //</div>onClick={() => onSelectItem(item)}
                                                        onClick={() => onRemoveItem(item)}
                                                    >
                                                        <X color={"gray"} size={20} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                        </section>

                    </section>



                </Col>
            </Row>


            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Manually Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This is where the user could manually search for and select the correct inventory item or remove it.</p>


                    <Form className='d-flex border border-1 rounded' style={{ borderRadius: 25 }}>
                        <InputGroup className="flex-nowrap input-resize">
                            <Form.Control
                                type="search"
                                placeholder="Say something like 'smoked salmon 7 pounds'..."
                                className={'border-0 shadow-none'}
                                aria-label="Say something..."
                                value={searchTerm}
                                onKeyDown={e => onKeyDown(e)}
                                onChange={e => onChangeSearchTerm(e.target.value)}
                            />
                            <div className="bg-white rounded-start d-flex align-items-center p-2">
                                <Search strokeWidth={2} size={20} onClick={() => onMicTouch()} />
                            </div>
                        </InputGroup>
                    </Form>
                    <p className="mt-3 p-2">List of matched results go here...</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => onCloseModal()}>
                        Remove Item
                    </Button>
                    <Button variant="secondary" onClick={() => onCloseModal()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal size="sm" show={showRecordModal} onHide={() => setShowRecordModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Voice to Text</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tap to record your inventory counts.</p>

                    <p>With a brief pause between items, you can record multiple items at a time.</p>

                    <p><strong>Try saying phrases like the following:</strong></p>

                    <ul className="">
                        <li className="">"smoked salmon 7 pounds"</li>
                        <li className="">"firefly vodka sweet tea 2 liters"</li>
                        <li className="">"cucumbers 12 each"</li>
                    </ul>

                    <MicrophoneAccess
                        whenStartRecording={() => onStartListening()}
                        whenStopRecording={() => onStopListening()}
                        // whenAddPhrase={(item) => collectRecordedPhrase(item)} 
                        onPhrasesChange={(phrases) => collectRecordedPhrases(phrases)} />

                </Modal.Body>
                <Modal.Footer>
                    {recordedPhrases.length > 0 &&
                        <Button variant="outline-secondary" onClick={() => processRecordedPhrases()}>
                            Close and Add to List
                        </Button>
                    }
                    <Button variant="outline-secondary" onClick={() => onCloseRecordModal()}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>



            {/* <div className="record-button" onClick={() => setShowRecordModal(true)}>
                <Mic size={28} color="white" strokeWidth="2" />
            </div> */}



        </Container>
    );
}

export default VoiceInventory;
