import React, { useState, useRef, useEffect } from 'react';
import { Mic, Square, XSquare } from "react-feather";
import cloneDeep from "lodash/cloneDeep";


function SpeechToText({ whenStartRecording, whenStopRecording, onPhrasesChange }) {
  const [hasPermission, setHasPermission] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);  // Persist recognition instance
  const startSoundRef = useRef(null); // Reference for start sound
  const stopSoundRef = useRef(null);  // Reference for stop sound
  const [phrases, setPhrases] = useState([])

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

  // anytime phrases change, bubble to parent 
  useEffect(() => {
    onPhrasesChange(phrases)
  },[phrases])

  useEffect(() => {
    if (SpeechRecognition && !recognitionRef.current) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = false;
      recognition.lang = 'en-US'; // Set language

      recognition.onstart = () => {
        //console.log("onstart");
        setIsListening(true);
        whenStartRecording()
        startSoundRef.current.play(); // Play start sound
      };

      recognition.onend = () => {
        //console.log("onend");
        setIsListening(false);
        // state not ready in time here, so create a messy useEffect to handle
        whenStopRecording()
        stopSoundRef.current.play(); // Play stop sound
      };

      // recognition.onspeechstart = () => {
      //   console.log("Speech detected!");
      // };
      // recognition.onspeechend = () => {
      //   console.log("Speech ended");
      // };
      // recognition.onsoundstart = () => {
      //   console.log("Sound detected!");
      // };
      // recognition.onsoundend = () => {
      //   console.log("Sound ended");
      // };

      recognition.onresult = (event) => {
        const lastResult = event.results[event.resultIndex];
        if (lastResult.isFinal) {
          const transcript = lastResult[0].transcript.trim().toLowerCase();
          setPhrases((prevPhrases) => [...prevPhrases, transcript]); // Update phrases with new phrase
          //console.log(`Phrase recorded: "${transcript}"`);
        }
      };

      recognition.onerror = (error) => {
        console.error('Speech recognition error:', error);
        setIsListening(false);
      };

      recognitionRef.current = recognition; // Save instance
    } else if (!SpeechRecognition) {
      console.warn("Browser doesn't support SpeechRecognition API");
    }
  }, [SpeechRecognition, phrases]);

  const toggleListening = () => {
    if (!isListening) {
      recognitionRef.current?.start();
      setHasPermission(true);
    } else {
      recognitionRef.current?.stop();
    }
  };

  function onRemovePhrase(phrase) {
    const filteredList = phrases.filter((s) => s !== phrase);
    setPhrases(filteredList)
  }

  return (
    <div className="mt-4 mb-4">

      <div
        className={`record-button-large ${isListening ? 'recording' : ''}`}
        onClick={toggleListening}
      >
        {!isListening ? (
          <Mic size={28} color="white" strokeWidth="2" />
        ) : (
          <Square size={24} color="white" strokeWidth="2" style={{ backgroundColor: "white" }} />
        )}
      </div>


      {phrases.length > 0 &&
        <div className="mt-4 mb-5">
          <p className="fw-bold">Captured Phrases</p>
          {phrases.map((phrase, index) => (
            <section key={index} className="gray p-3 mb-2">
              <div className="d-flex flex-row">
                <div className="flex-grow-1">{phrase}</div>
                <div className="flex-shrink-0"><XSquare size={20} color="gray" onClick={() => onRemovePhrase(phrase)}/></div>
              </div>
            </section>
          ))}
        </div>
      }


      {/* Hidden audio elements to load sounds */}
      <audio ref={startSoundRef} src="/sounds/start.mp3" preload="auto" />
      <audio ref={stopSoundRef} src="/sounds/start.mp3" preload="auto" />

    </div>
  );
}

export default SpeechToText;
