import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    InputGroup,
    Dropdown,
    Offcanvas, ToastContainer, Toast, Modal
} from "react-bootstrap";
import { Search, ArrowUp, ArrowDown, ChevronDown, Check } from "react-feather";

import cloneDeep from "lodash/cloneDeep";

import OffcanvasFilters from "../Report/OffcanvasFilters";
import OffcanvasReport from "../Report/OffcanvasReport";
import Toaster from "../Report/Toaster";
import OffcanvasDownloads from "../Report/OffcanvasDownloads";
import sidemenuItems from "./SampleData/sidemenu-items.json";
import downloadIcon from '../../Images/icon-download-ready.svg';

import { dynamicSort } from "../../Helpers/DynamicSort";

import "./OffcanvasMenuNew.css"

import searchItems from "./SampleData/raw-search-items.json";
// Shuffle the array to randomly select 20 items
//let shuffledItems = searchItems.sort(() => 0.5 - Math.random()); 
let shuffledItems = searchItems.sort(dynamicSort('menuFunctionName', 1));;
// Set the favoritesFlag to true for the first 20 items
shuffledItems.slice(0, 12).forEach(function (item) {
    item.favoritesFlag = true;
});
searchItems.forEach(function (item) {
    if (item.tipText?.length > 100) {
        item.tipText = item.tipText.substring(0, 100) + '...'
    }
});

// Map reducer to return unique list of items with id and label
// const sidemenuItems = searchItems.reduce((acc, item) => {
//     // Check if the item is already in the result
//     if (!acc.some(el => el.id === item.parentMenuHeirarchyId)) {
//       acc.push({
//         id: item.parentMenuHeirarchyId,
//         label: item.parentMenuName
//       });
//     }
//     return acc;
//   }, []);


function OffcanvasMenu(props) {

    const MAX_COUNT = 999

    const searchInputRef = useRef();

    const [show, setShow] = useState(props?.show);
    const [showFilters, setShowFilters] = useState(false);
    const [showDownloads, setShowDownloads] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [forceRerender, setForceRerender] = useState(null);
    const [reportStack, setReportStack] = useState([]);
    const [toastStack, setToastStack] = useState([]);
    const [downloadStack, setDownloadStack] = useState([]);
    const [excelAB, setExcelAB] = useState('queue');
    const [filters, setFilters] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [filteredCount, setFilteredCount] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    let [searchParams] = useSearchParams();

    const [selectedCategory, setSelectedCategory] = useState(sidemenuItems[0]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);

    // set focus here once the input field is rendered
    useEffect(() => {


        searchInputRef.current.focus();

        // default the search term and set focus
        // check query string param first...
        let query = searchParams.get('searchTerm')
        // check if the top nav search is passing in a search term...
        if (props?.searchTerm !== '') {
            query = props?.searchTerm;
            console.log("default query");
            setSearchTerm(query);
            onChangeSearchTerm(query);
            setSelectedCategory(null)
            setSelectedSubCategory(null)
        }
        // default favorites
        // else if (props?.defaultCategoryIndex === 1) {
        //     onCategorySelection({ id: -2, label: "Favorites", subs: null })
        //     console.log("default favs");
        // }
        // else {
        //     console.log("default end");
        //     // const newList = searchItems.filter((s) => s.parentMenuName === "Reports");
        //     let items = findItemsWithSearchTerm("and"); // just to display a mixture of types
        //     const sliced = items.slice(0, 999); // only display top 12
        //     sliced.sort(dynamicSort('activityCount', -1));
        //     setFilteredItems(sliced);
        // }
        else {
            onCategorySelection({ id: -3, label: "Recent Activity", subs: null })
            console.log("default favs");
        }


    }, [])

    // needed to ensure input focus is set correctly when opened from top nav
    useEffect(() => {
        searchInputRef.current.focus();
    }, [props?.searchTerm])


    // const [searchParams] = useSearchParams();
    // const excelAB = searchParams.get('excelAB') || "queue"; // queue or toast
    // console.log("excelAB", excelAB);
    function onSelectCategory(cat) {
        console.log("cat", cat)
        setSelectedCategory(cat)
        onCategorySelection(cat)

        setSearchTerm('')

    }
    function onSelectSubCategory(event, cat, sub) {
        // prevent the parent click event from firing
        event.preventDefault()
        event.stopPropagation()
        setSelectedCategory(cat)
        setSelectedSubCategory(sub)
        onSubSelection(sub)
        setSearchTerm('')
    }

    function onCategorySelection(category) {
        console.log("category", category);

        // only affects mobile users
        // only when a category has no subs (allow user to expand the category)
        if (!category.subs) {
            setShowMenu(false);
        }

        // if favorites or recent activity, fake something...
        if (category.label === "Favorites") {

            let copyList = searchItems.filter((s) => s.favoritesFlag === true)
            // sort by name
            copyList.sort(dynamicSort('menuFunctionName', 1));
            setFilteredItems([])
            setTimeout(() => {
                setFilteredItems([...copyList])
            }, 100)


        }
        else if (category.id < 0) {
            let term = "and";
            if (category.id === -2) term = "list"; // fake it
            let items = findItemsWithSearchTerm(term); // just to display a mixture of types
            const sliced = items.slice(0, MAX_COUNT); // only display top 12
            //sliced.sort(dynamicSort('activityCount', -1));
            sliced.sort(dynamicSort('menuFunctionName', 1));
            setFilteredItems([])
            setTimeout(() => {
                setFilteredItems([...sliced])
            }, 100)
        }
        else {
            const newList = searchItems.filter((s) => s.parentMenuName === category.label);
            const sliced = newList.slice(0, MAX_COUNT); // only display top 12
            //sliced.sort(dynamicSort('activityCount', -1));
            setFilteredItems([])
            setTimeout(() => {
                setFilteredItems([...sliced])
            }, 100)
        }
    }
    function onSubSelection(sub) {
        console.log("sub", sub);

        // only affects mobile users
        setShowMenu(false);

        const newList = searchItems.filter((s) => s.menuName === sub.label);
        console.log("sub list", newList)
        const sliced = newList.slice(0, MAX_COUNT); // only display top 12
        //sliced.sort(dynamicSort('activityCount', -1));
        sliced.sort(dynamicSort('menuFunctionName', 1));
        setFilteredItems([])
        setTimeout(() => {
            setFilteredItems([...sliced])
        }, 100)
    }

    function onSelectSearchItem(event, item) {
        console.log("item", item)
        setSelectedReport(item);
        setShowFilters(!showFilters);
        setForceRerender(new Date());
    }

    function onDrilldown(parent, sub) {
        setForceRerender(new Date().toString());
        let newList = cloneDeep(reportStack);
        newList.push({ show: true, index: reportStack.length + 1, ...sub, parent });
        setReportStack(newList);
    }

    function onApplyFilters() {

        // TODO: When new filters are applied, we need to tell Builder.js to refetch data.
        let newFilters = { displayBy: 'NET', jiggleTheHandle: new Date().getTime() }
        setFilters(newFilters);


        setForceRerender(new Date().toString());
        const item = selectedReport;
        // reset the report stack with specific properties of the selected search result item
        const index = reportStack.length + 1;
        let newItem = { show: true, index, id: item.menuFunctionId, title: item.menuFunctionName, description: item.tipText };
        setReportStack([newItem]);
    }

    function exportSelectedReport(abSwitch, yesDownload, delay) {

        console.log("export", abSwitch, yesDownload, delay);
        const fileName = encodeURIComponent(selectedReport.title);
        const time = new Date().getTime();
        const stringKey = fileName + time.toString(); // used to remove it later...


        setExcelAB(abSwitch);

        if (abSwitch === "queue") {

            // setShowFilters(false);
            setShowDownloads(true);

            console.log("stringKey", stringKey);

            let newDownloadList = cloneDeep(downloadStack);
            newDownloadList.push({ title: selectedReport.title, fileName, time, stringKey })
            setDownloadStack(newDownloadList);

        }

        else if (abSwitch === "toast") {


            let newList = cloneDeep(toastStack);
            let newItem = { title: selectedReport.title, description: `${selectedReport.title} has been queued for download`, stringKey, icon: downloadIcon };
            newList.push(newItem)
            setToastStack(newList);

            if (yesDownload) {
                let url = `//rosreports-786ef1499c8d.herokuapp.com/api/excel-report?filename=${fileName}&delay=${delay}`;

                fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
                    .then(res => res.blob())
                    .then(res => {
                        const aElement = document.createElement('a');
                        aElement.setAttribute('download', fileName + ".xlsx");
                        const href = URL.createObjectURL(res);
                        aElement.href = href;
                        aElement.setAttribute('target', '_blank');
                        aElement.click();
                        URL.revokeObjectURL(href);

                        console.log("looking to remove", newItem)
                        removeToastFromStack(newItem);


                    });
            }
        }

    }

    function removeDownloadFromStack(stringKey) {
        const newList = downloadStack.filter((s) => s.stringKey !== stringKey);
        setDownloadStack(newList);
    }
    function removeToastFromStack(item) {
        const newList = toastStack.filter((s) => s.stringKey !== item.stringKey);
        console.log("newList", newList)
        setToastStack(newList);
    }



    // remove the report from the stack
    function onPrev() {

        // set show=false for the active report so that it slides out. Then, set timer to remove it from the stack to clean up the array
        let newList = cloneDeep(reportStack);
        newList[newList.length - 1].show = false;
        setReportStack(newList);

        // add a bit of delay, then clear the report from the stack
        setTimeout(function () {
            setReportStack(newList.slice(0, newList.length - 1));
        }, 500);

    }

    function closeAll() {
        setShowFilters(false);
        setForceRerender(new Date().toString());
        // clear the stack
        setReportStack([]);
    }

    // find the selected reportStack item and clear all items past its index
    function onSelectBreadcrumb(breadcrumb) {

        // console.log("breadcrumb", breadcrumb.title, breadcrumb.index);

        // for any in the reportStack past the index, set show=false so that they visually slide out before updating the stack
        let newReportStack = cloneDeep(reportStack);
        newReportStack.forEach(function (item, index) {
            item.show = false;
            // REMINDER: Filter Options is at index 0, so account for that
            if (index < breadcrumb.index) {
                item.show = true;
            }
        })
        // console.log("(newReportStack", newReportStack)
        setReportStack(newReportStack)

        // add a bit of delay, then recreate the report stack with whats left...
        setTimeout(function () {
            const purgedList = newReportStack.slice(0, breadcrumb.index);
            // console.log("(newReportStack.slice(0, breadcrumbIndex)", purgedList)
            setReportStack(purgedList);
        }, 500);
    }



    function onChangeSearchTerm(query) {
        console.log("filtering results for", query);
        setSelectedCategory(null)
        setSelectedSubCategory(null)
        setSearchTerm(query);
        if (query.length > 0) {
            let items = findItemsWithSearchTerm(query)
            // setFilteredCount(items.length);
            const sliced = items.slice(0, MAX_COUNT); // only display top 12
            //sliced.sort(dynamicSort('activityCount', -1));
            sliced.sort(dynamicSort('menuFunctionName', 1));
            setFilteredItems(sliced);
        }
        else {
            // clear all search 
            setFilteredItems([...searchItems])
        }
    }
    function findItemsWithSearchTerm(searchTerm) {
        // Split the search term into individual words
        const words = searchTerm.toLowerCase().split(' ');

        // Filter the items array to find matches
        return searchItems.filter(item => {
            // Combine all text elements search
            const combinedText = `${item.menuFunctionName.toLowerCase()} ${item.tipText.toLowerCase()} ${item.parentMenuName.toLowerCase()} ${item.menuName.toLowerCase()}`;

            // Check if all words in the search term exist in the combined text
            return words.every(word => combinedText.includes(word));
        });
    }


    // this prevents the click of the parent div
    function handleActionClick(event, item, index, mode) {
        event.stopPropagation(); // Prevents the click event from bubbling to the div
        alert("Button clicked!");

    }

    // this prevents the click of the parent div
    function handleFavoriteClick(event, item, index, mode) {
        event.stopPropagation(); // Prevents the click event from bubbling to the div

        let copyList = cloneDeep(filteredItems)
        //      copyList = copyList.filter(s => s.folderName === cat.name);
        let found = copyList.find((s) => s.menuFunctionId === item.menuFunctionId)
        found.favoritesFlag = !found.favoritesFlag || false
        setFilteredItems([...copyList])


        // also update the fake master list
        let found2 = searchItems.find((s) => s.menuFunctionId === item.menuFunctionId)
        found2.favoritesFlag = !found2.favoritesFlag || false

    }

    function handleSortSelection(sortBy) {

        // let copyList = cloneDeep(assignGroupList)
        // if (permission === 'share') {
        //     copyList = cloneDeep(shareGroupList)
        // }

        // const found = copyList.find((s) => s.groupId === group.groupId);
        // if (found) {
        //     let exists = found.allowedGroupIds.find((s) => s === selectedGroup.groupId);
        //     if (exists) {
        //         console.log("exists", found.allowedGroupIds, selectedGroup.groupId)
        //         let newList = found.allowedGroupIds.filter(item => item !== selectedGroup.groupId);
        //         found.allowedGroupIds = newList
        //     }
        //     else {
        //         found.allowedGroupIds.push(selectedGroup.groupId)
        //     }
        // }

        // if (permission === 'share') {
        //     setShareGroupList(copyList)
        // }
        // else {
        //     setAssignGroupList(copyList)
        // }

    }

    return (
        <Offcanvas
            show={props?.show}
            onClose={props?.onClose}
            onHide={props?.onClose}
            placement="end"
            className="oc-shared"
        >
            <Offcanvas.Header>
                <Offcanvas.Title>
                    PowerCenter Menu
                    <div className="subtitle-short">Full menu with favorites and recently-used features.</div>
                </Offcanvas.Title>
                <button
                    type="button"
                    className="btn-close btn-close-white"
                    aria-label="Close"
                    onClick={props.onClose}
                ></button>
            </Offcanvas.Header>
            <Offcanvas.Body className="menu">

                <div className="text-center mt-4">
                    <h4>What can we help you find?</h4>
                </div>

                <div className="text-center">


                    <div className="search-box mt-4">
                        <Form className='d-flex mx-md-2 d-none d-sm-block border border-1 rounded'>
                            <InputGroup className="flex-nowrap input-resize">
                                <div className="bg-white rounded-start d-flex align-items-center p-2">
                                    <Search strokeWidth={2} size={20} />
                                </div>
                                <Form.Control
                                    type="search"
                                    placeholder="Search"
                                    className={'border-0 shadow-none'}
                                    aria-label="Search"
                                    value={searchTerm}
                                    // onKeyDown={e => onKeyDown(e)}
                                    onChange={e => onChangeSearchTerm(e.target.value)}
                                    ref={searchInputRef}
                                />
                            </InputGroup>
                        </Form>
                    </div>

                    <div className="pill-container mt-4">
                        <div className="animate-slide-up mt-2 d-flex flex-row flex-wrap justify-content-center gap-2 mt-3 mb-3 pb-2">
                            {sidemenuItems?.map((cat, index) => (
                                <div key={index}
                                    className={selectedCategory?.id === cat.id ? 'item-pill selected' : 'item-pill'}
                                    onClick={() => onSelectCategory(cat)}
                                >
                                    <>

                                        {cat.label}

                                        {cat.subs?.length > 0 &&
                                            <>
                                                {/* <ChevronDown size="18" color={selectedCategory?.id === cat.id ? 'white' : 'silver'} style={{ marginLeft: 1, marginRight: -5 }} />
                                                <div className="dropdown-content">
                                                    {cat.subs.map((sub, subIndex) => (
                                                        <div key={subIndex} className="button-container">
                                                            <Button variant="link" className="btn-link-off" onClick={() => onSelectSubCategory(cat, sub)}>{sub.label}</Button>
                                                        </div>
                                                    ))}
                                                </div> */}

                                                <Dropdown className="d-inline" autoClose="outside">
                                                    <Dropdown.Toggle variant="link" className="btn-link no-caret">
                                                        {/* {cat.label} */}
                                                        <ChevronDown size="22" color={selectedCategory?.id === cat.id ? 'white' : '#333f48'} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="p-3">
                                                        {cat.subs.map((sub, subIndex) => (
                                                            <Dropdown.Item as="button" onClick={(event) => onSelectSubCategory(event, cat, sub)} eventKey={subIndex} className="d-flex flex-row gap-3">

                                                                {sub.label}

                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </>
                                        }
                                    </>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="d-flex flex-row justify-content-center gap-5">
                        <div>Displaying {filteredItems.length} of {searchItems.length} menu items</div>
                        <Dropdown className="" style={{ position: "unset" }}>
                            <Dropdown.Toggle variant="default" className="p-0">
                                <ArrowDown color={'gray'} size="15" style={{ padding: 0 }} /> Sort Alphabetically
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {["Sort Alphabetically", "Sory by Popularity", "Sort by Most Recent Use"].map((g, gndx) => (
                                    <Dropdown.Item key={gndx} as="button" onClick={() => handleSortSelection(g)} eventKey={gndx} className="d-flex flex-row gap-3">
                                        <div className="flex-grow-1" style={{ minWidth: 150 }}>
                                            {g}
                                        </div>
                                        {g === "Sort Alphabetically" &&
                                            <Check size={15} strokeWidth="3" color={'#67A617'} />
                                        }
                                    </Dropdown.Item>
                                ))}

                            </Dropdown.Menu>
                        </Dropdown>



                    </div>

                    <div className="row mt-3 px-5">
                        <Col xs="12">
                            <Row className="gap-6">
                                {filteredItems.map((item, index) => {
                                    return (
                                        <div key={index}
                                            className={'col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 search-item no-select'}
                                            onClick={(event) => onSelectSearchItem(event, item)}>
                                            <div className="d-flex gap-3">
                                                <div>
                                                    <div className="d-flex">
                                                        <img src={`/img/search-icons/blue/${item.parentMenuName}.svg`} alt={item.menuFunctionName} />
                                                        {item.favoritesFlag &&
                                                            <div className="star-on">
                                                                <Button variant="link" className="btn-link-off" onClick={(event) => handleFavoriteClick(event, item, index)}>
                                                                    <img src="/img/search-icons/blue/StarOn.svg" alt="Favorite" />
                                                                </Button>
                                                            </div>
                                                        }
                                                        {!item.favoritesFlag &&
                                                            <div className="star-off">
                                                                <Button variant="link" className="btn-link-off" onClick={(event) => handleFavoriteClick(event, item, index)}>
                                                                    <img src="/img/search-icons/blue/StarOff.svg" alt="Favorite" />
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <div className="d-flex flex-row align-items-start justify-content-between"> {/* Updated alignment classes */}
                                                        <div className="flex-grow-1">
                                                            <h5>{item.menuFunctionName}</h5>
                                                        </div>
                                                    </div>
                                                    <div>{item?.tipText || `Sorry, we don't have a description for ${item.menuFunctionName}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Row>
                        </Col>




                    </div>





                    <OffcanvasFilters
                        show={showFilters}
                        forceRerender={forceRerender}
                        report={selectedReport}
                        onClose={closeAll}
                        onApplyFilters={onApplyFilters}
                        onExport={exportSelectedReport}
                    />


                    {excelAB === "queue" &&
                        <OffcanvasDownloads
                            show={showDownloads}
                            onClose={() => setShowDownloads(false)}
                            forceRerender={forceRerender}
                        />
                    }

                    {excelAB === "toast" &&
                        <Toaster
                            show={toastStack.length ? true : false}
                            toasts={toastStack}
                            onClose={removeToastFromStack}
                        />
                    }



                    {reportStack.map((report, index) => {
                        return (
                            <OffcanvasReport
                                key={index}
                                index={index}
                                // OffcanvasFilters already causes the backdrop to darken
                                backdrop={false}
                                forceRerender={forceRerender}
                                report={report}
                                onClose={closeAll}
                                onPrev={onPrev}
                                onDrilldown={onDrilldown}
                                breadcrumbs={{
                                    items: reportStack,
                                    filterOptionsAtRoot: true
                                }}
                                onSelectBreadcrumb={onSelectBreadcrumb}
                                filters={filters}
                                onExport={exportSelectedReport}
                            />
                        );
                    })}


                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default OffcanvasMenu;
