// https://codepen.io/keithakritselis/pen/BOEMQB


import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Card
} from "react-bootstrap";
import { Check } from "react-feather";

import cloneDeep from "lodash/cloneDeep";

// color palette source
import paletteList from "../../Fixtures/color-palettes.json";

import "./ColorPaletteListStyles.css";
import { color } from "chart.js/helpers";

// Using React state for this during the drag state was not updating in time between onDragStart and onDragEnter
// the call of setState itself is synchronous, but it does not mean that the state is updated immediately after calling setState. 
// This update is determined according to the context of our current execution environment.
let selectedPaletteId = null;

function ColorPaletteList(props) {

    const [itemBeingDragged, setItemBeingDragged] = useState(null)
    // const [selectedPaletteId, setSelectedPaletteId] = useState(null)
    const [colorPalettes, setColorPalettes] = useState(paletteList)
    const [selectedPalette, setSelectedPalette] = useState(null);
    const [showTipper, setShowTipper] = useState(false);

    useEffect(() => {

        setColorPalettes(paletteList);
        setSelectedPalette(paletteList[0]);

        // just to demo persisting the selected palette
        const savedPalette = JSON.parse(localStorage.getItem("colorPalette"));
        console.log("saved palette?", savedPalette)
        if (savedPalette) {
            let found = paletteList.find((s) => s.id === savedPalette.id);
            if (found) {
                // Important: we will want to present the colors in the order that they saved them...
                setSelectedPalette(savedPalette);

                console.log("saved", savedPalette)
                found.colors = savedPalette.colors;
                setColorPalettes(paletteList); // this updates the master list to include the user's customized order
            }
        }


    }, []);

    useEffect(() => {

        console.log("colorPalettes changed", colorPalettes)

    }, [colorPalettes])

    function onDragStart(e, item) {

        // avoiding React state here because of latency...
        selectedPaletteId = e.target.getAttribute('data-palette-id');

        //console.log("onDragStart", e.target.id, "palette", e.target.getAttribute('data-palette-id'));
        e.dataTransfer.setData("text", e.target.id);
        setItemBeingDragged(item)
    }

    function onDragOver(e) {
        e.stopPropagation();
        e.preventDefault();
        //console.log("onDragOver", e.target.id)

        // if trying to drag into a different palette
        //if(e.currentTarget.getAttribute('data-palette-id') !== e.target.getAttribute('data-palette-id')) {
        if (selectedPaletteId !== e.target.getAttribute('data-palette-id')) {
            e.dataTransfer.dropEffect = "none";
        }

    }

    function onDragEnter(e, item) {
        e.stopPropagation();
        e.preventDefault();
        //let container_ndx = e.target.dataset.container
        //console.log("onDrageEnter", "source", itemBeingDragged, "target", item)
    }

    function onDrop(e, item, index) {
        //console.log("onDrop", item, itemBeingDragged, index);
        e.preventDefault();
        e.stopPropagation();


        let foundPalette = colorPalettes.find((s) => s.id === parseInt(selectedPaletteId));
        // tell the parent what has moved to what new index
        console.log("found", selectedPaletteId, foundPalette)
        onMoveColor(foundPalette, itemBeingDragged, index);

        setItemBeingDragged(null);
        setShowTipper(false)
    }

    function onDragEnd() {
        setItemBeingDragged(null);
    }

    function onSelectPalette(palette) {
        setSelectedPalette(palette);

        // update local storage before triggering a display refresh
        localStorage.setItem("colorPalette", JSON.stringify(palette));
        props?.onTriggerDisplayRefresh(palette)

        setShowTipper(true)

    }

    function onMoveColor(palette, item, newIndex) {

        setSelectedPalette(palette);
        let newList = cloneDeep(colorPalettes);
        let foundPalette = newList.find((s) => s.id === palette.id);
        if (foundPalette) {

            let list = [];
            // get rid of the color being moved
            let wo = foundPalette.colors.filter((s) => s !== item);
            console.log("found color", wo)
            //console.log("wo", JSON.stringify(wo));
            for (let i = 0; i < wo.length; i++) {
                // jam it back into its new index position
                if (i === newIndex) {
                    list.push(item);
                }
                // push in the next one
                list.push(wo[i]);
            }
            // if it is being moved to the end...
            if (newIndex === foundPalette.colors.length - 1) {
                list.push(item);
            }

            //console.log("list", JSON.stringify(list));
            foundPalette.colors = list;

            console.log("new colors", JSON.stringify(foundPalette.colors, null, 2))


            setColorPalettes(newList);

            // update local storage before triggering a display refresh
            localStorage.setItem("colorPalette", JSON.stringify(foundPalette));
            props?.onTriggerDisplayRefresh(palette)

        }

    }


    return (


        <div className="palette-list">

            <Row className="g-1">
                {colorPalettes.map((palette, paletteIndex) => {
                    return (
                        <Col key={paletteIndex} xs={12} sm={6} md={6} lg={6} xl={6} xxl={4} className="pb-3 card-deck">

                            <Card key={paletteIndex} className={palette.id === selectedPalette?.id ? 'selected' : ''}>
                                <Card.Header>{palette.name}</Card.Header>
                                <Card.Body className="bg-white"><div className="d-flex align-content-start flex-row flex-wrap gap-1 p-3 h-100" style={{ minHeight: 111 }}>
                                    {showTipper && palette.id === selectedPalette?.id &&
                                        <div className="tipper fade-in-text p-2 text-center">Use drag-and-drop to rearrange these colors</div>
                                    }
                                    {palette.colors.map((hexCode, index) => {
                                        return (
                                            <div key={index} className={hexCode === itemBeingDragged ? 'palette-list color dragged' : 'palette-list color'} style={{ backgroundColor: hexCode }}
                                                draggable={true}
                                                onDragStart={e => onDragStart(e, hexCode, index)}
                                                onDrop={(e) => onDrop(e, hexCode, index)}
                                                onDragOver={onDragOver}
                                                onDragEnter={(e) => onDragEnter(e, hexCode, index)}
                                                onDragEnd={(e) => onDragEnd()}
                                                data-palette-id={palette.id}
                                            >
                                            </div>
                                        );
                                    })}
                                </div></Card.Body>
                                <Card.Footer className="bg-gray">
                                    {/* <Card.Title>{palette.name}</Card.Title> */}
                                    <Button className="w-100" variant={palette.id === selectedPalette?.id ? 'success' : 'outline-secondary'}
                                        onClick={() => onSelectPalette(palette)}>
                                        {palette.id === selectedPalette?.id ?
                                            <><Check size={18} /> Selected</>
                                            :
                                            <>Select</>
                                        }
                                    </Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </div>


    );
}

export default ColorPaletteList;
