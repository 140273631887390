import React, { useState, useEffect } from 'react';

import {
  Button, Form, Row, Col, Tabs, Tab
} from "react-bootstrap";

import { MinusSquare, Edit, Minus, HelpCircle } from "react-feather";
import cloneDeep from "lodash/cloneDeep";

import "./Styles.css";

const initialCondition = [
  {
    field: '',
    operator: '',
    value: '',
  },
];

const initialGroup = { id: new Date().getTime(), conditions: initialCondition, returnValue: '' }

const DynamicSQLCaseBuilder = ({ selectedGroup, onChange }) => {
  const [conditionGroups, setConditionGroups] = useState([]);
  const [defaultReturnValue, setDefaultReturnValue] = useState('');
  const [groupBeingEdited, setGroupBeingEdited] = useState(null);

  useEffect(() => {

    if(selectedGroup) {
      setConditionGroups(selectedGroup.customProps)
      setDefaultReturnValue(selectedGroup.customDefaultReturnValue)
    }
    else {
      setConditionGroups([initialGroup])
      setGroupBeingEdited(initialGroup)
    }

  }, [selectedGroup]);

  useEffect(() => {
    // bubble changes to parent
    const sql = buildSQLCaseStatement()
    onChange({ conditionGroups, defaultReturnValue, sql })
  }, [conditionGroups, defaultReturnValue]);


  const handleConditionChange = (groupIndex, conditionIndex, field, value) => {
    const updatedGroups = cloneDeep(conditionGroups);
    updatedGroups[groupIndex].conditions[conditionIndex][field] = value;
    setConditionGroups(updatedGroups);
    setGroupBeingEdited(updatedGroups[groupIndex])
  };

  const handleReturnValueChange = (groupIndex, value) => {
    const updatedGroups = cloneDeep(conditionGroups);
    updatedGroups[groupIndex].returnValue = value;
    setConditionGroups(updatedGroups);
  };

  const addCondition = (groupIndex) => {
    const updatedGroups = cloneDeep(conditionGroups);
    updatedGroups[groupIndex].conditions.push({
      field: '',
      operator: '',
      value: '',
    });
    setConditionGroups(updatedGroups);
  };

  const removeCondition = (groupIndex, conditionIndex) => {
    const updatedGroups = cloneDeep(conditionGroups);
    let group = updatedGroups[groupIndex]
    group.conditions = group.conditions.filter((item, index) => index !== conditionIndex);
    setConditionGroups(updatedGroups);
  };

  const addConditionGroup = () => {
    let newGroup = { ...initialGroup, id: new Date().getTime() }
    setGroupBeingEdited(newGroup)
    setConditionGroups([
      ...conditionGroups, newGroup,
    ]);
  };

  function parseValue(n) {
    const isNumber = !isNaN(n)

    if (!isNumber) {
      if (n.includes("'")) {
        return n
      }
      else {
        return `'${n}'`
      }
    }
    else return n
  }

  const buildSQLCaseStatement = (group) => {
    if (group) {

      let sql = '  WHEN ';
      group.conditions.forEach((condition, index) => {
        sql += `${condition.field} ${condition.operator} ${parseValue(condition.value)}`;
        if (index < group.conditions.length - 1) {
          sql += ' AND ';
        }
      });
      sql += ` THEN ${parseValue(group.returnValue)}\n`;

      return sql;

    }
    else {
      let sql = 'CASE\n';
      conditionGroups.forEach((group) => {
        sql += '  WHEN ';
        group.conditions.forEach((condition, index) => {
          sql += `${condition.field} ${condition.operator} ${parseValue(condition.value)}`;
          if (index < group.conditions.length - 1) {
            sql += ' AND ';
          }
        });
        sql += ` THEN ${parseValue(group.returnValue)}\n`;
      });
      sql += `  ELSE ${parseValue(defaultReturnValue)}\n`
      sql += 'END';
      return sql;
    }
  };

  function onHandleEdit(group) {
    setGroupBeingEdited(group)
  }
  function onHandleDelete(group) {
    const updatedGroups = [...conditionGroups].filter((item, index) => item.id !== group.id);;
    setConditionGroups(updatedGroups);
    setGroupBeingEdited(null)

  }

  return (
    <div className="caseForm">

      <Tabs defaultActiveKey="cases">
        <Tab
          eventKey="cases"
          title="Build"
        >


          <section className="gray">

            {conditionGroups.length > 0 &&
              <h6><strong>Conditions for your custom column</strong></h6>
            }

            {conditionGroups.map((group, groupIndex) => (
              <div key={groupIndex} className="mb-2">

                {group.id !== groupBeingEdited?.id &&
                  <section className="bordered hover-effect px-1 py-1">
                    <div className="d-flex flex-row">
                      <div className="flex-grow-1 align-middle p-2">{buildSQLCaseStatement(group)}</div>
                      <div >
                        <Button variant="none" onClick={() => onHandleEdit(group)}><Edit size={20} color={'gray'} /></Button>
                        <Button variant="none" onClick={() => onHandleDelete(group)}><Minus size={20} color={'gray'} /></Button>
                      </div>
                    </div>
                  </section>
                }

                {group.id === groupBeingEdited?.id &&
                  <section className="section-active">
                    <h6><strong>Define one or more conditions</strong></h6>
                    <p>Use one or more columns, operators, and values to create a condition that returns a specific value. </p>

                    {group.conditions.map((condition, conditionIndex) => (
                      <div key={conditionIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>

                        <select
                          value={condition.field}
                          onChange={(e) => handleConditionChange(groupIndex, conditionIndex, 'field', e.target.value)}
                          className="form-select me-2"
                        >
                          <option value="">Select Column</option>
                          <option value="Record_Type">Record_Type</option>
                          <option value="Tipped_Job">Tipped_Job</option>
                          <option value="label">label</option>
                        </select>
                        <select
                          value={condition.operator}
                          onChange={(e) => handleConditionChange(groupIndex, conditionIndex, 'operator', e.target.value)}
                          className="form-select me-2"
                        >
                          <option value="">Select Operator</option>
                          <option value="=">Equals</option>
                          <option value="!=">Not Equal</option>
                          <option value=">">Greater Than</option>
                          <option value=">=">Greater Than or Equal</option>
                          <option value="<">Less Than</option>
                          <option value="<=">Less Than or Equal</option>
                        </select>

                        <input
                          type="text"
                          value={condition.value}
                          onChange={(e) => handleConditionChange(groupIndex, conditionIndex, 'value', e.target.value)}
                          placeholder="Value"
                          className="form-control me-2"
                        />

                        {conditionIndex > 0 &&
                          <Button variant="link" className="btn-link-off" onClick={() => removeCondition(groupIndex, conditionIndex)}>
                            <MinusSquare size={20} color={'gray'} strokeWidth={1} />
                          </Button>
                        }

                        {conditionIndex === group.conditions.length - 1 && (
                          <>
                            <Button variant="link" className="btn-link-off mx-2" onClick={() => addCondition(groupIndex)} style={{ marginLeft: '8px' }}>
                              And
                            </Button>
                          </>
                        )}


                      </div>
                    ))}
                    <div className="mt-4">
                      <h6><strong>Return Value</strong></h6>
                      <input
                        type="text"
                        value={group.returnValue}
                        onChange={(e) => handleReturnValueChange(groupIndex, e.target.value)}
                        placeholder="Return Value"
                        className="form-control"
                      />
                    </div>

                    <span className="d-flex flex-row flex-grow-0 p-0 gap-0">
                      <Button variant="primary" className="me-2 mt-3" onClick={() => setGroupBeingEdited(null)}>
                        Save Condition
                      </Button>
                      <Button variant="link" className="btn-link-off me-2 mt-3" onClick={() => onHandleDelete(group)}>
                        Remove
                      </Button>
                    </span>


                  </section>
                }
              </div>


            ))}

            {groupBeingEdited === null &&
              <div className={conditionGroups.length > 0 ? 'mt-4' : ''}>
                <h6><strong>Add a condition</strong></h6>
                <p>Add one or more conditions that can be used to return a custom value for your column. </p>
                <Button variant="primary" onClick={addConditionGroup}>
                  Add a Condition
                </Button>
              </div>
            }

          </section>

          <section className="gray mt-3">
            <h6><strong>Add a Default Return Value</strong></h6>
            <p>If custom return value conditions are not met, what default value would you like to use for this column?</p>
            <input
              type="text"
              value={defaultReturnValue}
              onChange={(e) => setDefaultReturnValue(e.target.value)}
              placeholder="Default Value"
              className="form-control me-2"
            />
          </section>


        </Tab>

        <Tab
          eventKey="details"
          title="Generated SQL Case Statement"
        >
          <section className="gray mt-3">
            <h6><strong>Generated SQL Case Statement</strong></h6>
            <pre>{buildSQLCaseStatement()}</pre>
          </section>

        </Tab>
      </Tabs>




    </div>
  );
};

export default DynamicSQLCaseBuilder;
