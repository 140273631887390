import React, { useState } from "react";

import "./LandingToggle.css";

function LandingToggle(props) {

  const [daypart, setDaypart] = useState('RT');

  function onSelect(val) {

    setDaypart(val);
    props.onChangeToggle();
  }


  return (
    <div className="lt toggle-container" href={props.href} onClick={props.onClick}>
      <div className="button-container">
        <button type="button" className={daypart === 'RT' ? `btn btn-sm btn-primary` : 'btn btn-sm btn-light'} onClick={() => onSelect('RT')}>RT</button>
        <button type="button" className={daypart === 'DAY' ? `btn btn-sm btn-primary` : 'btn btn-sm btn-light'} onClick={() => onSelect('DAY')}>DAY</button>
      </div>
    </div>
  )

}

export default LandingToggle;