import React, { useState, useEffect } from "react";

import {
  Offcanvas,
  Button,
  Dropdown,
  Modal
} from "react-bootstrap";

import {
  ChevronLeft,
  RefreshCw,
  Download,
  Printer,
  Share,
  ExternalLink,
  Filter
} from "react-feather";

import Builder from "./Builder";
import CopyLink from "./CopyLink";
import ButtonLikeAnchor from "./ButtonLikeAnchor"; // Adjust the path based on your project structure
import Breadcrumbs from "./Breadcrumbs";

import "../../Styles/OffcanvasShared.css";
import "./Styles/OffcanvasReport.css";
import "./Styles/Developer.css";

function OffcanvasReport(props) {
  const [showShareModal, setShowShareModal] = useState(false);
  const [show, setShow] = useState(props?.report.show);
  const [backOption, setBackOption] = useState("breadcrumbs");


  useEffect(() => {

    let lsval = localStorage.getItem('backoption');
    if(lsval) { 
      setBackOption(lsval);
    }

  },[]);


  useEffect(() => {
    console.log("props.forceRerender", props.forceRerender);
  }, [props.forceRerender]);

  function onDrilldown(parent, sub) {

    // incase we need to do other things before passing the data to the parent
    props.onDrilldown(parent, sub);

  }

  function onBack() {

    setShow(false);
    props.onPrev();
  }

  function onStatusChange(status) {
    //setIsLoading(status.loading); // true false;
  }

  return (
    <Offcanvas
      show={props?.report?.show}
      onHide={props.onClose}
      placement="end"
      className="oc-shared oc-report"
      // backdrop={props?.backdrop || false} // set to false, otherwise the background gets darker with each OffcanvasReport in the satck
      backdrop={ props.backdrop }
    >
      <Offcanvas.Header>
        <Offcanvas.Title>
          {props?.report?.title}
          <div className="subtitle-short">{props?.report?.description}</div>
        </Offcanvas.Title>
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={props.onClose}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="content-container">
          <div className="report-options">

              {/* Display breadrumbs from parent. The list is managed there. */}
              {/* {props?.breadcrumbs} */}

   
              <Breadcrumbs 
                items={props.breadcrumbs.items} 
                filterOptionsAtRoot={props.breadcrumbs.filterOptionsAtRoot}
                onSelectBreadcrumb={props.onSelectBreadcrumb} />
            

              {/* <Button variant="light" onClick={onBack}>
                <ChevronLeft />
                {props?.report?.parent &&
                  <span>{props?.report?.parent?.title}</span>
                }
                {!props?.report?.parent &&
                  <span>Filter Options</span>
                }
              </Button>
           */}


 
            <div className="icons">


              <ButtonLikeAnchor
                onClick={() => {
                  console.log("refresh");
                }}
              >
                <RefreshCw size={20} color={"lightGray"} />
              </ButtonLikeAnchor>

              <ButtonLikeAnchor
                onClick={() => {
                  setShowShareModal(true);
                }}
              >
                <Share size={20} color={"#333f48"} />
              </ButtonLikeAnchor>


              <ButtonLikeAnchor
                onClick={() => {
                  window.open(`/report?name=a-unique-name-value-goes-here&title=${props?.report?.title}`, '_blank');
                }}
              >
                <ExternalLink size={20} color={"#333f48"} />
              </ButtonLikeAnchor>


              <Dropdown size="md" variant="default">
                <Dropdown.Toggle variant="default" id="dropdown-basic" style={{ color: "#333f48" }}>
                  <Download size={20} color={"#333f48"} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={() => props?.onExport('toast', true, 2000) }>Excel</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props?.onExport('toast', true, 2000) }>PDF</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <ButtonLikeAnchor
                onClick={() => {
                  console.log("printer");
                }}
              >
                <Printer size={20} color={"lightGray"} />
              </ButtonLikeAnchor>


            </div>
          </div>

          <Builder 
            report={props?.report} 
            onStatusChange={onStatusChange}
            filters={props?.filters}
            forceRerender={props?.forceRerender}
            onDrilldown={onDrilldown} />

        </div>

        <Modal
          show={showShareModal}
          onHide={() => {
            setShowShareModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            To share this report, copy the link below and paste it into an email
            or text message.
            <CopyLink
              link={
                "http://localhost:3000/report?link=35c87cdc-e9b1-432e-9768-05e33459e088"
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              onClick={() => {
                setShowShareModal(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffcanvasReport;
