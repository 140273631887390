import React, { useState, useEffect } from "react";

import {
  Offcanvas,
  Button,
  Accordion,
  Row,
  Col,
  Form,
  ButtonGroup,
  Modal,
  Alert
} from "react-bootstrap";

import {
  Info,
  PlusCircle,
  ArrowLeftCircle
} from "react-feather";
import Dropdown from "../Dropdown/DropdownMultiselect";

import "../../Styles/OffcanvasShared.css";
import "./Styles/OffcanvasFilters.css";

const displayPeriods = ["RT", "DAY", "WTD", "7DR", "PTD", "YTD", "Custom"];

function OffcanvasFilters(props) {

  const [showAddFilter, setShowAddFilter] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);


  const [displayPeriod, setDisplayPeriod] = useState("DAY");

  useEffect(() => {

    //window.scrollTo(0, 0);;

  }, [props?.forceRerender]);

  // useEffect(() => {
  //   let lsval = localStorage.getItem('backoption');
  //   console.log("lsval", lsval);
  //   if(lsval) { 
  //     setBackOption(lsval);
  //   }
  //   else {
  //     // if doesn't exist, default it
  //     onSelectBackOption('breadcrumbs')
  //   }
  // },[]);


  // function onSelectBackOption(val) {
  //   console.log('back', val);
  //   localStorage.setItem('backoption', val);
  //   setBackOption(val);
  // }


  function onSelectDisplayPeriod(period) {

    setDisplayPeriod(period);

    if (period === "Custom") {
      setShowDateRange(true);
    }
    else {
      setShowDateRange(false);
    }
  }


  return (
    <Offcanvas
      show={props.show}
      onHide={props.onClose}
      placement="end"
      className="oc-shared oc-filters"
      // scroll is not working - should be disabled, but is not
      scroll={false}
      backdrop={true}
    >
      <Offcanvas.Header>
        <Offcanvas.Title>
          {props?.report?.menuFunctionName}
          <div className="subtitle-short">{props?.report?.tipText}</div>
        </Offcanvas.Title>
        {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
        <button
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={props.onClose}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="oc-filters">


        <Row className="justify-content-md-center mb-5">
          <Col xs lg="8">

            <div className="content-container">

              <div className="mt-4">
                <Button variant="link" className="btn-link-off" onClick={() => props.onClose()}><ArrowLeftCircle size={20} color={'gray'} style={{ marginRight: 5 }} />Back to Menu</Button>
              </div>

              <h4 className="mt-4">Filtering Options for {props?.report?.menuFunctionName}</h4>

              {/* <Alert variant="info" className="mt-5 mb-5">
                Please click '<strong>View Report</strong>'. This is a very early experiment for report filtering. The goal is to not overwhelm the user with too many choices at once.
              </Alert> */}

              <p className="mt-3 mb-4">
                <Info size={14} /> Tip: Use the filter options below to narrow down
                the results. When filters are not selected, you will see the
                broadest range of data.
              </p>



              {/* <Row className="mt-5 mb-4">
            <Col className="col-lg-2 pb-2 text-end">Saved Filter Options</Col>
            <Col className="col-lg-4 pb-2">
              <strong>Create a New Saved Filter</strong>  <PlusCircle size={18} style={{ color: 'gray', marginLeft: 5, cursor: 'pointer' }} onClick={() => setShowAddFilter(true)} />
            </Col>
          </Row> */}

              <Row className="mt-5 mb-4">
                {/* <Col className="col-lg-2 pb-2 text-end">Saved Filters:</Col> */}
                <Col className="col-lg-12 pb-2 pt-1">
                  <Accordion className="oc-filters" activeKey={"0"}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="white-background">Saved Filters</Accordion.Header>
                      <Accordion.Body>
                        <Row><Col className="col-lg-12 pb-2 saved-filter-buttons">

                          <Button variant="secondary">ABC Filters</Button>
                          <Button variant="outline-secondary">My Other Saved Filters</Button>
                          <Button variant="outline-secondary">Even More Saved Filters</Button>

                          <PlusCircle size={18} style={{ color: 'gray', marginLeft: 5 }} onClick={() => setShowAddFilter(true)} />
                        </Col></Row>

                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>



              <Row className="mb-4">
                {/* <Col className="col-lg-2 pb-2 text-end">Display Period:</Col> */}
                <Col className="col-lg-12 pb-2 pt-1">
                  <Accordion className="oc-filters" activeKey={"0"}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="white-background">Display Period</Accordion.Header>
                      <Accordion.Body>
                        <Row><Col className="col-lg-3 pb-2">
                          <ButtonGroup aria-label="Basic example">
                            {/* <Button variant="light" onClick={() => onSelectDisplayPeriod("RT")}>RT</Button>
                        <Button variant="secondary" onClick={() => onSelectDisplayPeriod("DAY")}>DAY</Button>
                        <Button variant="light" onClick={() => onSelectDisplayPeriod("WTD")}>WTD</Button>
                        <Button variant="light" onClick={() => onSelectDisplayPeriod("7DR")}>7DR</Button>
                        <Button variant="light" onClick={() => onSelectDisplayPeriod("PTD")}>PTD</Button>
                        <Button variant="light" onClick={() => onSelectDisplayPeriod("Custom")}>Custom</Button> */}

                            {displayPeriods.map((period, index) => {
                              return (
                                <Button key={index} variant={period === displayPeriod ? "secondary" : "light"} onClick={() => onSelectDisplayPeriod(period)}>{period}</Button>
                              )
                            })}

                          </ButtonGroup>
                        </Col></Row>


                        {showDateRange &&
                          <Row className="mt-3 mb-2">
                            <Col className="col-lg-3">


                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="date" placeholder="Start Date" />
                              </Form.Group>

                            </Col>
                            <Col className="col-lg-3">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="date" placeholder="End Date" />
                              </Form.Group>
                            </Col>
                          </Row>
                        }


                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>

              {/* {showDateRange &&
            <Row className="mb-4">
              <Col className="col-lg-12 pb-2 pt-1">
                <Accordion className="oc-filters" defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="white-background">Date Range</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col className="col-lg-3 pb-2">
                          <Form.Control type="date" placeholder="Start Date" />
                        </Col>
                        <Col className="col-lg-3 pb-2">
                          <Form.Control type="date" placeholder="End Date" />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          } */}


              <Row className="mb-4">
                {/* <Col className="col-lg-2 pb-2 text-end">Locations:</Col> */}
                <Col className="col-xl-12 pb-2 pt-1">
                  <Accordion className="oc-filters" activeKey={"0"}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="white-background">Locations</Accordion.Header>
                      <Accordion.Body>

                        <Row>
                          <Col className="col-lg-4 pb-2">
                            <Dropdown
                              placeholder={"Companies"}
                              choices={[]}
                              onChange={(item) => console.log(item)}
                            />
                          </Col>
                          <Col className="col-lg-4 pb-2">
                            <Dropdown
                              placeholder={"Markets"}
                              choices={[]}
                              onChange={(item) => console.log(item)}
                            />
                          </Col>
                          <Col className="col-lg-4 pb-2">
                            <Dropdown
                              placeholder={"Regions"}
                              choices={[]}
                              onChange={(item) => console.log(item)}
                            />
                          </Col>
                          <Col className="col-lg-4 pb-2">
                            <Dropdown
                              placeholder={"Locations"}
                              choices={[]}
                              onChange={(item) => console.log(item)}
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>


              {/* <Row className="mb-4">
                <Col className="col-lg-12 pb-2 pt-1">

                  <Accordion className="oc-filters">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="white-background">Category Display</Accordion.Header>
                      <Accordion.Body>
                        <Row><Col className="col-lg-6 pb-2">
                          <ButtonGroup aria-label="Basic example">
                            <Button variant="light">Sub-Categories</Button>
                            <Button variant="secondary">Major Categories</Button>
                          </ButtonGroup>
                        </Col></Row>

                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </Col>
              </Row> */}


              {/* <Row className="mb-4">
                <Col className="col-lg-12 pb-2 pt-1">
                  <Accordion className="oc-filters">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="white-background">Dayparts</Accordion.Header>
                      <Accordion.Body>
                        <Row><Col className="col-lg-6 pb-2">
                          <Dropdown
                            placeholder={"Dayparts"}
                            choices={[]}
                            onChange={(item) => console.log(item)}
                            singleSelect={false}
                          />
                        </Col></Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row> */}



              {/* <Row className="mb-4">
                <Col className="col-lg-12 pb-2 pt-1">
                  <Accordion className="oc-filters">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="white-background">Departments</Accordion.Header>
                      <Accordion.Body>
                        <Row><Col className="col-lg-6 pb-2">
                          <Dropdown
                            placeholder={"Departments"}
                            choices={[]}
                            onChange={(item) => console.log(item)}
                            singleSelect={false}
                          />
                        </Col></Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row> */}



              {/* <Row className="mb-4">
                <Col className="col-lg-12 pb-2 pt-1">
                  <Accordion className="oc-filters">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="white-background">Major Categories & Sub-Categories</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col className="col-lg-6 pb-2">
                            <Dropdown
                              placeholder={"Major Categories"}
                              choices={[]}
                              onChange={(item) => console.log(item)}
                              singleSelect={false}
                            />
                          </Col>
                          <Col className="col-lg-4 pb-2">
                            <Dropdown
                              placeholder={"Sub-Categories"}
                              choices={[]}
                              onChange={(item) => console.log(item)}
                              singleSelect={false}
                            />
                          </Col>

                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row> */}


              <Row className="mb-4">
                {/* <Col className="col-lg-2 pb-2 text-end">Dollars/Cover:</Col> */}
                <Col className="col-lg-12 pb-2 pt-1">
                  <Form.Check // prettier-ignore
                    // checked="true"
                    type="switch"
                    id="custom-switch"
                    label="Show Dollars per Cover"
                  />
                </Col>
              </Row>

              {/* <Row className="mb-4">
            <Col className="col-lg-2 pb-2 text-end">A/B Testing Drilldown Navigation</Col>
            <Col className="col-lg-6 pb-2">
              <ButtonGroup aria-label="Basic example">
                <Button variant={ backOption === 'backbutton' ? "success" : "light"} onClick={() => onSelectBackOption('backbutton')}>Back Button</Button>
                <Button variant={ backOption === 'breadcrumbs' ? "success" : "light"} onClick={() => onSelectBackOption('breadcrumbs')}>Breadcrumbs</Button>
              </ButtonGroup>
            </Col>
          </Row> */}




              <Row className="mb-5">
                {/* <Col className="col-lg-2"></Col> */}
                <Col className="col-lg-112">
                  <div className="filter-buttons">
                    <Button variant="primary" size={"lg"} onClick={props.onApplyFilters} style={{ marginRight: 20 }}>
                      View Report
                    </Button>

                    {/* <Button variant="light" size={"lg"} onClick={() => props?.onExport('queue')}>
                  Excel with Queue
                </Button> */}
                    <Button variant="light" size={"lg"} onClick={() => props?.onExport('toast', true, 2000)}>
                      Excel Export
                    </Button>
                    <Button variant="link" className="btn-link-off mx-4" onClick={() => props.onClose()}>Back to Menu</Button>
     
                    {/* <Form.Control type="number" value={delay} onChange={(e) => setDelay(e.target.value)} style={{ width: 50 }} /> */}
                    {/* <Button variant="light" size={"lg"} onClick={() => props?.onExport('toast', false)}>
                  Excel with Toast w/o Download
                </Button> */}


                  </div>
                </Col>
              </Row>


            </div>


            <Modal
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showAddFilter} onHide={() => setShowAddFilter(!showAddFilter)}>
              <Modal.Header closeButton>
                <Modal.Title>Create a New Saved Filter</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  {/* <Form.Label>Enter a name for your saved filter</Form.Label> */}
                  <Form.Control type="text" placeholder="Enter a name for your new filter" />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => setShowAddFilter(!showAddFilter)}>
                  Save
                </Button>
                <Button variant="light" onClick={() => setShowAddFilter(!showAddFilter)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffcanvasFilters;
