import React, { useState, useEffect } from 'react';
import cloneDeep from "lodash/cloneDeep";
import './Styles.css';

const nameList = "Ashley,Bryant,Bryon,Corey,Dywayne,Irina,Jason,Joel,Karl,Kia,Linda,Mark,Matt,Megan,Seth,Susan,Troy,Vasu";

function UpNext() {
    const [availableNames, setAvailableNames] = useState([]);
    const [selectedName, setSelectedName] = useState('');
    const [skippedNames, setSkippedNames] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isNewSelection, setIsNewSelection] = useState(false);
    const [containerSize, setContainerSize] = useState(0); // Responsive container size
    const [radius, setRadius] = useState(0); // Responsive outer circle radius
    const [innerCircleSize, setInnerCircleSize] = useState(0); // Inner circle size


    const delay = 50;

    useEffect(() => {
        // Set container size, inner circle size, and radius based on the screen size
        const updateSizes = () => {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            // Set container size to 80% of the smaller dimension (width or height)
            const size = Math.min(screenWidth, screenHeight) * 0.8;
            setContainerSize(size);

            // Default inner circle size and radius
            let newInnerCircleSize = size * 0.35;
            let adjustedRadius = (size / 2) - newInnerCircleSize * 0.5;

            // If the screen size is smaller than 576px, reduce the inner circle size and radius
            if (size < 576) {
                //newInnerCircleSize = size * 0.18; // Reduce inner circle size for smaller screens
                adjustedRadius = (size / 2) - newInnerCircleSize * 0.7; // Add more padding to prevent overlap
            }

            // Set a maximum radius for larger screen sizes to prevent overlap
            if (size >= 720) {
                adjustedRadius = Math.min(adjustedRadius, size / 3.5); // Cap radius to a maximum based on size
            }

            setRadius(adjustedRadius);
            setInnerCircleSize(newInnerCircleSize); // Dynamically set the inner circle size
        };

        updateSizes();
        window.addEventListener('resize', updateSizes);

        return () => window.removeEventListener('resize', updateSizes);
    }, []);
    
    
    
    

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const names = urlParams.get('names');
        const randomsort = urlParams.get('randomsort') || 'true';

        if (names) {
            const namesArray = names.split(',').map(name => name.trim()).sort(() => Math.random() - 0.5);
            let shuffledNamesList = [];

            if (!randomsort || randomsort === 'true') {
                shuffledNamesList = namesArray.sort(() => Math.random() - 0.5);
            } else {
                shuffledNamesList = namesArray.sort();
            }
            setAvailableNames(shuffledNamesList);
        } else {
            const shuffledNamesList = nameList.split(',').map(name => name.trim()).sort(() => Math.random() - 0.5);
            setAvailableNames(shuffledNamesList);
        }
    }, []);

    useEffect(() => {
        if (availableNames.length > 0) {
            spinWheel(true);
        }
    }, [availableNames]);

    useEffect(() => {
        if (selectedName) {
            setIsNewSelection(true);
            setTimeout(() => {
                setIsNewSelection(false);
            }, 300);
        }
    }, [selectedName]);

    const spinWheel = () => {
        if (availableNames.length === 0) return;

        if (availableNames.length === skippedNames.length) {
            onRestart();
            return;
        }

        setSpinning(true);
        const totalNames = availableNames.length;
        const fullRounds = totalNames;
        const extraSteps = Math.floor(Math.random() * totalNames);
        const totalSteps = fullRounds + extraSteps;

        let step = 0;
        let nextIndex = currentIndex;

        const interval = setInterval(() => {
            do {
                nextIndex = (nextIndex + 1) % totalNames;
            } while (skippedNames.includes(availableNames[nextIndex]));

            setCurrentIndex(nextIndex);
            step++;

            if (step === totalSteps) {
                clearInterval(interval);
                setSpinning(false);

                const selected = availableNames[nextIndex];
                setSelectedName(selected);
                setSkippedNames((prev) => [...prev, selected]);
            }
        }, delay);
    };

    const getCircleStyle = (name, index) => {
        const angle = (index / availableNames.length) * 2 * Math.PI;
        const x = containerSize / 2 + radius * Math.cos(angle) - (containerSize * 0.1) / 2;
        const y = containerSize / 2 + radius * Math.sin(angle) - (containerSize * 0.1) / 2;

        const backgroundColor = spinning && index === currentIndex ? '#4CBB17' : skippedNames.includes(name) ? '#fff' : '#004B87';
        const color = spinning && index === currentIndex ? '#fff' : skippedNames.includes(name) ? '#333' : '#fff';

        return {
            left: `${x}px`,
            top: `${y}px`,
            width: `${containerSize * 0.1}px`, // Dynamic outer circle size
            height: `${containerSize * 0.1}px`, // Dynamic outer circle size
            color,
            backgroundColor
        };
    };

    function getInnerCircleLabel() {
        if (selectedName === '' && skippedNames.length > 0) {
            return 'Resume';
        }
        if (selectedName === '' && availableNames.length === skippedNames.length) {
            return <>Start Over</>;
        }
        if (spinning) {
            return 'Searching...';
        }
        if (selectedName) {
            return (<>{selectedName}<div className="next-link">Click to Continue</div></>);
        }
        return 'Start';
    }

    function onRestart() {
        setCurrentIndex(0);
        setIsNewSelection(false);
        setSpinning(false);
        setSelectedName('');
        setSkippedNames([]);
    }

    function onSelectName(name, ndx) {

        const copyList = cloneDeep(skippedNames)

        const exists = copyList.find((s) => s === name);
        if (exists) {
            const filtered = copyList.filter(s => s !== name);
            setSkippedNames(filtered)
            setSelectedName('')
        }
        else {
            setSelectedName(name)
            setSkippedNames((prev) => [...prev, name]);
        }
    }

    return (
        <div className="container fluid min-vh-100">
            <div className="text-center mt-5">
                <h1>UpNext <span className="for-teams">for teams</span></h1>
                <h5>Random turns, organized updates.</h5>
                <p>With UpNext, teams can share updates at random or select members manually, making it easy to keep track of who has provided an update.</p>
                <div className="mt-5" style={{ position: 'relative', width: `${containerSize}px`, height: `${containerSize}px`, margin: '0 auto' }}>
                    {/* <div className={`d-flex flex-column inner-circle ${isNewSelection ? 'expand' : ''}`} onClick={spinWheel}>
                        {getInnerCircleLabel()}
                    </div> */}

<div
                        className={`d-flex flex-column inner-circle ${isNewSelection ? 'expand' : ''}`}
                        style={{ width: `${innerCircleSize}px`, height: `${innerCircleSize}px` }}
                        onClick={spinWheel}
                    >
                        {getInnerCircleLabel()}
                    </div>

                    {availableNames.map((name, index) => (
                        <div
                            key={name}
                            className={`outer-circle ${index === currentIndex ? 'active' : ''}`}
                            onClick={() => onSelectName(name, index)}
                            style={getCircleStyle(name, index)}
                        >
                            {name}
                        </div>
                    ))}
                </div>

                <div className="mt-5">
                    <a href="#" className="btn-link-off" onClick={() => onRestart()}>Reset</a>
                </div>
            </div>
        </div>
    );
}

export default UpNext;
