import React, { useState, useEffect, useRef, lazy, Suspense } from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    Offcanvas,
    Tooltip,
    OverlayTrigger,
    Popover,
    DropdownButton,
    Dropdown
} from "react-bootstrap";
import { PlusCircle, Info, Edit, XCircle, Filter, Settings, X, Maximize2, PieChart } from "react-feather";


import { dynamicSort } from '../../../Helpers/DynamicSort';

import cloneDeep from "lodash/cloneDeep";

import CustomBadge from "../../Badge/Badge";

import "./Dashboard.css";
import "../../../Styles/Widgets.css";

function Widgets({ host, widgetList, currentLayout = [4, 4, 4, 12, 3, 3, 3, 3, 3], chartColors, widgetHeight, onRemoveWidget, onRearrangeWidgets }) {

    const [displayList, setDisplayList] = useState([])
    const [itemBeingDragged, setItemBeingDragged] = useState(null)

    // const prevShow = usePrevious({ show });

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    useEffect(() => {

        let list = []
        widgetList.forEach(function (item, ndx) {
            const widgetItem = { widget_library_id: item.widgetId, title: item.title, description: item.description, type: item.type, api: item.api }
            list.push({
                // creating this as an "attributes" bundle to pass things more easily into the component without including the instance
                originalItem: item,
                item: widgetItem,
                colors: chartColors,
                grid_id: list.length + 1,
                grid_order: list.length + 1,
                //dataPromise: ServiceHelper.Request(component),
                instance: React.lazy(() => import("../../../Components/Dashboard/" + item.type))


            })
        })


        setDisplayList(list)


    }, [widgetList]);


    function onSelectChartType(c, type) {

        // let newCopy = JSON.parse(JSON.stringify(selectedDashboard))
        // let found = newCopy.components.find(e => e.component_id === c.item.component_id)
        // if (found) {
        //     found.component = type
        // }

        // setSelectedDashboard({ ...newCopy })
    }

    function onShowFullScreen(Component) {

        // setShowFullScreenModal(true)
        // setSelectedWidget(Component)

        // console.log("component", Component)
    }


    // By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element
    function onDragOver(e) {
        //event.stopPropagation();
        e.preventDefault();
        //console.log("onDragOver", e.target.id)
    }

    function onDragEnter(e, widget) {
        e.stopPropagation();
        e.preventDefault();
        console.log("onDragEnter", e.target.id)
        let container_ndx = e.target.dataset.container
        //console.log("onDrageEnter", "source", itemBeingDragged, "target", widget)
    }

    function onDragStart(e, widget) {
        //console.log("onDragStart", e.target.id, "widget", widget)
        e.dataTransfer.setData("text", e.target.id);

        setItemBeingDragged(widget)
    }

    function onDrop(e, widget) {

        console.log("onDrop", widget)
        console.log("itemBeingDragged", itemBeingDragged)
        e.preventDefault();
        e.stopPropagation();

        let sourceOrder = itemBeingDragged.grid_order;
        let targetOrder = widget.grid_order;

        let items = cloneDeep(displayList)


        items.forEach(function (item) {

            console.log("item", item.grid_id, item.grid_order)

            // this swaps places between the source and target
            if (item.grid_id === itemBeingDragged.grid_id) {
                console.log("changing dragged", targetOrder)
                item.grid_order = targetOrder
            }
            else if (item.grid_id === widget.grid_id) {
                console.log("changing target", sourceOrder)
                item.grid_order = sourceOrder
            }

        })

        console.log("items", items)

        items.sort(dynamicSort('grid_order', 1))

        // make sure and strip this back down to the original array of selected widgets for re-rendering
        const reducedArray = items.map(item => item.originalItem);
        onRearrangeWidgets(reducedArray)

    }



    return (

        <Row className="mt-3 justify-content-center">

            <Col xs={12} lg={8} className="dashboard-wrapper">

                <Row className="g-2 p-2 justify-content-center" style={{ backgroundColor: "#eef2f5" }}>

                    {displayList.map((Component, index) => {
                        return (
                            <div
                                key={index}
                                className={"widget-grid-wrapper col-12 col-sm-12 col-md-6 col-lg-" + currentLayout[index]}
                                onDrop={(e) => onDrop(e, Component)}
                                onDragOver={onDragOver}
                                onDragEnter={(e) => onDragEnter(e, Component)}
                            >

                                <div className="widget--menu">

                                    <DropdownButton title={<Settings style={{ height: 15, color: "gray" }} />} variant="none">
                                        <Dropdown.Item onClick={() => console.log("add filter")} disabled><Filter style={{ height: 15, color: "silver" }} /> Add Filter</Dropdown.Item>
                                        <Dropdown.Item onClick={() => onRemoveWidget(Component.originalItem, index)}><X style={{ height: 15, color: "silver" }} />Remove Widget</Dropdown.Item>
                                        <Dropdown.Item onClick={() => onShowFullScreen(Component)}><Maximize2 style={{ height: 15, color: "gray" }} />View Full Screen</Dropdown.Item>
                                        {Component.item.component !== "Table" &&
                                            <DropdownButton className="show-toggle dropend mx-1 p-0" title={<><PieChart style={{ height: 15, color: "gray" }} />Chart Type</>} variant="none">
                                                <Dropdown.Item onClick={() => onSelectChartType(Component, "BarChartVertical")}>Vertical Bar Chart</Dropdown.Item>
                                                <Dropdown.Item onClick={() => onSelectChartType(Component, "BarChartHorizontal")}>Horizontal Bar Chart</Dropdown.Item>
                                                <Dropdown.Item onClick={() => onSelectChartType(Component, "LineChart")}>Line Chart</Dropdown.Item>
                                                <Dropdown.Item onClick={() => onSelectChartType(Component, "PieChart")}>Pie Chart</Dropdown.Item>
                                                <Dropdown.Item onClick={() => onSelectChartType(Component, "DoughnutChart")}>Donut Chart</Dropdown.Item>
                                                <Dropdown.Item onClick={() => onSelectChartType(Component, "Table")} disabled>Table View</Dropdown.Item>
                                            </DropdownButton>
                                        }
                                    </DropdownButton>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#"
                                        // onClick={() => onRemoveWidget(Component.item)}
                                        >
                                            <XCircle size={20} color={'gray'} strokeWidth={1} />Remove
                                        </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                <Filter size={20} color={'gray'} strokeWidth={1} />Filter
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    className="widget-grid-item d-flex flex-column"
                                    style={{ height: widgetHeight + 60 }}
                                    draggable="true"
                                    onDragStart={(e) => onDragStart(e, Component)}
                                >
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Component.instance
                                            // dataPromise={Component.dataPromise}
                                            // compositeFilters={compositeFilters}
                                            host={host}
                                            item={Component.item}
                                            chartColors={Component.colors}
                                            userData={{ access_token: 'faked...' }}
                                            widgetHeight={widgetHeight}
                                        />
                                    </Suspense>
                                </div>
                            </div>

                        )
                    })}

                </Row>

            </Col>
        </Row>
    )
};

export default Widgets;