// src/SplitPane.js
import React, { useState, useEffect } from 'react';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Form,
    Modal,
    Table,
    Tabs,
    Tab,
    Dropdown,
    DropdownButton
} from "react-bootstrap";

import { Menu, ChevronLeft, ChevronRight } from "react-feather"; // Import Chevron icons

import './Styles.css';

const SplitPane = () => {
    const [isPaneVisible, setPaneVisible] = useState(true);
    const [paneWidth, setPaneWidth] = useState(250); // Initial width of the left pane
    const [href, setHref] = useState('#welcome')


    useEffect(() => {

        document.title = 'Rosnet UI Guidelines & Examples | PowerCenter';

        const links = document.querySelectorAll('.nav-link');
        links.forEach(link => {
            link.addEventListener('click', function (e) {
                e.preventDefault()

                const targetId = this.getAttribute('href').substring(1); // Get the href without the '#'
                const targetElement = document.getElementById(targetId);

                targetElement?.scrollIntoView({
                    behavior: 'smooth', // Smooth scrolling
                    block: 'start' // Scroll to the top of the target element
                });

                links.forEach(l => l.classList.remove('active')); // Remove active class from all links
                this.classList.add('active'); // Add active class to the clicked link
            });
        });

    }, []);




    const togglePaneVisibility = () => {
        setPaneVisible(!isPaneVisible);
    };

    return (
        <div className="ui-guide">
            <div className="split-pane">
                <div
                    className={`left-pane ${isPaneVisible ? 'visible' : 'hidden'}`}
                    style={{ width: isPaneVisible ? paneWidth : 0 }}
                >
                    <section>
                        <a href="/"><img src="./img/rosnet-logo-blue.png" alt="logo" style={{ maxWidth: 170 }} /></a>
                        {/* <h3 className="mt-3">Rosnet UI</h3> */}
                        <h4 className="mt-4 mb-3">UI Guidelines</h4>

                        <nav className="p-3 bg-light">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#welcome">Welcome</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#brand-guidelines">Brand Guidelines</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#design-system">Design System</a>

                                    <ul className="nav flex-column">
                                        <li className="nav-item nested-nav-link">
                                            <a className="nav-link" href="#ui-basics">Essentials</a>
                                        </li>

                                    </ul>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="#ux-ui">UX/UI</a>
                                </li> */}

                                <li className="nav-item">
                                    <a className="nav-link" href="#uikit">UI Kit</a>

                                    <ul className="nav flex-column">
                                        <li className="nav-item  nested-nav-link">
                                            <a className="nav-link" href="#templates">Templates</a>
                                        </li>
                                        <li className="nav-item  nested-nav-link">
                                            <a className="nav-link" href="#components">Components</a>
                                        </li>
                                    </ul>

                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" href="#templates">Conventions</a>
                                </li>
                            </ul>
                        </nav>

                        <a className="btn btn-block btn-outline-secondary w-100 mt-5" href="/">Home Page</a>
                    </section>
                </div>

                {/* Chevron Icon inside a circular div */}
                <div className="chevron-toggle" onClick={togglePaneVisibility}>
                    {isPaneVisible ? <ChevronLeft size={24} color={'white'} /> : <ChevronRight size={24} color={'white'} />}
                </div>

                <div className="right-pane">
                    <section id="welcome" className="mb-2">
                        <div className="">
                            <h3>Welcome</h3>
                            <p>Rosnet UI Guidelines is an evolving resource to help Rosnet developers create a more consistent look-and-feel across all projects that adhere to brand guidelines.
                                By providing documentation, sample templates, and reusable components, the hope is that developers will have a tangible resource that helps them understand how brand guidelines influence the style guide and ultimately the look-and-feel of our internal and customer-facing web apps.
                            </p>
                        </div>
                    </section>
                    <section id="brand-guidelines" className="mb-2">
                        <div className="">
                            <h3>Brand Guidelines</h3>
                            <p>Brand guidelines are the rules that define a brand’s identity, including elements like logo usage, color palette,
                                typography, and tone of voice, ensuring consistency across all communications. While brand guidelines focus on the overall brand identity,
                                a <strong>style guide</strong> is more specific to design or content implementation,
                                providing detailed instructions for styling and formatting.</p>
                            <p>For a Web developer, Rosnet's brand guidelines boil down to a handful of concerns that include the following.</p>
                            <ul>
                                <li>New logo with variations</li>
                                <li>New font selection</li>
                                <li>New colors</li>
                                <li>Tone of Voice (how we say stuff)</li>
                            </ul>
                            <p>For details on how these apply to our <strong>style guide</strong>, <a target="_blank" href="https://www.figma.com/design/4nMErdkid2sQgPCELwgDwg/PowerCenter-Style-Guide?node-id=11-32&t=GTBLEDoeM8blvTPH-1">click here</a>.</p>
                        </div>
                    </section>

                    <section id="design-system" className="mb-2">

                        <h3>Design System</h3>

                        <p>A design system is a comprehensive collection of reusable components, guidelines, and design principles that ensure consistency and efficiency
                            in the creation of user interfaces across products and platforms. It encompasses visual elements like colors, typography, and icons, as well
                            as interaction patterns and accessibility standards, serving as a single source of truth for designers and developers.
                            By providing a standardized framework, a design system streamlines collaboration, enhances usability, and promotes a cohesive brand identity.</p>

                        <p><a href="https://getbootstrap.com/docs/5.1/getting-started/introduction/" target="_blank">Bootstrap 5</a> is arguably not quite a a design system on its own,
                            but was initially implemented in the portal as a temporary UI framework while we awaited the finalization of the new brand guidelines.
                            The plan was to eventually adopt a more robust off-the-shelf design system and UI kit (<a href="https://designsystemsrepo.com/design-systems/" target="_blank">examples</a>), customizing them to align with the brand.
                            However, with the brand guidelines evolving in parallel to the development of the new portal/dashboard and its approaching deadline,
                            this prolonged uncertainty of the brand guidelines led to a significant reliance on Bootstrap.
                            As a result, the portal's design system strategy became a 'lightly styled' Bootstrap approach, intended as a stopgap but ultimately requiring a deeper commitment to Bootstrap than originally planned.</p>

                        <p>Being tethered to Bootstrap is not a bad thing. It is widely used and offers a solid framework that can be customized based on brand guidelines, making it a convenient way to develop a tailored design system.</p>

                        <p>On top of Bootstrap, <a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a> was later introduced to help make React interactions with Bootstrap components easier. You can still use native Bootstrap 5 classes, but we encourage the use of React Bootstrap's UI components - especially for interactions with Bootstrap components like Modal, Offcanvas, Accordion, and others.</p>

                        <p>As part of our design system, we've customized certain aspects of Bootstrap and have created a few custom styles for things like the following:</p>

                        <ul>
                            <li>Border radius on containers</li>
                            <li>Spacing between components (margin, padding, etc.)</li>
                            <li>Offcanvas (side panel) appearance and slidein animation speed</li>
                            <li>More to come...</li>
                        </ul>


                        <div id="ui-basics" className="mt-4">
                            <h4>Essentials</h4>
                            <p>To help demystify some of the basic things about our brand guidelines and how they apply to our style guide and design system, a few key examples have been provided below.</p>


                            <h5>CSS Overrides</h5>
                            <p>In React, we usually place this in the top of the root App.css file.</p>
                            <pre style={{ color: 'green' }}>
                                {
                                    `html,
body {
    font-family: "Open Sans", sans-serif !important; /* Google Open Sans font */
    font-size: 14px; /* Overall font size */
    color: #333f48 !important; /* override to Bootstap default font color */
    background-color: #eef2f5 !important; /* override to Bootstap default background color */
}`
                                }
                            </pre>




                            <h5>HTML Overrides</h5>
                            <p>In React, we can either place the Google Open Sans font resource in the head of the /public/index.html file or as an import at the top of our App.css file.</p>

                            <pre style={{ color: 'green' }}>
                                {
                                    `
// App.css
@import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&amp;display=swap');
`
                                }
                            </pre>

                            <pre style={{ color: 'green' }}>
                                {
                                    `
/public/index.html
<head>
<link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&amp;display=swap"
/>
</head>
`
                                }
                            </pre>


                        </div>

                    </section>


                    {/* <section id="ux-ui" className="mb-2">
            
                            <h3>UX/UI - What's the difference?</h3>
                            <p>What is UX/UI and how does UX differ from UI?</p>


                            <h5>1. <span className="highlight">UX (User Experience)</span></h5>
                            <p>UX is about the overall experience a user has when interacting with a product. It’s a broader, more holistic approach than UI, encompassing:</p>
                            <ul>
                                <li><strong>User flows and navigation</strong> (how easily users can accomplish tasks)</li>
                                <li><strong>Usability and accessibility</strong> (is it easy to use and accessible to all users?)</li>
                                <li><strong>Information architecture</strong> (how information is organized and presented)</li>
                                <li><strong>User satisfaction and emotional response</strong></li>
                            </ul>
                            <p><strong>Goal of UX:</strong> Ensure that the product is easy to use, engaging, and meets the needs of the user, leading to a positive overall experience.</p>

                            <h5>2. <span className="highlight">UI (User Interface)</span></h5>
                            <p>UI refers to the visual elements and interactive components of a product—the parts that users interact with. This includes:</p>
                            <ul>
                                <li><strong>Layouts</strong> (how things are arranged on the screen)</li>
                                <li><strong>Buttons, icons, and menus</strong></li>
                                <li><strong>Typography and colors</strong></li>
                                <li><strong>Animations and micro-interactions</strong></li>
                                <li><strong>Input forms</strong></li>
                            </ul>
                            <p><strong>Goal of UI:</strong> Make the product aesthetically pleasing, intuitive, and functional. It focuses on visual consistency and clarity to help users interact with the system.</p>

                            <h5>Key Differences:</h5>
                            <ul>
                                <li><strong>Scope:</strong> UX covers the entire experience with the product (e.g., ease of use, effectiveness), while UI focuses specifically on the look and feel of the product.</li>
                                <li><strong>Focus:</strong> UX deals with problem-solving and making the product efficient for the user. UI is more about the visual design and how interactive elements appear on screen.</li>
                                <li><strong>Process:</strong> UX often involves research, wireframing, prototyping, and testing. UI comes into play afterward, where the visuals and aesthetics are applied to the wireframes.</li>
                            </ul>
                            <p>In short, <span className="highlight">UI is the “look”</span> and <span className="highlight">UX is the “feel”</span>—UI deals with interfaces, and UX deals with user journeys and overall satisfaction.</p>

     
                    </section> */}



                    <section id="uikit" className="mb-2">
                        <div className="">
                            <h3>UI Kit</h3>
                            <p>
                                Our <strong>UI Kit</strong> (User Interface kit) will evolve as a collection of design elements and components
                                that helps streamline the process of building user interfaces for websites or applications.
                                Our UI Kit will continue to evolve and include the following:
                            </p>
                            <ul>
                                <li><strong>Pre-designed components</strong>: Buttons, forms, icons, modals, and navigation bars that are ready to use.</li>
                                <li><strong>Typography and color schemes</strong>: Predefined fonts, text sizes, and color palettes that maintain design consistency.</li>
                                <li><strong>Layouts and grids</strong>: Structures for organizing content on different screen sizes, helping to create responsive designs.</li>
                                <li><strong>Patterns and templates</strong>: Common interface patterns like login forms, product cards, and dashboards, which can be reused across different projects.</li>
                                <li><strong>Style guidelines</strong>: Documentation on how to use the components to ensure consistency throughout the app or website.</li>
                            </ul>
                            <p>
                                The goal of our UI Kit is to help developers and designers save time by providing reusable
                                elements and maintaining design consistency across internal and customer-facing solutions.
                            </p>

                        </div>

                        <section id="templates" className="gray mb-2">
                            <div className="">
                                <h3>Templates</h3>
                                <p>Here are some general layout examples.</p>
                                <p>(links coming soon)</p>
                                <ul>
                                    <li>Centered using sections</li>
                                    <li>Example 2</li>
                                    <li>Example 3</li>
                                </ul>
                            </div>
                        </section>
                        <section id="components" className="gray mb-2">
                            <div className="">
                                <h3>Components</h3>
                                <p>We rely heavily on Bootstrap 5 for pre-built components but have created a few of our own:</p>
                                <p>(links coming soon)</p>
                                <ul>
                                    <li>Side Nav</li>
                                    <li>Multi-select, searchable dropdown</li>
                                    <li>Data-driven pill buttons used for selection</li>
                                </ul>
                            </div>
                        </section>


                    </section>


                    <section id="conventions" className="mb-2">
                        <div className="">
                            <h3>Conventions</h3>
                            <p>Describe here...</p>
                            <p>(links coming soon)</p>
                            <ul>
                                <li>Use of offcanvas, modals, toasts, etc.</li>
                                <li>Minimal use of tabs, except for 2-3 tabs at most.</li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default SplitPane;
