import React, { useState, useEffect } from "react";
import {
    Table,
    Dropdown,
    Tab,
    Tabs,
    Accordion,
    DropdownButton,
    Button
} from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import TablePlaceholder from "./TablePlaceholder";

import "./Styles/Accordion.css";

import sampleData from "./SampleData/report.json";
import sampleSubReportData from "./SampleData/subreport.json";

const ReportBuilder = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [reportData, setReportData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);
    const [key, setKey] = useState('');

    useEffect(() => {
        getData();
    }, [props.forceRerender]);

    useEffect(() => {
        console.log("props.report", props.report, props.filters);
        if(props.report && props.filters) {
            getData();
        }
    }, [props.report, props.filters]);



    async function getData() {

        shareStatusChange({ loading: true });

        // faked names for now just to show different reports
        let name = "report";
        if (props?.report.index > 1) name = "subreport";

        let data = null;
        if (name === "report") {
            data = sampleData;
        }
        else if (name === "subreport") {
            data = sampleSubReportData
        }

        setTimeout(() => {
            setReportData(data);
            shareStatusChange({ loading: false });
            if (data?.tabs?.length) {
                setSelectedTab(data.tabs[0]);
                setKey(data.tabs[0].label);
            }
        }, 500);


        // let url = "http://localhost:3100/api/reports/" + name;
        // if (document.location.href.indexOf('localhost') === -1) {
        //     url = "/api/reports/" + name;
        // }
        // fetch(url)
        // .then(response => response.json())
        // .then(data => {
        //     setReportData(data);
        //     setIsLoading(false);
        //     if(data?.tabs?.length) {
        //         setSelectedTab(data.tabs[0]);
        //         setKey(data.tabs[0].label);
        //     }
        // }).catch(err => {
        //     // handle error
        // });



    }

    function shareStatusChange(status) {

        setIsLoading(status.loading);
        console.log("statusChange", status);
        props?.onStatusChange({ loading: false });

    }

    function onDrilldownRow(row) {
        const report = {
            id: 0, // TODO: we need to get a report id of some sort for the subreport drilldown
            title: row.cols[0].label,
            description: "This is where the subreport description goes",
        };

        props.onDrilldown(props?.report, report);
    }

    function onDrilldownValue(val) {
        const report = {
            id: 0, // TODO: we need to get a report id of some sort for the subreport drilldown
            title: val,
            description: "This is where the subreport description goes",
        };

        props.onDrilldown(props?.report, report);
    }

    function onSelectTab(tabKey) {
        console.log("selectedTab", tabKey);
        const found = reportData.tabs.find((s) => s.label === tabKey);
        if (found) {
            setSelectedTab(found);
            setKey(tabKey);
        }

        shareStatusChange({ loading: true });
        setTimeout(() => {
            shareStatusChange({ loading: false });
        }, 500);
    }

    function renderElement(elem, ndx) {

        if (elem.type === "table") {
            return (
                <>{renderTable(elem, ndx)}</>
            )
        }
        else if (elem.type === "text") {
            return (
                <div key={ndx} className="mt-3">
                    {/* {elem.title && <h6 className="table-title mt-3">{elem.title}</h6>} */}
                    <p className={elem.class}>{elem.label}</p>
                </div>
            )
        }
        else if (elem.type === "label") {

            return (
                <span className={elem.class}>{elem.label}</span>
            )
        }
        else if (elem.type === "drilldownList") {

            return (
                <>
                    {elem?.drilldownList?.map((d, ndx) => (
                        renderDrilldown(d, ndx)
                    ))}
                </>
            )
        }
        else if (elem.type === "drilldown") {

            return (
                <>
                    {renderDrilldown(elem.drilldown, ndx)}
                </>
            )
        }
        else return <></>


    }

    function renderCell(elem, ndx) {

        // if just a label-value column 
        if (!elem.elements && !elem.drilldown) {
            return elem.label;
        }

        if(elem.drilldown) {

            //console.log("elem.drilldown", elem.drilldown);
            
            return (
                <>{renderDrilldown(elem.drilldown, ndx)}</>
            )
        }

        // if the column should render a vertical stack of elements
        else if (elem.elements) {

            //console.log("rendering cell elements", elem);
            return (
                <div className="flex-elements">
                    {elem.elements.map((e, rndx) => (
                        renderElement(e)
                    ))}
                </div>
            )

        }

        else return <></>

    }

    function renderTable(elem, ndx) {

        // CSS headsup, React bootstrap injects its table class at the end
        return (

            <div key={ndx} className="mt-3">
                {/* <h5 className="table-title mt-3">{elem.title}</h5> */}
                <Table className={`${elem.class}`} size="sm" responsive>
                    <thead className="">
                        <tr>
                            {elem.headers.map((header, index) => (
                                <th key={index} className={header.class}>{header.label}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {elem.rows.map((row, rndx) => (
                            <tr
                                key={rndx}
                                className={`hoverShade ${row.class}`}
                                // TODO: this needs to be conditional based on the row having a "drilldown" element
                                // onClick={() => {
                                //     onDrilldownRow(row);
                                // }}
                            >
                                {row.cols.map((cell, cndx) => (
                                    <td key={cndx} className={cell.class}>
                                        {renderCell(cell, cndx)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

        )
    }

    function renderDrilldown(d, ndx) {

        //console.log("drilldown", d)

        if (d.component === "dropdown") {
            return (
                <>
                    <DropdownButton
                        key={ndx}
                        variant="outline-secondary"
                        size="sm"
                        title={d.label}
                    >
                        {d.values.map((val, ndx) => (
                            <Dropdown.Item key={ndx} href="#" onClick={() => { onDrilldownValue(val.label) }}>{val.label}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </>
            )
        }
        else if (d.component === "button") {

            // if just a single drilldown item...
            if (!d.values) {
                return (
                    <Button key={ndx} variant="outline-secondary" size="sm" onClick={() => { onDrilldownValue(d.label) }}>{d.label}</Button>
                )
            }
            else {
                return (
                    <div key={ndx} style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                        {d.values.map((val, ndx) => (
                            <Button key={ndx} variant="outline-secondary" size="sm" onClick={() => { onDrilldownValue(val.label) }}>{val.label}</Button>
                        ))}
                    </div>
                )
            }
        }
        else if (d.component === "link") {

            //console.error("link", d);

            // if just a single drilldown item...
            if (!d.values) {
                return (
                    <>
                        <button key={ndx} type="button" className="btn link-style" onClick={() => { onDrilldownValue(d.label) }}>{d.label}</button>
                    </>
                )
            }
            else {
                return (
                    <>
                        {d.values.map((val, ndx) => (
                            <Button key={ndx} variant="outline-secondary" size="sm" onClick={() => { onDrilldownValue(val.label) }}>{val.label}</Button>
                        ))}
                    </>
                )
            }
        }
    }


    return (
        <div className="oc-report-content-wrapper">

            {isLoading && (
                <div className="mt-2">
                    <TablePlaceholder cols={8} rows={20} />
                    <div className="centered-loader">
                        <PuffLoader color={"#004b87"} />
                    </div>
                </div>
            )}

            {!isLoading && reportData && (
                <>

                    {/* Render tabs */}
                    {reportData?.tabs?.length > 1 &&
                        <Tabs defaultActiveKey="report-1" activeKey={key} className="mt-3" onSelect={(k) => onSelectTab(k)}>
                            {reportData.tabs.map((tab, ndx) => (
                                <Tab key={ndx} eventKey={tab.label} title={tab.label}></Tab>
                            ))}
                        </Tabs>
                    }

                    {/* Render accordion with title and parameters */}
                    {selectedTab?.subtitles && props?.report.index <= 1 &&
                        <Accordion className="mt-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{selectedTab.title}</Accordion.Header>
                                <Accordion.Body style={{ fontSize: 13 }}>
                                    <p><strong>Report Parameters</strong></p>
                                    {selectedTab.subtitles.map((s, ndx) => (
                                        <p key={ndx}><strong>{s.label}: </strong>{s.value}</p>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }


                    {/* Render drilldownList here... */}
                    {selectedTab?.drilldownList &&
                        <div className="mt-3" style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                            {selectedTab?.drilldownList?.map((d, ndx) => (
                                renderDrilldown(d, ndx)
                            ))}
                        </div>
                    }


                    {/* Render elements here... */}
                    {selectedTab?.elements?.map((elem, ndx) => (
                        renderElement(elem, ndx)
                    ))}




                    <Accordion className="mt-5">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>View Generic JSON Data Used to Produce this Report</Accordion.Header>
                            <Accordion.Body style={{ fontSize: 13, maxHeight: 300, overflowY: 'scroll' }}>
                                <pre>{JSON.stringify(reportData, null, 2)}</pre>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>


                    <Accordion className="mt-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Stylesheet Class Names Availble to Developers</Accordion.Header>
                            <Accordion.Body style={{ fontSize: 13, maxHeight: 300, overflowY: 'scroll' }}>
                                <p>A list of granular CSS classes that can be applied to tables, rows, and columns. These are stored in /src/Components/Report/Styles/Developer.css.</p>
                                <ul>
                                    <li>table-width-auto (compact view for table)</li>
                                    <li>table-width-100 (table width 100% of container)</li>
                                    <li>subtotal-row (applies light shading)</li>
                                    <li>total-row (applies darker shading)</li>
                                    <li>align-right (can be used for numeric values, the heading style should match)</li>
                                    <li>align-center</li>
                                    <li>font-size-sm (small font, 11px)</li>
                                    <li>font-weight-bold (applies font-weight: 600;)</li>
                                    <li>font-color-red (applies color:red;)</li>
                                    <li>More to come...</li>

                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </>

            )}

        </div>

    )
};

export default ReportBuilder;
