import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Tab,
    Tabs,
    Table,
    Badge,
    Card,
    Offcanvas
} from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import faker from "faker";

import "./OffcanvasDataTransfer.css";

const colors = {
    success: "#67A617", // green
    warning: "#F5AA04", // orange
    critical: "#E03C31" // red
};


// prev colors ["#FFB81C", "#78BE20"]

const countLocations = 32;
const countUnsuccessful = 4 //faker.datatype.number({ min: 14, max: 72 });
const countSuccessful = countLocations - countUnsuccessful;

const labels = ["Unsuccessful", "Successful"];
const data = {
    labels: labels,
    datasets: [
        {
            data: [countUnsuccessful, countSuccessful],
            backgroundColor: [colors.critical, colors.success]
        }
    ]
};
const dataAllSuccessful = {
    labels: ["Successful"],
    datasets: [
        {
            data: [countSuccessful],
            backgroundColor: [colors.success]
        }
    ]
};

const DoughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
        legend: {
            position: "bottom",
            display: false
        },
        tooltip: {
            enabled: true
        },
        datalabels: {
            formatter: (value, ctx) => {
                return value;
            },
            anchor: "center",
            color: "#ffffff"
        }
    }
};

function OffcanvasDataTransfer(props) {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500)
    }, []);

    return (
        <Offcanvas
            show={props?.show}
            onClose={props?.onClose}
            onHide={props?.onClose}
            placement="end"
            className="oc-custom"
        >
            <Offcanvas.Header>
                <Offcanvas.Title>
                    Transmission Status
                    <div className="subtitle-short">Transmission statuses for your locations.</div>
                </Offcanvas.Title>
                <button
                    type="button"
                    className="btn-close btn-close-white"
                    aria-label="Close"
                    onClick={props.onClose}
                ></button>
            </Offcanvas.Header>
            <Offcanvas.Body>

                <div className="dt">

                    <Row className="mb-5 pt-3">

                        <Col>

                            {isLoading &&
                                <div className="centered">
                                    <BeatLoader color={"#337ab7"} />
                                </div>
                            }

                            {!isLoading &&
                                <Tabs defaultActiveKey="today" className="mx-3">
                                    <Tab
                                        eventKey="today"
                                        title={
                                            <React.Fragment>
                                                Today
                                                <Badge pill variant="critical">
                                                    {countUnsuccessful}
                                                </Badge>
                                            </React.Fragment>
                                        }
                                    >
                                        <Row>
                                            <Col>
                                                <h6 className="text-center">{countUnsuccessful} unsuccesful data transmissions</h6>
                                                <div className="chart-container">
                                                    <Doughnut
                                                        data={data}
                                                        options={DoughnutChartOptions}
                                                        plugins={[ChartDataLabels]}
                                                    />
                                                </div>
                                                <div className="cta">
                                                    <Button variant="primary" size="lg">
                                                        Transmit Data to Rosnet
                                                    </Button>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="table-container no-scroll">
                                                    <h6>The following locations were not successful</h6>
                                                    <section className="mb-2 h-50">123 - ABC Location</section>
                                                    <section className="mb-2 h-50">234 - BCD Location</section>
                                                    <section className="mb-2 h-50">345 - CDE Location</section>
                                                    <section className="mb-2 h-50">456 - DEF Location</section>
                                                    <section className="mb-2 h-50">123 - ABC Location</section>
                                                    <section className="mb-2 h-50">234 - BCD Location</section>
                                                    <section className="mb-2 h-50">345 - CDE Location</section>
                                                    <section className="mb-2 h-50">456 - DEF Location</section>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab
                                        eventKey="yesterday"
                                        title={
                                            <React.Fragment>
                                                Yesterday
                                            </React.Fragment>
                                        }
                                    >
                                        <Row>
                                            <Col>
                                                <h6 className="text-center">
                                                    All data transmissions were successful, but you can transmit data any
                                                    time you have changes.
                                                </h6>
                                                <div className="chart-container">
                                                    <Doughnut
                                                        data={dataAllSuccessful}
                                                        options={DoughnutChartOptions}
                                                        plugins={[ChartDataLabels]}
                                                    />
                                                </div>
                                                <div className="cta">
                                                    <Button variant="primary" size="lg">
                                                        Transmit Data to Rosnet
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab
                                        eventKey="week"
                                        title={
                                            <React.Fragment>
                                                Past 7 Days
                                            </React.Fragment>
                                        }
                                    >
                                       <Row>
                                            <Col>
                                                <h6 className="text-center">
                                                    All data transmissions were successful, but you can transmit data any
                                                    time you have changes.
                                                </h6>
                                                <div className="chart-container">
                                                    <Doughnut
                                                        data={dataAllSuccessful}
                                                        options={DoughnutChartOptions}
                                                        plugins={[ChartDataLabels]}
                                                    />
                                                </div>
                                                <div className="cta">
                                                    <Button variant="primary" size="lg">
                                                        Transmit Data to Rosnet
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>

                                </Tabs>
                            }
                        </Col>
                    </Row>

                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default OffcanvasDataTransfer;
