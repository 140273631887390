import React, { useState, useEffect } from "react";
import { ArrowUpCircle, ArrowDownCircle } from "react-feather";
import { BeatLoader } from "react-spinners";

import "./LandingKPI.css";

const loadingStateData = {
  trend: "",
  trendColor: "",
  currentValue: "$0",
  comparisonLabel: "Comparison",
  comparisonValue: "$0",
  varianceLabel: "Variance",
  variancePerc: "0%",
  varianceAmount: "$0"
};

export default function LandingKPI(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(loadingStateData);

  // just used to demo the UI when waiting for data
  useEffect(() => {
    setTimeout(() => {
      setData(props.data);
      setIsLoading(false);
    }, 500);
  }, []);

  // just used to demo the UI when waiting for data
  useEffect(() => {
    setIsLoading(true);
    setData(loadingStateData);
    setTimeout(() => {
      setData(props.data);
      setIsLoading(false);
    }, 500);
  }, [props.demoLoadingState, props.data]);

  return (
    <div className={`${props.className}`}>
      <div className={`top ${isLoading ? "loading" : data.trendColor}`}>
        <div className="label">
          {props.data.title}
          {isLoading && (
            <span style={{ marginLeft: 15 }}>
              <BeatLoader color={"#337ab7"} size={12} />
            </span>
          )}
        </div>

        <div className={`perc ${data.trendColor}`}>{data.variancePerc}</div>

        <div className={`arrow ${data.trendColor}`}>
          {data.trend === "up" && <ArrowUpCircle />}
          {data.trend === "down" && <ArrowDownCircle />}
        </div>
      </div>
      <div className="middle">
        <div className="label">{data.currentValue}</div>
      </div>

      <div className="bottom">
        <div style={{ flexDirection: "column", textAlign: "left" }}>
          <div className="label">{data.comparisonLabel}</div>
          <div className="value">{data.comparisonValue}</div>
        </div>
        <div style={{ flexDirection: "column", textAlign: "right" }}>
          <div className="label">{data.varianceLabel}</div>
          <div className="value">{data.varianceAmount}</div>
        </div>
      </div>

      {props.data.graph && isLoading && (
        <div className="graph">
          <h6>{props.data.graph.label}</h6>
          <img
            src={"/img/placeholders/Placeholder-LineChart.png"}
            alt="Loading data ..."
            style={{ opacity: 1, maxHeight: 210, width: "100%" }}
          />
        </div>
      )}
      {props.data.graph && !isLoading && (
        <div className="graph">
          <h6>{props.data.graph.label}</h6>
          {props.data.graph.chart}
        </div>
      )}
    </div>
  );
}
