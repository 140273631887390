import React from 'react';

export var Render = {

    ProcessSubQuestions: function (column) {


        return column.map((item, index) => (

            <div key={'sub_' + index} className="col">{Render.Question(item)}</div>

        ))

    },

    Question: function (question, onAnswerChanged) {


        //console.log("Render.Question", question)

        if (question.type === 'text') {

            return (
                <div className="mt-3">
                    <label htmlFor={question.ref_id}>{question.label}</label>
                    <div className="form-group">
                        <input type="text" id={question.ref_id}
                            value={question.answer}
                            onChange={onAnswerChanged}
                            className="form-control" placeholder={question.placeholder}
                            disabled={(question.active) ? "" : "disabled"}
                        />
                    </div>
                </div>
            )
        }
        else if (question.type === 'number') {

            return (
                <div className="mt-3">
                    <label htmlFor={question.ref_id}>{question.label}</label>
                    <div className="form-group">
                        <input
                            data-input-mask="number" // number masking with commas
                            type="text"  // use text to allow commas and decimals to appear
                            id={question.ref_id}
                            value={question.answer}
                            onChange={onAnswerChanged}
                            className="form-control" placeholder={question.placeholder} />
                    </div>
                </div>
            )
        }

        else if (question.type === 'currency') {

            // IMPORANT: If the field type is number, any commas entered in Firefox will cause the 
            // value to not be saved

            return (
                <div className="mt-3">
                    <label htmlFor={question.ref_id}>{question.label}</label>

                    <div className="input-group mb-3 width-250">
                        <span className="input-group-text">$</span>
                        <input
                            data-input-mask="currency" // currency masking with commas and 2 decimal places
                            type="text" // use text to allow commas and decimals to appear
                            id={question.ref_id}
                            value={question.answer}
                            onChange={onAnswerChanged}
                            className="form-control" />
                    </div>

                    <br />
                </div>
            )
        }

        else if (question.type === 'phone') {

            return (
                <div className="mt-3">
                    <label htmlFor={question.ref_id}>{question.label}</label>
                    <div className="form-group">
                        <input
                            data-input-mask="phone" // masking with hyphens
                            type="text"  // use text to allow hyphens
                            id={question.ref_id}
                            value={question.answer}
                            onChange={onAnswerChanged}
                            className="form-control" placeholder={question.placeholder} />
                    </div>
                </div>
            )
        }

        else if (question.type === 'percent') {

            // IMPORANT: If the field type is number, any commas entered in Firefox will cause the 
            // value to not be saved
            return (
                <div className="mt-3">
                    <label htmlFor={question.ref_id}>{question.label}</label>
                    <div className="input-group width-250">
                        <input type="text" id={question.ref_id}
                            value={question.answer}
                            onChange={onAnswerChanged}
                            className="form-control" />
                        <span className="input-group-text">%</span>
                    </div>
                    <br />
                </div>
            )
        }

        else if (question.type === 'email') {

            return (
                <div className="mt-3">
                    <label htmlFor={question.ref_id}>{question.label}</label>

                    <div className="input-group">
                        <span className="input-group-text" id={`email_${question.ref_id}`}>@</span>
                        <input type="text" id={question.ref_id}
                            value={question.answer}
                            onChange={onAnswerChanged}
                            className="form-control" placeholder={question.placeholder} aria-label={question.placeholder} area-describedby={`email_${question.ref_id}`} />
                    </div>


                    <br />

                </div>
            )
        }
        else if (question.type === 'tel') {

            return (
                <div className="mt-3">
                    <label htmlFor={question.ref_id}>{question.label}</label>
                    <div className="form-group">
                        <input type="tel" id={question.ref_id}
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            value={question.answer}
                            onChange={onAnswerChanged}
                            className="form-control" placeholder={question.placeholder} />
                    </div>
                </div>
            )
        }
        else if (question.type === 'textarea') {

            return (
                <div className="mt-3">
                    <label htmlFor={question.ref_id}>{question.label}</label>
                    <div className="form-group">
                        <textarea id={question.ref_id}
                            value={question.answer}
                            onChange={onAnswerChanged}
                            rows={question.rows || 3}
                            className="form-control" placeholder={question.placeholder} />
                    </div>
                </div>
            )
        }

        else if (question.type === 'date') {

            return (
                <div className="mt-3">
                    <label htmlFor={question.ref_id}>{question.label}</label>
                    <div className="form-group width-250">
                        <input type="date" id={question.ref_id}
                            value={question.answer}
                            onChange={onAnswerChanged}
                            className="form-control" />
                    </div>
                </div>
            )
        }

        else if (question.type === 'dropdown') {

            return (
                <div className="form-group">
                    <label className="col-md-12 control-label no-padding" htmlFor={question.ref_id}>{question.label}</label>

                    <select id={'dropdown_' + question.ref_id} name={'dropdown_' + question.ref_id} className="form-control"
                        onChange={onAnswerChanged}
                        value={question.answer}>
                        <option value="">-- Select --</option>
                        {question.choices.map((choice, index) => (
                            <option key={'option_' + choice.ref_id} value={choice.value}>{choice.label}</option>
                        ))}
                    </select>

                </div>
            )
        }

        else if (question.type === 'checkboxes-inline' || question.type === 'checkboxes') {

            return (
                <div className="form-group">
                    <label className="col-md-12 control-label no-padding" htmlFor={question.ref_id} >{question.label}</label>

                    <br />
                    {question.choices.map((choice, index) => (

                        <div className="checkbox form-check-inline" key={'checkbox_' + index}>
                            <label>
                                {/* the choice.value is ONE of the entries in the list of answers */}
                                <input checked={question?.answer?.indexOf(choice?.value) !== -1} className="form-check-input" type="checkbox" value={choice?.value} id={'checkbox_' + choice.ref_id} name={'checkbox_' + question.ref_id} onChange={onAnswerChanged} />{choice.label}
                            </label>
                        </div>

                    ))}

                </div>
            )
        }

        else if (question.type === 'radio-buttons') {

            return (
                <div className="mt-3 mb-4">
                    <div className="form-group">
                        <label className="col-md-12 control-label no-padding" htmlFor={question.ref_id} >{question.label}</label>

                        <br />
                        {question.choices.map((choice, index) => (

                            <div className="radio form-check-inline" key={'radio_' + index}>
                                <label>
                                    <input checked={question.answer === choice.value} type="radio" value={choice.value} id={'radio_' + choice.ref_id} name={'radio_' + question.ref_id} onChange={onAnswerChanged} />{choice.label}
                                </label>
                            </div>


                        ))}


                    </div>
                </div>
            )
        }

        else if (question.type === 'sub-questions') {

            console.log("sub-questions", question.sub_questions)

            return (
                <div className="mt-3">
                    <p>{question.label}</p>
                    {question.sub_questions.map((item, index) => (
                        <div className="row" key={'question_' + index}>{Render.ProcessSubQuestions(item)}</div>
                    ))}
                </div>
            )


        }



    }





}


