import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Dropdown,
    DropdownButton,
    Accordion
} from "react-bootstrap";

import { Search, PlusCircle, List, Grid, Trash, MoreHorizontal, Edit, FileText, Send } from "react-feather";


import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
import { Render } from '../../Helpers/Render'
import { InputMasks } from '../../Helpers/InputMasks'

import sampleData from "../../Components/Survey/sample-survey.json";
import SideMenu from "../../Components/SideMenu/SideMenu";
import { SyncLoader } from 'react-spinners';

import "./Styles.css";



function Survey() {

    const [sending, setSending] = useState(false);
    const [surveySections, setSurveySections] = useState(sampleData);
    const [receiving, setReceiving] = useState(false);
    const [currentSection, setCurrentSection] = useState(sampleData[0]);
    const [currentIndex, setCurrentIndex] = useState(0);

    let categoryItems = []
    sampleData.forEach(function (item, ndx) {
        categoryItems.push({ id: item.ref_id, label: item.label })
    })
    const [sidemenuItems] = useState(categoryItems)


    function onChange(e) {

        console.log("onChange", e.target.id, "=", e.target.value)
        console.log("onChange name", e.target.name)


        // THIS DID NOT WORK IN FIREFOX!!!! - It kept the comma
        // let _VALUE = e.target.value
        // if(e.target.type === 'number' && e.target.value.indexOf(',') !== -1) {
        //     _VALUE = Utils.ReplaceAll(_VALUE, ',', '')

        // }

        let target_ref_id = e.target.id
        let target_input_mask = ""
        if (e.target.attributes['data-input-mask']) {
            target_input_mask = e.target.attributes['data-input-mask'].value
        }
        //console.log("target_input_mask", target_input_mask)


        let type = ''

        // for checkbox choice, attach the anwer to the question not the choice
        if (target_ref_id.indexOf('checkbox') !== -1) {
            type = 'checkbox'
            target_ref_id = e.target.name.replace('checkbox_', '')
        }
        // for radiobuttons, attach the anwer to the question not the choice
        else if (target_ref_id.indexOf('radio') !== -1) {
            type = 'radio'
            target_ref_id = e.target.name.replace('radio_', '')
        }
        // for dropdown choice, attach the anwer to the question not the choice
        else if (target_ref_id.indexOf('dropdown') !== -1) {
            type = 'dropdown'
            target_ref_id = e.target.name.replace('dropdown_', '')
        }


        //console.log("target_ref_id", target_ref_id)

        // this.setState({ [e.target.id]: e.target.value })
        let sectionCopy = JSON.parse(JSON.stringify(currentSection))
        let found = sectionCopy.questions.find(function (question) {
            return question.ref_id === target_ref_id
        })
        if (found) {

            if (!found.answer) {
                found.answer = ""
            }
            //console.log("found.answer", found.answer)

            // checkbox allows multiple selections, which are stored as comma-separated values
            if (type === 'checkbox') {

                //console.log("e.target.checked", e.target.checked)
                //console.log("e.target.value='" + e.target.value + "'")

                // for some reason, spliting an empty string results in a blank entry in the array
                let arry = found.answer.split(", ")
                let items = arry.filter(word => word !== '')

                //console.log("items", JSON.stringify(items, null, 2))

                // if checked, then add
                if (e.target.checked) {
                    items.push(e.target.value)
                }
                // removing
                else {

                    let filtered = items.filter(word => word !== e.target.value && word !== '')

                    //console.log("filtered out '" + e.target.value + "'", filtered)

                    items = filtered

                }

                found.answer = items.join(", ")

                //console.log("found.answer", found.answer)


            }
            else {
                if (target_input_mask === "number") {
                    found.answer = InputMasks.Numeric(e.target.value)
                }
                else if (target_input_mask === "currency") {
                    found.answer = InputMasks.CurrencyWithoutDecimalPlaces(e.target.value)
                }
                else if (target_input_mask === "phone") {
                    found.answer = InputMasks.Phone(e.target.value)
                }
                else {
                    found.answer = e.target.value
                }
            }
        }


        setCurrentSection(sectionCopy)





    }

    function onCategorySelection(category) {
        //console.log("category", category);
        let found = surveySections.find(s => s.ref_id === category.id);
        if (found) {
            setCurrentSection(found)
            resetCurrentIndex(found)
        }
        window.scrollTo(0, 0)

    }

    function onChangeSection(section) {

        //$("#custom-scroll-anchor").animate({ scrollTop: 0 }, "slow");
        window.scrollTo(0, 0)

        // this.setState({
        //     currentSection: section
        // }, () =>

        //     // Do this AFTER state updates - this shares the persisted userData to the App-Rosnet.js wrapper
        //     this.resetCurrentIndex(section)
        // )

        setCurrentSection(section)
        resetCurrentIndex(section)

        //console.log("ths.state.currentSection", section)
    }

    function onPreviousSection() {

    }
    function onFinishSection() {

        // make a local copy for offline backup
        //localStorage.setItem(this.state.response_id, JSON.stringify(this.state.surveySections))

        //$("#custom-scroll-anchor").animate({ scrollTop: 0 }, "slow");
        // $(window).scrollTop(0);


        // dont exceed the array - just reset to zero
        let ndx = currentIndex + 1
        if (ndx >= surveySections.length) {
            setCurrentIndex(0)
            setCurrentSection(surveySections[0])
        }
        else {
            setCurrentSection(surveySections[ndx])
        }

        resetCurrentIndex(currentSection)

    }


    function resetCurrentIndex(section) {

        if (section === null) {

            setCurrentSection(section)
            setCurrentIndex(0)

        }
        else {
            let ndx = 0
            let newNdx = 0
            surveySections.forEach(function (item) {
                if (item.ref_id === section.ref_id) {
                    newNdx = ndx
                }
                ndx++
            })

            setCurrentSection(section)
            setCurrentIndex(newNdx)
        }

    }



    return (
        <Container fluid className="ssr">

            <TopNav dataTransferSuccess={true} />


            <Row className="justify-content-md-center mb-5 pt-3">

                <Col xs={12} sm={12} md={12} lg={4} xl={2}>

                    <SideMenu
                        // the category to select by default (0 based index)
                        defaultCategoryIndex={0}
                        // array of items in the format that the component expects
                        items={sidemenuItems}
                        // when a user selects a category
                        onCategorySelection={(cat) => onCategorySelection(cat)}
                        // default the selected subcategory item
                        // IMPORTANT: make sure and set defaultCategoryIndex to match the index of the parent
                        // defaultSub={{ id: 1 }}
                        // when a user selects a subcategory
                        // onSubSelection={(sub) => onSubSelection(sub)}
                        // heading content can be customized
                        headingContent={
                            <div className="dashboard-heading">
                                <h4>Survey Sections</h4>
                                <Button
                                    variant="link"
                                    type="button"
                                    style={{ margin: 0, padding: 0 }}
                                    onClick={() => console.log("something")}
                                >
                                    <PlusCircle size={24} color={"white"} />
                                </Button>
                            </div>
                        }
                    />
                </Col>

                <Col xs={12} sm={12} md={12} lg={4} xl={6}>

                    <div id="custom-scroll-anchor" className="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main custom-margin-top-60" >

                        <section>
                            <h3>Data Collection Survey</h3>
                            <p>A prototype of a survey app that can dynamically build the UI based on a JSON configuration of questions.</p>
                        </section>

                        <div className="row">
                            <div className="col-lg-12">
                                
                                <div className="panel panel-default custom-panel-min-height">

                                    {receiving &&
                                        <div className="text-center">
                                            <br />
                                            <br />
                                            <SyncLoader
                                                color={'#8ad919'}
                                                loading={true}
                                            />
                                        </div>
                                    }


                                    {currentSection &&
                                        <section>
                                            <h4>
                                                {currentSection.label}
                                            </h4>

                                            <div className="panel-body">
                                                <div className="col-md-10">
                                                    <form role="form">

                                                        <p>{currentSection.desc}</p>

                                                        {currentSection.questions.map((item, index) => (
                                                            <span key={'question_' + index}>{Render.Question(item, onChange)}</span>
                                                        ))}

                                                        {/* only show next if more sections to go... */}
                                                        {currentIndex > 0 &&
                                                            <span>
                                                                <button className="btn btn-md btn-primary"
                                                                    type="button"
                                                                    onClick={() => onPreviousSection()}><em className="fa fa-angle-left"></em> Previous Section </button>
                                                                &nbsp;
                                                            </span>
                                                        }

                                                        {/* only show next if more sections to go... */}
                                                        {currentIndex < (surveySections.length - 1) &&
                                                            <button className="btn btn-md btn-primary"
                                                                type="button"
                                                                onClick={() => onFinishSection()}>Next Section <em className="fa fa-angle-right"></em></button>
                                                        }

                                                    </form>
                                                </div>
                                            </div>

                                                    <Accordion className="mt-5">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>Generic JSON Data Used for this Survey Step</Accordion.Header>
                                                            <Accordion.Body style={{ fontSize: 13, maxHeight: 300, overflowY: 'scroll' }}>
                                                                <pre>{JSON.stringify(currentSection, null, 2)}</pre>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                             

                                        </section>
                                    }
                                </div> {/* end panel */}

                            </div> {/* end col-12 */}


                        </div> {/* end row */}


                    </div>

                </Col>
            </Row>





        </Container>
    );
}

export default Survey;