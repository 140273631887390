import { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom";
import { Navbar, NavDropdown, Nav, Dropdown, Form, InputGroup, Button } from "react-bootstrap";

import { User, Menu, Layers, Search, ArrowRight, CloudLightning, Zap, ZapOff, X } from "react-feather";

import dayjs from "dayjs";

import OffcanvasMenu from "../OffcanvasMenu/OffcanvasMenuNew";
import OffcanvasDataTransfer from "../OffcanvasDataTransfer/OffcanvasDataTransfer";
import OffcanvasHelp from "../OffcanvasHelp/OffcanvasHelp";

import languages from "../../Fixtures/languages.json";

import { dynamicSort } from "../../Helpers/DynamicSort";

import "./TopNavMegaMenu.css";

import searchItems from "./SampleData/raw-search-items.json";
searchItems.forEach(function (item) {
  item.icon = `/img/search-icons/blue/${item.parentMenuName}.svg`;
});


function TopNavMegaMenu(props) {

  const searchInputRef = useRef();

  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [showDataTransfer, setShowDataTransfer] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [items] = useState(searchItems);
  const [filteredItems, setFilteredItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [maxCount, setMaxCount] = useState(18);
  // const [abOption, setAbOption] = useState(1);
  const [showHelp, setShowHelp] = useState(false);
  const [forceRerender, setForceRerender] = useState(new Date());

  useEffect(() => {

    // const searchParams = new URLSearchParams(window.location.search);
    // const ab = searchParams.get('ab') || 1;
    // setAbOption(parseInt(ab));


    // adpative based on user's screen resolution
    if (window.innerWidth <= 1500) {
      setMaxCount(12);
    }
    // console.log("window.location", window.location.pathname)
  }, []);

  useEffect(() => {
    // if we're hiding the results to open the menu, clear the search term and collapse the results
    if (showMenu) {
      // console.log("hide results");
      onClearSearch();
    }
  }, [showMenu]);


  useEffect(() => {
    // console.log("props.action", props.action);
    // if any page like home.js want's to tell the top nav something to do
    if (props.action === "show-data-transfer") {
      setShowDataTransfer(true);
      props.onActionPerformed();
    }
  }, [props?.action]);


  function onSelectLanguage(item) {
    setSelectedLanguage(item)
  }

  function onChangeSearchTerm(query) {
    setSearchTerm(query);
    if (query.length > 0) {
      setShowResults(true);
      let items = findItemsWithSearchTerm(query)
      setTotalCount(items.length);
      // setFilteredCount(items.length);
      const sliced = items.slice(0, maxCount); // only display top maxCount
      sliced.sort(dynamicSort('activityCount', -1)); // sort high to low
      setFilteredItems(sliced);
    }
    else {
      setShowResults(false);
    }
  }

  const onKeyDown = (event) => {
    console.log("event key", event.key);
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };


  function onClearSearch() {
    setSearchTerm('');
    setShowResults(false);
    searchInputRef.current.focus();
  }

  function findItemsWithSearchTerm(searchTerm) {
    // incase the user is using comma-delimited instead of space-delimited search terms (e.g. "sales, reports")
    const nocommas = searchTerm.replaceAll(',', ' ');
    // Split the search term into individual words
    const words = nocommas.toLowerCase().split(' ');

    // Filter the items array to find matches
    return items.filter(item => {
      // Combine all text elements search
      const combinedText = `${item.menuFunctionName.toLowerCase()} ${item.tipText.toLowerCase()} ${item.parentMenuName.toLowerCase()} ${item.menuName.toLowerCase()}`;

      // Check if all words in the search term exist in the combined text
      return words.every(word => combinedText.includes(word));
    });
  }

  function renderSearchBox(fixed) {

    return (
      // <Form className='d-flex mx-md-2 d-none d-sm-block'>
      //   <InputGroup className={ fixed ? 'flex-nowrap input-fixed' : `flex-nowrap input-resize`}>
      //     <div className="bg-white rounded-start d-flex align-items-center p-2">
      //       <Search strokeWidth={2} size={20} />
      //     </div>
      //     <Form.Control
      //       type="search"
      //       placeholder="Search"
      //       className={'border-0 shadow-none'}
      //       aria-label="Search"
      //       value={searchTerm}
      //       onKeyDown={e => onKeyDown(e)}
      //       onChange={e => onChangeSearchTerm(e.target.value)}
      //       ref={searchInputRef}
      //     />
      //   </InputGroup>

      // </Form>

      // THis was necessary for Firefox to show the "X" icon to clear search input
      <div className="input-container">
        <Form.Control
          type="search"
          placeholder="Search"
          className={'border-0 shadow-none'}
          aria-label="Search"
          value={searchTerm}
          onKeyDown={e => onKeyDown(e)}
          onChange={e => onChangeSearchTerm(e.target.value)}
          ref={searchInputRef}
        />
        <span className={searchTerm.length > 0 ? 'clear-button show' : 'clear-button'} onClick={() => onChangeSearchTerm('')}>
          <X size={18} color={'#333f48'} />
        </span>
      </div>
    )
  }

  function onKeyDownOpenUrl(e, url) {
    if (e.key === 'Enter') {
      window.open(url, "_blank");
    }
  }

  function onViewAll() {
    setShowMenu(true);
    // setShowResults(false); // lets leave this still visible for now...
  }

  function renderMegaMenu() {

    const show = showResults ? 'show' : '';

    return (

      <div className={`dropdown-menu search-results px-3 border-0 shadow ${show}`}>


        {filteredItems.length === 0 &&
          <h5 className="mt-3 mb-3">Sorry, we couldn't find anything matching “{searchTerm}” <button type="button" className="btn btn-primary btn-sm mx-3 view-all" onClick={() => onViewAll()}>View Full Menu <ArrowRight size={15} /></button></h5>
        }

        {/* <a href={`/menu?searchTerm=${searchTerm}`} className="btn btn-primary btn-sm mx-3 view-all">View All {totalCount} <ArrowRight size={15} /></a> */}
        {filteredItems.length > 0 &&
          <div className="mb-4">
            <h5 className="mt-3 mb-3">Top results for “{searchTerm}”
              {totalCount > maxCount &&
                <button type="button" className="btn btn-primary btn-sm mx-3 view-all" onClick={() => onViewAll()}>View All {totalCount} <ArrowRight size={15} /></button>
              }
            </h5>

            <div className="row px-2">
              {filteredItems.map((item, index) => {
                return (
                  <div key={index} tabIndex={0} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 search-item" onKeyDown={(e) => onKeyDownOpenUrl(e, item.url)}>
                    {/* IMPORTANT: tabIndex=-1 children */}
                    <a tabIndex={-1} href={item.url} target="_blank" rel="noreferrer" className="btn btn-link search-item-button">
                      <div className="d-flex py-3 px-1 rounded-3">
                        <div className="icon">
                          <img src={item.icon} alt={item.menuFunctionName} />
                        </div>
                        <div className="text ps-3">
                          <h5>{item.menuFunctionName}</h5>
                          <div>{item.tipText}</div>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        }

        {/* <div className="bottom mt-3 mb-3">
          <div className="left">
            <h3>Not finding what you're looking for?</h3>
            <p className="mt-2 mb-0 mx-1 fs-5">View our full menu of features, with highlights for your favorites and recently used features.</p>
            <a className="btn btn-primary btn-lg mt-3" href="#" onClick={() => setShowMenu(true)}>View Full Menu</a>
          </div>
          <div className="right align-middle">
              <a className="btn btn-primary btn-lg" href="/menu">View Full Menu</a>
          </div>
        </div> */}

      </div>
    )
  }


  function onToggleMenu() {
    setShowResults(false); // when sliding in the menu, lets close the search results mega menu
    setShowMenu(!showMenu);
  }
  function onToggleDataTransfer() {
    setShowResults(false); // when sliding in the menu, lets close the search results mega menu
    setShowDataTransfer(!showDataTransfer);
  }


  return (
    <div className="mega-menu white-on-blue">
      <Navbar collapseOnSelect expand="md" sticky>
        <a href="/home"><img src="/img/rosnet-icon.svg" alt="Rosnet" /></a>
        <Navbar.Brand href="/home">
          Restaurant Name
          <Layers strokeWidth={2} size={20} style={{ marginLeft: 5 }} />
          {/* <div className="navbar-brand-subtitle">Powered by Rosnet &reg;</div> */}
          <div style={{ fontSize: 11, marginTop: -4 }}>{dayjs(new Date().toString()).format('ddd M/D/YYYY')} W2 P2 {new Date().getFullYear()}</div>
        </Navbar.Brand>


        {!props.maintenanceMode &&
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" title="Navigation menu">
              <Menu style={{ color: "white" }} />
            </Navbar.Toggle>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="navbar-collapsed-container"
            >
              <Nav className="ml-auto ms-auto align-items-center">

                <Nav.Link href="#" onClick={() => setShowDataTransfer(true)}>
                  <img
                    src={props?.dataTransferSuccess ? '/img/topnav/transmission-status-success.png' : '/img/topnav/transmission-status-critical.png'}
                    alt="Transmission Status"
                  />
                </Nav.Link>

                {renderSearchBox(false)}
                {renderMegaMenu()}

                <Nav.Item>
                  <Link to="/home" className="nav-link">Home</Link>
                </Nav.Item>

                {/* <Nav.Item>
                  <Link to="/dashboards" className="nav-link">Dashboards</Link>
                </Nav.Item> */}

                <Nav.Link onClick={() => onToggleMenu()} data-pointerz-id="menu-link">Menu</Nav.Link>

                <Nav.Link onClick={() => setShowHelp(true)}>
                  Help
                </Nav.Link>

                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic" className="languageDropdown" data-notranslate>
                    {selectedLanguage && selectedLanguage?.label}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {languages.map((item, index) => {
                      return (
                        <Dropdown.Item key={`lang${index}`} href="#" onClick={() => onSelectLanguage(item)} data-notranslate>{item.label}</Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>


                <div className="navLinkContainer">
                  <span className="nav-badge bg-white">12</span>
                  <NavDropdown
                    title={<User size={20} />}
                    align="end"
                    id="collasible-nav-dropdown"
                  >

                    <NavDropdown.Item>
                      <Link to="/payroll-export" className="nav-link-dark">Payroll Export Form</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/payroll-export-decision-tree" className="nav-link-dark">Payroll Export Decision Tree</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/admin/assign-share-list" className="nav-link-dark">Dashboard Assign/Share Permissions</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/ui-guide" className="nav-link-dark">Rosnet UI Guidelines (WIP)</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/upnext" className="nav-link-dark">UpNext for Teams</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/voice-inventory" className="nav-link-dark">Voice Inventory Prototype</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Divider />

                    <NavDropdown.Item>
                      <Link to="/maintenance?mode=warning" className="nav-link-dark">Site Maintenance Warning</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link to="/maintenance?mode=active" className="nav-link-dark" onClick={() => window.location.href = '/maintenance?mode=active' }>Site Maintenance Active</Link>
                    </NavDropdown.Item>

                    {/* <NavDropdown.Divider />

                    <NavDropdown.Item>
                      <Link to="/home?maint=a" className="nav-link-dark" forceRefresh={true}>Maintenance Warning: Option A</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/home?maint=b" className="nav-link-dark" forceRefresh={true}>Maintenance Warning: Option B</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/home?maint=c" className="nav-link-dark" forceRefresh={true}>Maintenance Warning: Option C</Link>
                    </NavDropdown.Item> */}

                  </NavDropdown>
                </div>

              </Nav>
            </Navbar.Collapse>
          </>
        }

        {props.maintenanceMode &&

          <>

            <Navbar.Toggle aria-controls="basic-navbar-nav" title="Navigation menu">
              <Menu style={{ color: "white" }} />
            </Navbar.Toggle>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="navbar-collapsed-container"
            >
              <Nav className="ml-auto ms-auto align-items-center">

                <Nav.Link onClick={() => setShowHelp(true)}>
                  Help
                </Nav.Link>



                <div className="navLinkContainer">
                  {/* <span className="nav-badge">12</span> */}
                  <NavDropdown
                    title={<User size={20} />}
                    align="end"
                    id="collasible-nav-dropdown"
                  >

                    <NavDropdown.Item>
                      <Link to="/logout" className="nav-link-dark">Logout</Link>
                    </NavDropdown.Item>

                  </NavDropdown>
                </div>

              </Nav>
            </Navbar.Collapse>

          </>


        }


      </Navbar>

      {showResults &&
        <div className="mega-menu backdrop" onClick={() => onClearSearch()}></div>
      }


      {/* wrapping this in the same boolean var prevents React from an endless render loop */}
      {showMenu &&
        <OffcanvasMenu
          show={showMenu}
          searchTerm={searchTerm}
          selectedMenuItem={selectedMenuItem}
          onClose={() => setShowMenu(false)}
        />
      }

      {/* wrapping this in the same boolean var prevents React from an endless render loop */}
      {showDataTransfer &&
        <OffcanvasDataTransfer
          show={showDataTransfer}
          onClose={() => setShowDataTransfer(false)}
        />
      }

      {showHelp &&
        <OffcanvasHelp
          show={showHelp}
          forceRerender={forceRerender}
          onClose={() => setShowHelp(false)}
        />
      }

    </div>
  )
};


export default TopNavMegaMenu;
