import React, { useState, useEffect } from "react";

import { X } from "react-feather";

import "./Styles/Toaster.css";

const Toaster = (props) => {
    // const [toasts, setToasts] = useState(props?.toasts);

    // useEffect(() => {
    //     console.log("toasts changed", props.toasts)
    //     setToasts(props?.toasts);
    // }, [props?.toasts]);


    // useEffect(() => {

    //     setToasts(props?.toasts)

    // }, [props?.show]);


    // function onClose(item) {
    //     const newList = toasts.filter((s) => s.stringKey !== item.stringKey);
    //     console.log("newList", newList)
    //     setToasts(newList);
    // }

    return (

        <>
            {props?.show &&
                <div className="toaster-container">

                    {props?.toasts.map((toast, index) => {
                        return (
                            <div key={index} className="toaster">
                                <div className="icon"><img src={toast.icon} /></div>
                                <div className="heading">{toast.title}
                                    <div className="description">{toast.description}</div>
                                </div>
                                <div className="close-icon" onClick={() => props?.onClose(toast)}><X size={18} /></div>
                            </div>

                        );
                    })}

                </div>
            }

        </>

    );
};

export default Toaster;
