/*
  <DropdownMultiselect
      placeholder={"Companies"}
      choices={[]}
      onChange={(item) => console.log(item)}
  />


*/


import React, { useState, useEffect } from "react";
import { Check } from "react-feather";
import "./Dropdown.css";

export default function DropdownMultiselect(props) {
  const [choices, setChoices] = useState([]);
  const [filteredChoices, setFilteredChoices] = useState([]);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState(props.placeholder || "None Selected");
  const [allChecked, setAllChecked] = useState(true);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (props.choices) {
      setChoices(props.choices);
      setFilteredChoices(props.choices);
      updateLabel([...props.choices], props.prefixLabel);
      //const selected = props.choices.filter(item => item.selected)
      //console.log(props.placeholder, "selected", selected)
      setAllChecked(
        props.selected && props.selected.length !== 0 ? false : true
      );
      if (props.selected) {
        setSelectedChoices([...props.selected]);
        updateLabel([...props.selected], props.prefixLabel);
      }
    }
  }, [props.choices]);

  function onSelectItem(e, selectedIndex, selectedItem) {
    // do nothing if readonly mode
    if (props.readonly) {
      return;
    }

    let newSelectedItems = [];

    if (props.singleSelect) {
      // find just the one that has been selected, but KEEP AS AN ARRAY
      newSelectedItems = props.choices.filter(
        (s) => s.value === selectedItem.value
      );

      // for single-select lists, go ahead and close the dropdown
      setIsOpen(false);
    } else {
      // if the user is holding down the shift key, we need to select the items between the previously selected and the currently selected
      if (e.shiftKey) {
        let list = [];
        props.choices.forEach(function (item, index) {
          // if the item's index is between...
          if (index >= lastSelectedIndex && index <= selectedIndex) {
            newSelectedItems.push(item);
          }
        });
      }
      // just a normal selection...
      else {
        props.choices.forEach(function (item) {
          if (item.value.toString() === selectedItem.value.toString()) {
            // if the item is already selected, then we need to remove it
            let alreadySelected = selectedChoices.find(
              (s) => s.value.toString() === selectedItem.value.toString()
            );
            if (alreadySelected) {
              newSelectedItems = selectedChoices.filter(
                (s) => s.value.toString() !== selectedItem.value.toString()
              );
            } else {
              newSelectedItems = [...selectedChoices, selectedItem];
            }
          }
        });
      }
      // set this AFTER any shiftKey logic is done...
      setLastSelectedIndex(selectedIndex);
    }

    // if something has been selected, make sure All is unchecked
    // if nothing is selected, auto-select the All option
    setAllChecked(newSelectedItems.length === 0 ? true : false);

    //console.log("newSelectedItems", newSelectedItems)
    setSelectedChoices(newSelectedItems);
    updateLabel(newSelectedItems, props.prefixLabel);
    props.onChange(newSelectedItems); // pass selections back to parent
  }

  function onCheckAll() {
    setAllChecked(true);
    setSelectedChoices([]);
    updateLabel([]);
    props.onChange([]); // pass selections back to parent
  }

  function updateLabel(items, labelPrefix = "") {
    let label = "";
    if (props.singleSelect) {
      if (items.length > 0) {
        label = items[0].label;
      } else {
        label = props.placeholder;
      }
    } else {
      // when nothing selected, the APIs for widget data will return "all"
      if (items.length === 0) {
        label = props.placeholder ? `All ${props.placeholder}` : "All";
      } else {
        label = props.placeholder
          ? `${props.placeholder} (${items.length})`
          : "Missing Placeholder";
      }
    }
    setLabel(labelPrefix ? `${labelPrefix}: ${label}` : label);
  }

  const itemIsSelected = (item) => {
    const selectedItem = selectedChoices.find(
      (selected) => selected.value === item.value
    );
    return selectedItem || null;
  };

  function onChangeSearchTerm(term) {
    setSearchTerm(term);
    const list = props.choices.filter((item) =>
      item.label.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredChoices([...list]);
    // clear any currently selected items
    setSelectedChoices([]);
  }

  return (
    <div onMouseLeave={() => setIsOpen(false)}>
      <div className={isOpen ? "dropdown on" : "dropdown"}>
        <label className="dropdown-label" onClick={() => setIsOpen(!isOpen)}>
          {label}
        </label>

        <div className="dropdown-list prevent-text-select">
          {props.allowSearch && (
            <div className="search">
              <input
                type="text"
                className="form-control"
                style={{ marginBottom: 5 }}
                placeholder={props.searchPlaceholder}
                onFocus={() => setIsOpen(true)}
                value={searchTerm}
                onChange={(e) => onChangeSearchTerm(e.target.value)}
              />
            </div>
          )}

          {!props.singleSelect && (
            <label className="dropdown-option" onClick={onCheckAll}>
              {allChecked && (
                <Check size={25} style={{ float: "right", color: "black" }} />
              )}
              All
            </label>
          )}

          {filteredChoices &&
            filteredChoices.map((item, index) => {
              return (
                <label
                  key={"dd_" + index}
                  className="dropdown-option"
                  onClick={(e) => onSelectItem(e, index, item)}
                  data-notranslate={props?.doNotTranslate || false}
                >
                  {itemIsSelected(item) && (
                    <Check
                      size={25}
                      style={{ float: "right", color: "black" }}
                    />
                  )}
                  {item.label}
                </label>
              );
            })}
        </div>
      </div>
    </div>
  );
}
