import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';

import {
    Container, Dropdown, Modal, Button
} from "react-bootstrap";

import {
    RefreshCw,
    Download,
    Printer,
    Share,
} from "react-feather";

import cloneDeep from "lodash/cloneDeep";

import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
import Builder from "../../Components/Report/Builder";
import CopyLink from "../../Components/Report/CopyLink";
import ButtonLikeAnchor from "../../Components/Report/ButtonLikeAnchor"; // Adjust the path based on your project structure
import OffcanvasReport from "../../Components/Report/OffcanvasReport";
import OffcanvasFilters from "../../Components/Report/OffcanvasFilters";
import Toaster from "../../Components/Report/Toaster";
import Breadcrumbs from "../../Components/Report/Breadcrumbs";

import downloadIcon from '../../Images/icon-download-ready.svg';

function Report(props) {

    const [searchParams] = useSearchParams();
    const title = searchParams.get('title');
    const report = { show: false, index: 0, id: 0, title: title, description: "Description goes here..." };
    const defaultReportStack = [report]

    const [isLoading, setIsLoading] = useState(true);
    const [showShareModal, setShowShareModal] = useState(false);
    const [forceRerender, setForceRerender] = useState(null);
    const [reportStack, setReportStack] = useState(defaultReportStack);
    const [toastStack, setToastStack] = useState([]);
    const [downloadStack, setDownloadStack] = useState([]);
    const [excelAB, setExcelAB] = useState('queue');
    const [showFilters, setShowFilters] = useState(false);
    const [showDownloads, setShowDownloads] = useState(false);
    const [selectedReport, setSelectedReport] = useState(report);
    const [filters, setFilters] = useState(null);



    useEffect(() => {
        //console.log("props.forceRerender", props.forceRerender);
        document.title = `${searchParams.get('title') || 'Title Goes Here'} | PowerCenter`;
        // setIsLoading(false);

        document.title = 'Reporting | PowerCenter';
    }, []);


    useEffect(() => {
        console.log("reportStack", reportStack.length, reportStack);
    }, [reportStack]);

    useEffect(() => {
        console.log("selectedReport", selectedReport);
    }, [selectedReport]);

    function onDrilldown(parent, sub) {
        setForceRerender(new Date().toString());
        let newList = cloneDeep(reportStack);
        newList.push({ show: true, index: reportStack.length + 1, ...sub, parent });
        setReportStack(newList);
    }

    // just pop the most recent off the stack
    function onPrev() {

        console.log("onPrev", reportStack);

        // set show=false for the active report so that it slides out. Then, set timer to remove it from the stack to clean up the array
        let newList = cloneDeep(reportStack);
        newList[newList.length - 1].show = false;
        setReportStack(newList);

        console.log("onPrev newList", newList);

        // add a bit of delay, then clear the report from the stack
        setTimeout(function () {
            setReportStack(newList.slice(0, newList.length - 1));
        }, 500);

    }

    function onApplyFilters() {

        // TODO: When new filters are applied, we need to tell Builder.js to refetch data.
        let newFilters = { displayBy: 'NET', jiggleTheHandle: new Date().getTime() }
        setFilters(newFilters);

        setShowFilters(false);


    }

    function exportSelectedReport(abSwitch, yesDownload, delay) {

        console.log("export", abSwitch, yesDownload, delay);
        const fileName = encodeURIComponent(selectedReport.title);
        const time = new Date().getTime();
        const stringKey = fileName + time.toString(); // used to remove it later...


        setExcelAB(abSwitch);

        if (abSwitch === "queue") {

            // setShowFilters(false);
            setShowDownloads(true);

            console.log("stringKey", stringKey);

            let newDownloadList = cloneDeep(downloadStack);
            newDownloadList.push({ title: selectedReport.title, fileName, time, stringKey })
            setDownloadStack(newDownloadList);

        }

        else if (abSwitch === "toast") {


            let newList = cloneDeep(toastStack);
            let newItem = { title: selectedReport.title, description: `${selectedReport.title} has been queued for download`, stringKey, icon: downloadIcon };
            newList.push(newItem)
            setToastStack(newList);

            if (yesDownload) {
                let url = `//rosreports-786ef1499c8d.herokuapp.com/api/excel-report?filename=${fileName}&delay=${delay}`;

                fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
                    .then(res => res.blob())
                    .then(res => {
                        const aElement = document.createElement('a');
                        aElement.setAttribute('download', fileName + ".xlsx");
                        const href = URL.createObjectURL(res);
                        aElement.href = href;
                        aElement.setAttribute('target', '_blank');
                        aElement.click();
                        URL.revokeObjectURL(href);

                        console.log("looking to remove", newItem)
                        removeToastFromStack(newItem);


                    });
            }
        }

    }

    function removeToastFromStack(item) {
        const newList = toastStack.filter((s) => s.stringKey !== item.stringKey);
        console.log("newList", newList)
        setToastStack(newList);
    }

    function closeAll() {
        setShowFilters(false);
        setForceRerender(new Date().toString());
        // clear the stack
        setReportStack([selectedReport]);
    }


    // find the selected reportStack item and clear all items past its index
    function onSelectBreadcrumb(breadcrumb) {


        let newReportStack = cloneDeep(reportStack);

        console.log("breadcrumb", breadcrumb.index, breadcrumb.title);

        // -1 is the index for filter options
        if (breadcrumb.index === -1) {
            setShowFilters(true);
            return;
        }
        // dont clear the root report, with index=0, from the stack
        else if (breadcrumb.index === 0) {
            // for any in the reportStack past the index, set show=false so that they visually slide out before updating the stack
            newReportStack.forEach(function (item, index) {
                // skip -1 and 0...
                if (index > 0) {
                    item.show = false;
                }
            })
            setReportStack(newReportStack)
            // add a bit of delay, then recreate the report stack with whats left...
            setTimeout(function () {
                setReportStack([selectedReport])
                return;
            }, 500);


        }

        // for any in the reportStack past the index, set show=false so that they visually slide out before updating the stack
        newReportStack.forEach(function (item, index) {
            // skip -1 and 0...
            if (index > 0) {
                item.show = false;
                // REMINDER: Filter Options is at index 0, so account for that
                if (index < breadcrumb.index) {
                    item.show = true;
                }
            }
        })
        // console.log("(newReportStack", newReportStack)
        setReportStack(newReportStack)

        // add a bit of delay, then recreate the report stack with whats left...
        setTimeout(function () {
            const purgedList = newReportStack.slice(0, breadcrumb.index);
            // console.log("(newReportStack.slice(0, breadcrumbIndex)", purgedList)
            setReportStack(purgedList);
        }, 500);
    }

    function onStatusChange(status) {

        console.log("onStatusChange", status);
        setIsLoading(status.loading); // true false;
    }


    return (
        <Container fluid>
            <TopNav dataTransferSuccess={true} />
            <div
                className="oc-shared oc-report" style={{ backgroundColor: "white" }}
            >


                <div className="content-container">

                    {!isLoading &&

                        <div className="report-options">

                            {/* <Breadcrumbs
                                    items={reportStack}
                                    filterOptionsAtRoot={true}
                                    forceRerender={forceRerender}
                                    onSelectBreadcrumb={onSelectBreadcrumb} /> */}

                            <Button variant="light" onClick={() => setShowFilters(true)}>
                                <span>Filter Options</span>
                            </Button>

                            <div className="icons">


                                <ButtonLikeAnchor
                                    onClick={() => {
                                        console.log("refresh");
                                    }}
                                >
                                    <RefreshCw size={20} color={"lightGray"} />
                                </ButtonLikeAnchor>
                                
                                <ButtonLikeAnchor
                                    onClick={() => {
                                        setShowShareModal(true);
                                    }}
                                >
                                    <Share size={20} color={"#333f48"} />
                                </ButtonLikeAnchor>


                                {/* Not needed since the report is already in its own tab */}
                                {/* <ButtonLikeAnchor
                                    onClick={() => {
                                        window.open(`/report?name=a-unique-name-value-goes-here&title=${props?.report?.title}`, '_blank');
                                    }}
                                >
                                    <ExternalLink size={20} color={"#333f48"} />
                                </ButtonLikeAnchor> */}


                                <Dropdown size="md" variant="default">
                                    <Dropdown.Toggle variant="default" id="dropdown-basic" style={{ color: "#333f48" }}>
                                        <Download size={20} color={"#333f48"} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={() => exportSelectedReport('toast', true, 2000) }>Excel</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => exportSelectedReport('toast', true, 2000) }>PDF</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>


                                <ButtonLikeAnchor
                                    onClick={() => {
                                        console.log("printer");
                                    }}
                                >
                                    <Printer size={20} color={"lightGray"} />
                                </ButtonLikeAnchor>


                            </div>



                        </div>
                    }

                    <Builder
                        report={selectedReport}
                        onStatusChange={onStatusChange}
                        filters={filters}
                        forceRerender={forceRerender}
                        onDrilldown={onDrilldown} />

                </div>





                <Modal
                    show={showShareModal}
                    onHide={() => {
                        setShowShareModal(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Share Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        To share this report, copy the link below and paste it into an email
                        or text message.
                        <CopyLink
                            link={
                                "http://localhost:3000/report?link=35c87cdc-e9b1-432e-9768-05e33459e088"
                            }
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="default"
                            onClick={() => {
                                setShowShareModal(false);
                            }}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <OffcanvasFilters
                    show={showFilters}
                    forceRerender={forceRerender}
                    report={selectedReport}
                    onClose={closeAll}
                    onApplyFilters={onApplyFilters}
                    onExport={exportSelectedReport}
                />

                <Toaster
                    show={toastStack.length ? true : false}
                    toasts={toastStack}
                    onClose={removeToastFromStack}
                />


                {reportStack.map((report, index) => {
                    return (
                        <OffcanvasReport
                            key={index}
                            index={index}
                            // since the root report is the first in the stack, we need to look for index 1
                            backdrop={index === 1 ? true : false}
                            forceRerender={forceRerender}
                            report={report}
                            onClose={closeAll}
                            onPrev={onPrev}
                            onDrilldown={onDrilldown}
                            breadcrumbs={{
                                items: reportStack,
                                filterOptionsAtRoot: true
                            }}
                            onSelectBreadcrumb={onSelectBreadcrumb}
                        />
                    );
                })}

            </div>
        </Container>
    );
}

export default Report;
