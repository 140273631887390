import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import Dropdown from "../../Components/Dropdown/DropdownMultiselect";

function App() {
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Container fluid>
      <Row>
        {/* Collapsible Menu on the left, completely hidden when collapsed */}
        {menuOpen && (
          <Col 
            xs={12} 
            sm={3} 
            md={3} 
            lg={3} 
            className="bg-light p-3"
            style={{
              transition: 'width 0.3s ease',
            }}
          >
            <h5>Menu</h5>
            <ul>
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
            </ul>
          </Col>
        )}

        {/* Right Column with toggle button and grid layout */}
        <Col xs={menuOpen ? 9 : 12} className="bg-white">
          {/* Toggle Button inside the right container */}
          <Button onClick={toggleMenu} className="m-2">
            {menuOpen ? 'Hide Menu' : 'Show Menu'}
          </Button>

          {/* Grid Columns */}
          <Row>
            <Col xs={12} md={3} className="p-3 bg-primary text-white">
              Column 1
            </Col>
            <Col xs={12} md={3} className="p-3 bg-secondary text-white">
              Column 2
            </Col>
            <Col xs={12} md={3} className="p-3 bg-success text-white">
              Column 3
            </Col>
            <Col xs={12} md={3} className="p-3 bg-danger text-white">
              Column 4
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
