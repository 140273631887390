import React, { useState, useEffect } from "react";

import { Offcanvas, Button, Tabs, Tab, Badge } from "react-bootstrap";

import OffcanvasDatePicker from "../OffcanvasDatePicker/OffcanvasDatePicker";

import { ChevronRight } from "react-feather";

// import "./App.css";
// import "./ListItem.css";

import { BeatLoader } from "react-spinners";

const list = [
  {
    NotificationId: -2,
    Message: "Alerts (27) to review",
    Hyperlink:
      "https://SRR-pc3.rosnetdev.com/Gen/passthrough.cfm?MFID=0&DirectToCFM=True&ParamL1=AlertMessage.cfm"
  },
  {
    NotificationId: 184510,
    Message: "Pending Preference Change Requests (1)",
    Hyperlink:
      "https://SRR-pc3.rosnetdev.com/Gen/passthrough.cfm?MFID=974&DirectToCFM=True&Location=8098"
  },
  {
    NotificationId: 184619,
    Message: "Pending Time Off Requests (3)",
    Hyperlink:
      "https://SRR-pc3.rosnetdev.com/Gen/passthrough.cfm?MFID=795&DirectToCFM=True&View_Pending=1&Location=8098"
  },
  {
    NotificationId: 30711,
    Message: "Miscellaneous Account Purchases",
    Hyperlink:
      "https://WENDYSCORP-pc3.rosnetdev.com/Gen/passthrough.cfm?MFID=855&DirectToCFM=True&"
  },
  {
    NotificationId: 4616,
    Message: "New order guide item(s) available for (THE SYGMA NETWORK INC)",
    Hyperlink: "/FoodManagement/ClientOGManage?vendorid=640"
  },
  {
    NotificationId: 5498,
    Message: "New order guide item(s) available for (Upper Lakes Foods)",
    Hyperlink: "/FoodManagement/ClientOGManage?vendorid=731"
  },
  {
    NotificationId: 7818,
    Message: "New order guide item(s) available for (Willow Run)",
    Hyperlink: "/FoodManagement/ClientOGManage?vendorid=734"
  },
  {
    NotificationId: 33983,
    Message: "Pending order guide items exist",
    Hyperlink:
      "https://WENDYSCORP-pc3.rosnetdev.com/Gen/passthrough.cfm?MFID=859&DirectToCFM=True&"
  },
  {
    NotificationId: 6810,
    Message: "Pending Transfers to accept for WENDYS",
    Hyperlink:
      "/Purchase/TransferSummary?Concept_no=1&Location=22,97,204,251,562,564,742,748,818,831,851,855,879,892,970,976,1012,1110,1113,1172,1206,1295,1558,1613,1639,1702,1757,1761,1784,1786,1862,1885,1892,1910,1912,1938,1939,1949,1970,2038,2062,2077,2096,2138,2142,2224,2257,2268,2289,2336,2434,2435,2437,2438,2440,2448,2452,2454,2455,2522,2569,2570,2582,2600,2620,2621,2667,2671,2672,2708,3266,4066,4288,4450,5526,7548,7564,7726,7742,7743,7866,7962,8239,8257,8521,8534,8585,8590,8624,8638,8902,9000,9008,9309,9322,9420,9435,9461,9662,9764,9765,9813,9888,10420,10776,10902,10954,11122,11461,11465,11594,11598,11611,11661,11669,11671,12151,12179,12478,12947,13288"
  }
];

function OffcanvasNotifications(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      // console.log("isLoading false", new Date());
    }, 500);
  }, [props.forceRerender]);

  return (
    <Offcanvas
      show={props.show}
      onHide={props.onClose}
      placement="end"
      className="oc-custom"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>Notifications</Offcanvas.Title>
        {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          onClick={props.onClose}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="notifs">
        <OffcanvasDatePicker
          onDateChange={(date) => console.log("calendar date change", date)}
        />
        <div className="m-0 p-1">
          {isLoading && (
            <div className="centered">
              <BeatLoader color={"#004b87"} />
            </div>
          )}

          <Tabs defaultActiveKey="today" className="mt-2 mx-3">
            <Tab
              eventKey="today"
              title={
                <React.Fragment>
                  Important
                  {/* <Badge pill variant="warning" className="ms-1 bg-warning">
                    1
                  </Badge> */}
                </React.Fragment>
              }
            >
              <div className="m-3 p-1">

                <div className="list-item">
                  <div className="item-container">
                    <div className="avatar">
                      <img src="./img/icon-notif-warning.svg" alt="Help" />
                    </div>

                    <div className="title-container">
                      <div className="title">
                        <span className="time">
                        Planned System Maintenance
                        </span>
                      </div>
                      <span className="sub-title">
                        10/17 10pm-11pm CST
                      </span>

                      <div className="description">
                        <p className="mt-2">PowerCenter will be offline for scheduled maintenance, starting on 10/17 between the hours of 10pm and 11pm CST.</p>
                        <p>We apologize for any inconvenience this may cause. For urgent assistance during this time, please contact us at support@rosnet.com or call 816-746-4100. </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </Tab>
            <Tab
              eventKey="yesterday"
              title={
                <React.Fragment>
                  General
                </React.Fragment>
              }
            >

              <div className="list-container">
                {list &&
                  list.map((item, index) => {
                    return (
                      // {item.Hyperlink}
                      <a key={index} href="#">
                        <div className="list-item" href="#" target="_blank">
                          <div className="item-container">
                            <div className="avatar">
                              <img src="./img/icon-notif.svg" alt="Help" />
                            </div>
                            <div className="title-container">
                              <div className="title truncate-line-clamp">
                                {item.Message}
                              </div>
                              {/* <div className="sub-title"></div> */}
                              {/* <div className="description truncate-line-clamp">
                              Our Help Center feature can guide you to find
                              solutions to common problems and answer your
                              questions.
                            </div> */}
                            </div>
                            <Button variant="Hyperlink" className="chevron">
                              <ChevronRight color={"#004b87"} size={20} />
                            </Button>
                          </div>
                        </div>
                      </a>
                    );
                  })}
              </div>
            </Tab>


          </Tabs>

          {/* {!isLoading && (
            <div className="list-container">
              {list &&
                list.map((item, index) => {
                  return (
                    <a key={index} href="#">
                      <div className="list-item" href="#" target="_blank">
                        <div className="item-container">
                          <div className="avatar">
                            <img src="./img/icon-notif.svg" alt="Help" />
                          </div>
                          <div className="title-container">
                            <div className="title truncate-line-clamp">
                              {item.Message}
                            </div>
                          </div>
                          <Button variant="Hyperlink" className="chevron">
                            <ChevronRight color={"#004b87"} size={20} />
                          </Button>
                        </div>
                      </div>
                    </a >
                  );
                })}
            </div >
          )} */}
        </div>
      </Offcanvas.Body >
    </Offcanvas >
  );
}

export default OffcanvasNotifications;
