import React from "react";

import { Offcanvas, Button } from "react-bootstrap";

import { ChevronRight } from "react-feather";

// import "./App.css";
// import "./ListItem.css";

function OffcanvasHelp(props) {
  return (
    <Offcanvas
      show={props.show}
      onHide={props.onClose}
      placement="end"
      className="oc-custom"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>How can we help you?</Offcanvas.Title>
        {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          onClick={props.onClose}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="help">
        <div className="list-container">
          <a href="#">
            <div className="list-item" href="#" target="_blank">
              <div className="item-container">
                <div className="avatar">
                  <img src="./img/icon-help-question.svg" alt="Help" />
                </div>
                <div className="title-container">
                  <div className="title">Rosnet Help Center</div>
                  <div className="description">
                    Our Help Center feature can guide you to find solutions to
                    common problems and answer your questions.
                  </div>
                </div>
                <Button variant="Hyperlink" className="chevron">
                  <ChevronRight color={"#004b87"} size={20} />
                </Button>
              </div>
            </div>
          </a>

          <a href="#">
            <div className="list-item" href="#" target="_blank">
              <div className="item-container">
                <div className="avatar">
                  <img src="./img/icon-help-list.svg" alt="Help" />
                </div>
                <div className="title-container">
                  <div className="title">My Support Requests</div>
                  <div className="description">
                    Your list of support requests that you have reported that
                    you can use to follow up on their status.
                  </div>
                </div>
                <Button variant="Hyperlink" className="chevron">
                  <ChevronRight color={"#004b87"} size={20} />
                </Button>
              </div>
            </div>
          </a>

          <a href="#">
            <div className="list-item" href="#" target="_blank">
              <div className="item-container">
                <div className="avatar">
                  <img src="./img/icon-help-phone.svg" alt="Help" />
                </div>
                <div className="title-container">
                  <div className="title">Contact Rosnet</div>
                  <div className="description">
                    If you have any other questions or concerns, you can easily
                    get in touch with us with a phone call or via email.
                  </div>
                </div>
                <Button variant="Hyperlink" className="chevron">
                  <ChevronRight color={"#004b87"} size={20} />
                </Button>
              </div>
            </div>
          </a>

          <a href="#">
            <div className="list-item" href="#" target="_blank">
              <div className="item-container">
                <div className="avatar">
                  <img src="./img/icon-help-shield.svg" alt="Help" />
                </div>
                <div className="title-container">
                  <div className="title">Privacy Policy</div>
                  <div className="description">
                    Our privacy policy outlines how we collect, use, and protect
                    your personal information, so you can feel confident and
                    informed about how we handle your data.
                  </div>
                </div>
                <Button variant="Hyperlink" className="chevron">
                  <ChevronRight color={"#004b87"} size={20} />
                </Button>
              </div>
            </div>
          </a>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffcanvasHelp;
