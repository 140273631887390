import React, { useState, useEffect, lazy, Suspense } from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    Offcanvas,
    Tooltip,
    OverlayTrigger,
    Popover,
    DropdownButton,
    Dropdown
} from "react-bootstrap";
import { PlusCircle, Info, Edit } from "react-feather";

import * as Icon from 'react-feather';

import TopNav from "../../Components/TopNavMegaMenu/TopNavMegaMenu";
import SideMenu from "../../Components/SideMenu/SideMenu";
import LayoutMenu from "../../Components/LayoutMenu/LayoutMenu";
import WizardModal from "../../Components/Dashboard/Wizard/WizardModal";

// import sidemenuItems from "../../Components/SideMenu/SampleData/dashboard-items.json";

import { dynamicSort } from '../../Helpers/DynamicSort';
import { getChartColors } from '../../Helpers/ChartColors';

import OffcanvasDashboardWizard from "../../Components/OffcanvasDashboardWizard/OffcanvasDashboardWizard";
import OffcanvasColorPalette from "../../Components/OffcanvasColorPalette/OffcanvasColorPalette";

import MaintenanceBanner from "../../Components/Maintenance/Banner";

import "./Styles.css";
import "../../Styles/Widgets.css";

import gridLayoutOptions from "../../Components/LayoutMenu/grid-layouts.json";


const arr = [
    "barv",
    "donut",
    "barh",
    "line",
    "pie",
    "table",
    "bubble",
    "barv",
    "donut"
]
const layouts = [
    {
        "id": "4,4,4,4,4,4,4,4,4"
    },
    {
        "id": "6,6,4,4,4,12,4,4,4"
    },
    {
        "id": "9,3,3,9,9,3,3,9,12"
    }
];

const fakeDashboard = {
    title: 'Turnover Trends',
    dashboardConfig: {
        widgets: [],
        layout: "4,4,4,12,6,6"
    }
}

function Dashboards() {

    const [showWizard, setShowWizard] = useState(false);
    const [selectedGridLayout, setSelectedGridLayout] = useState(layouts[1].id.split(','))
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFullScreenModal, setShowFullScreenModal] = useState(false);

    const [dashboardList, setDashboardList] = useState([]);
    const [selectedDashboard, setSelectedDashboard] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [showFilters, setShowFilters] = useState(false)
    const [showWidgetLibrary, setShowWidgetLibrary] = useState(false)
    const [showLayouts, setShowLayouts] = useState(false)
    const [widgets, setWidgets] = useState([])
    const [itemBeingDragged, setItemBeingDragged] = useState(null)
    const [compositeFilters, setCompositeFilters] = useState(null) // null important!
    const [dashboardData, setDashboardData] = useState(null);

    const [showColorPalette, setShowColorPalette] = useState(false);
    const [chartColors, setChartColors] = useState(null)
    const [selectedWidget, setSelectedWidget] = useState(null)

    let host = "http://localhost:3100";
    // when running at Heroku
    if (document.location.href.indexOf('localhost') === -1) {
        host = ""; // port 3000 works in build version
    }
    // console.log("host", host)

    useEffect(() => {
        document.title = 'Dashboards | PowerCenter';
        const colors = getChartColors()
        // console.log("getChartColors", colors)
        setChartColors(colors)
        getData()
    }, []);


    // this listens for any global state changes to selectedDashboard ONLY
    useEffect(() => {
        //console.log("global state change to selectedDashboard", selectedDashboard)

        // make sure not null (like on page reload when this isn't available yet)
        // otherwise it causes multiple calls to APIs by the components as side effect
        if (selectedDashboard) {

            let list = []
            let distinctFilters = []

            setSelectedGridLayout(selectedDashboard.layout)

            selectedDashboard.components.map(component => {

                // add each component's filters to a distinct list to tell the composite filter component 
                // what to display
                component.filters.map(filter => {
                    let found = distinctFilters.find(function (f) {
                        return f === filter
                    })
                    if (!found) {
                        distinctFilters.push(filter)
                    }
                })

                // console.log("component", component)

                //const LazyComponent = React.lazy(() => import("../../Components/Dashboard/" + component.component))
                list.push({
                    // creating this as an "attributes" bundle to pass things more easily into the component without including the instance
                    item: { ...component },
                    colors: chartColors,
                    grid_id: list.length + 1,
                    grid_order: list.length + 1,
                    //dataPromise: ServiceHelper.Request(component),
                    instance: React.lazy(() => import("../../Components/Dashboard/" + component.component))


                })
                //console.log("item", { ...component })
            })


            setWidgets(list);

            //console.log("distinctFilters", distinctFilters)
            //setFiltersNeededForSelectedDashboard(distinctFilters)

        }

    }, [selectedDashboard])


    async function getData() {

        setIsLoading(true)

        const headers = {};
        headers["Content-Type"] = "application/json";
        //headers.Authorization = "Bearer " + userData.access_token;

        const options = {};
        options.method = "GET";
        options.headers = headers;
        //options.body = JSON.stringify(request); // POST

        let url = "http://localhost:3100/api/dashboards";
        // when running at Heroku
        if (document.location.href.indexOf('localhost') === -1) {
            url = "/api/dashboards";
        }

        try {
            const response = await fetch(url, options)
            const data = await response.json()

            // if there are dashboards for the user, default the first one to selected
            if (data && data.length > 0) {

                setDashboardData(data);

                reformatDashboardDataForSideMenu(data)

                setSelectedDashboard(data[0])

                // use spread operator to force UI to update
                setSelectedGridLayout({ ...data[0].layout, updated: true })
            }
        }
        catch {
            console.log("error fetching data")
        }


        setIsLoading(false)
    }

    function reformatDashboardDataForSideMenu(data) {
        let list = []
        // these just demonstrate the different parents for SideMenu
        let rd = { id: 1, label: "Rosnet Dashboards", subs: [] }
        let cd = { id: 2, label: "Company Dashboards", subs: [] }
        let md = { id: 3, label: "My Dashboards", subs: [] }
        data.forEach(function (item, index) {

            rd.subs.push({ id: item.dashboard_id, label: item.title })
            cd.subs.push({ id: item.dashboard_id, label: item.title })
            md.subs.push({ id: item.dashboard_id, label: item.title })

        })
        list.push(rd)
        list.push(cd)
        list.push(md)

        // console.log("list", list)
        setDashboardList(list)

    }



    function onCategorySelection(category) {
        //console.log("category", category);
    }
    function onSubSelection(sub) {

        let db = dashboardData.find(d => d.dashboard_id === sub.id);
        setSelectedDashboard({ ...db });

        // only affects mobile users
        setShowMenu(false);
    }

    // By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element
    function onDragOver(e) {
        //event.stopPropagation();
        e.preventDefault();
        //console.log("onDragOver", e.target.id)
    }

    function onDragEnter(e, widget) {
        e.stopPropagation();
        e.preventDefault();
        console.log("onDragEnter", e.target.id)
        let container_ndx = e.target.dataset.container
        //console.log("onDrageEnter", "source", itemBeingDragged, "target", widget)
    }

    function onDragStart(e, widget) {
        //console.log("onDragStart", e.target.id, "widget", widget)
        e.dataTransfer.setData("text", e.target.id);

        setItemBeingDragged(widget)
    }

    function onDrop(e, widget) {
        e.preventDefault();
        e.stopPropagation();
        //var data = e.dataTransfer.getData("text");
        //e.target.appendChild(document.getElementById(data));
        //let container_ndx = e.target.dataset.container
        //console.log("onDrop container_ndx", container_ndx)

        //console.log("onDrop", "source", itemBeingDragged, "target", widget)

        let sourceOrder = itemBeingDragged.grid_order;
        let targetOrder = widget.grid_order;

        let items = [...widgets]  // shallow copy

        //console.log("items", items)

        items.forEach(function (item) {

            // this swaps places between the source and target
            if (item.grid_id === itemBeingDragged.grid_id) {
                item.grid_order = targetOrder
            }
            else if (item.grid_id === widget.grid_id) {
                item.grid_order = sourceOrder
            }

        })

        //console.log("new items", JSON.stringify(items, null, 2))

        items.sort(dynamicSort('grid_order', 1))

        setWidgets([...items])

        // API call to persist the order change...
        let dashboardToUpdate = { ...selectedDashboard }
        dashboardToUpdate.components = items.map(e => e.item) // map reduce to just the raw item
        dashboardToUpdate.components.forEach(function (c) {
            // this swaps places between the source and target
            if (c.component_id === itemBeingDragged.item.component_id) {
                c.order = targetOrder
            }
            else if (c.component_id === widget.item.component_id) {
                c.order = sourceOrder
            }
        })
        dashboardToUpdate.components.sort(dynamicSort('order', 1))
        saveDashboardChange(dashboardToUpdate)

        // ********************************************************
        // trying to save a round trip back to the API/DB 
        // we also have to update the dashboardList locally...
        let copyDashboardList = [...dashboardList]
        copyDashboardList.forEach(function (item) {
            if (item.dashboard_id === dashboardToUpdate.dashboard_id) {
                item.components = dashboardToUpdate.components
                item.components.sort(dynamicSort('order', 1))
                //console.log("updating d...")
            }
        })
        setDashboardList([...copyDashboardList])
        // ********************************************************


    }


    function onRemoveWidget(widget) {

        //console.log("onRemoveWidget", widget)

        let dashboardToUpdate = { ...selectedDashboard }


        let filtered = dashboardToUpdate.components.filter(c => c.component_id !== widget.component_id)

        // update the state of the selectedDashboard
        dashboardToUpdate.components = filtered
        setSelectedDashboard(dashboardToUpdate)

        // API call to persist the change...
        saveDashboardChange(dashboardToUpdate)

        // ********************************************************
        // trying to save a round trip back to the API/DB 
        // we also have to update the dashboardList locally...
        let copyDashboardList = [...dashboardList]
        let found = copyDashboardList.find(function (f) {
            return f.dashboard_id === dashboardToUpdate.dashboard_id
        })
        if (found) {
            found.components = dashboardToUpdate.components
            //console.log("found and updating layout")
        }
        setDashboardList([...copyDashboardList])
        // ********************************************************

    }

    async function saveDashboardChange(dashboard) {

        //console.log("saveDashboardChange", dashboard)

        const options = {
            "method": "PUT",
            //"headers": {"Content-Type":"application/json"},
            "body": JSON.stringify(dashboard)
        };

        const response = await fetch('http://localhost:3000/api/dashboards/' + dashboard.dashboard_id, options)
        //const data = await response.json()


    }

    function onLayoutSelect(layout) {
        // use spread operator to force UI to update
        setSelectedGridLayout({ ...layout, updated: true })
    }

    function onColorPaletteChange(palette) {
        // console.log("on select palette", palette)
    }

    function onTriggerDisplayRefresh(palette) {
        // console.log("on select palette", palette)
        let newColors = getChartColors()
        // console.log("new chart colors", newColors)
        setChartColors(newColors)

        // getData()
        setSelectedDashboard({ ...selectedDashboard })
    }

    function onCloseColorPalette() {

        setShowColorPalette(false);

        // let newColors = getChartColors()
        // // console.log("new chart colors", newColors)
        // setChartColors(newColors)

        // // getData()
        // setSelectedDashboard({ ...selectedDashboard })

    }

    function onSelectChartType(c, type) {

        let newCopy = JSON.parse(JSON.stringify(selectedDashboard))
        let found = newCopy.components.find(e => e.component_id === c.item.component_id)
        if (found) {
            found.component = type
        }

        setSelectedDashboard({ ...newCopy })
    }

    function onShowFullScreen(Component) {

        setShowFullScreenModal(true)
        setSelectedWidget(Component)

        console.log("component", Component)
    }

    function displayWidgets(count) {

        return (

            <Row className="g-2">

                {widgets.map((Component, index) => {
                    return (
                        <div
                            key={index}
                            className={"widget-grid-wrapper col-12 col-sm-12 col-md-6 col-lg-" + selectedGridLayout[index]}
                            onDrop={(e) => onDrop(e, Component)}
                            onDragOver={onDragOver}
                            onDragEnter={(e) => onDragEnter(e, Component)}
                        >

                            <div className="widget--menu">
                                {/* <button className="btn" style={{ marginTop: 5, marginLeft: -10, borderColor: "transparent", marginLeft: 0, padding: "0.375rem 0.75rem 0.375rem 0" }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <Icon.Settings style={{ height: 15, color: "gray" }} />
                                </button> */}

                                <DropdownButton title={<Icon.Settings style={{ height: 15, color: "gray" }} />} variant="none">
                                    <Dropdown.Item onClick={() => console.log("add filter")} disabled><Icon.Filter style={{ height: 15, color: "silver" }} /> Add Filter</Dropdown.Item>
                                    <Dropdown.Item onClick={() => console.log("remove")} disabled><Icon.X style={{ height: 15, color: "silver" }} />Remove Widget</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onShowFullScreen(Component)}><Icon.Maximize2 style={{ height: 15, color: "gray" }} />View Full Screen</Dropdown.Item>
                                    {Component.item.component !== "Table" &&
                                        <DropdownButton className="show-toggle dropend mx-1" title={<><Icon.PieChart style={{ height: 15, color: "gray" }} />Chart Type</>} variant="none">
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "BarChartVertical")}>Vertical Bar Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "BarChartHorizontal")}>Horizontal Bar Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "LineChart")}>Line Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "PieChart")}>Pie Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "DoughnutChart")}>Donut Chart</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onSelectChartType(Component, "Table")} disabled>Table View</Dropdown.Item>
                                        </DropdownButton>
                                    }
                                </DropdownButton>

                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="#"
                                        onClick={() => onRemoveWidget(Component.item)}>
                                        <Icon.XCircle size={20} color={'gray'} strokeWidth={1} />Remove
                                    </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            <Icon.Filter size={20} color={'gray'} strokeWidth={1} />Filter
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div
                                className="widget-grid-item d-flex flex-column"
                                style={{ height: 325 }}
                                draggable="true"
                                onDragStart={(e) => onDragStart(e, Component)}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Component.instance
                                        dataPromise={Component.dataPromise}
                                        compositeFilters={compositeFilters}
                                        host={host}
                                        item={Component.item}
                                        chartColors={Component.colors}
                                        userData={{ access_token: 'faked...' }}
                                        widgetHeight={270}
                                    />
                                </Suspense>
                            </div>
                        </div>

                    )
                })}
            </Row>
        )
    }

    function renderDashbardMenuOptions() {

        return (
            <>
                <button className="btn btn-outline-default" onClick={() => getData()}>
                    <Icon.RefreshCw style={{ height: 15 }} />Refresh
                </button>
                <button className="btn btn-outline-default" onClick={() => setShowColorPalette(!showColorPalette)}>
                    <Icon.Sliders style={{ height: 15 }} />Colors
                </button>
                <button className="btn btn-outline-default" onClick={() => setShowLayouts(!showLayouts)}>
                    <Icon.Layout style={{ height: 15 }} />Layout
                </button>
                <button className="btn btn-outline-default text-black-50" data-bs-toggle="modal" onClick={() => setShowFilters(!showFilters)} data-bs-target={'#compositeFiltersModal'}>
                    <Icon.Filter style={{ height: 15 }} />Filter
                </button>
                <button className="btn btn-outline-default text-black-50" data-bs-toggle="modal" onClick={() => setShowWidgetLibrary(!showWidgetLibrary)} data-bs-target={'#widgetLibraryModal'}>
                    <Icon.Grid style={{ height: 15 }} />Widgets
                </button>
                <button className="btn btn-outline-default text-black-50">
                    <Icon.UserCheck style={{ height: 15 }} />Assign
                </button>
                <button className="btn btn-outline-default text-black-50">
                    <Icon.Share style={{ height: 15 }} />Share
                </button>
                <button className="btn btn-outline-default text-black-50">
                    <Icon.Copy style={{ height: 15 }} />Copy
                </button>
                <button className="btn btn-outline-default text-black-50">
                    <Icon.Edit style={{ height: 15 }} />Edit
                </button>
            </>
        )
    }


    return (
        <Container fluid>
            <TopNav dataTransferSuccess={false} />

            {/* <MaintenanceBanner message="Planned System Maintenance on 10/17 10pm-11pm CST" /> */}

            <Row className="p-2 g-2">
                <Col xs={12} sm={12} md={4} lg={3} xl={2} className={showMenu ? "" : "d-none d-md-block"}>
                    <SideMenu
                        // the category to select by default (0 based index)
                        defaultCategoryIndex={2}
                        // array of items in the format that the component expects
                        items={dashboardList}
                        // when a user selects a category
                        onCategorySelection={(cat) => onCategorySelection(cat)}
                        // default the selected subcategory item
                        // IMPORTANT: make sure and set defaultCategoryIndex to match the index of the parent
                        defaultSub={{ id: 1 }}
                        // when a user selects a subcategory
                        onSubSelection={(sub) => onSubSelection(sub)}
                        // heading content can be customized
                        headingContent={
                            <div className="dashboard-heading">
                                <h4>Dashboards</h4>
                                <Button
                                    variant="link"
                                    type="button"
                                    style={{ margin: 0, padding: 0 }}
                                    onClick={() => setShowWizard(true)}
                                >
                                    <PlusCircle size={24} color={"white"} />
                                </Button>
                            </div>
                        }
                    />
                </Col>


                <Col xs={12} sm={12} md={8} lg={9} xl={10} className="dashboard-wrapper">


                    <section className="dashboard-options">

                        {/* <div className="d-block d-md-none" onClick={() => setShowMenu(true)}>
                            <Menu size={24} />
                        </div> */}

                        <div className="title-desc">
                            <div className="title">
                                <h5>
                                    <span className="me-2">{selectedDashboard?.title}</span>
                                    <OverlayTrigger
                                        rootClose
                                        placement={'bottom'}
                                        trigger={'click'}
                                        overlay={
                                            <Popover id="popover-basic">
                                                <Popover.Header as="h3">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <div className="flex-grow-2">
                                                            {selectedDashboard?.title} Dashboard
                                                        </div>
                                                        <div><Edit size={16} color={'gray'} /></div>
                                                    </div>
                                                </Popover.Header>
                                                <Popover.Body>
                                                    This is where the description is displayed.
                                                    It can support up to 350 characters of text to allow the user to share instructions or comments on why this dashboard will be helpful.
                                                    <br />
                                                    <div className="mt-3">Shared by John Smith on Mar 15, 2023</div>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <Info color={'gray'} size={20} />
                                    </OverlayTrigger>
                                </h5>
                            </div>
                        </div>


                        {/* This is the horizontal row of icons */}
                        <div className="icons mb-2 d-none d-xl-block">
                            {renderDashbardMenuOptions()}
                        </div>
                        {/* Display this Options dropdown on smaller devices */}
                        <DropdownButton
                            className="d-xl-none d-lg-flex"
                            variant="elipsis"
                            // title={<MoreHorizontal color={'#333f48'} />}
                            title={'Options'}
                        >
                            {renderDashbardMenuOptions()}
                        </DropdownButton>

                    </section>

                    {displayWidgets(9)}

                </Col>

            </Row>



            {/* <OffcanvasDashboardWizard show={showWizard} onClose={() => setShowWizard(false)} /> */}
            <WizardModal show={showWizard} onHide={() => setShowWizard(false)} />


            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>About Dashboards</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This dashboard and dashboard wizard prototype are a work in progress.
                    I'm exploring a new left side panel that matches the design we're using in the Menu as well as some other UI improvments.

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        Got it
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                show={showFullScreenModal}
                // fullscreen={true}
                dialogClassName="custom-90w"
                onHide={() => setShowFullScreenModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Full Screen View</Modal.Title>
                </Modal.Header>
                {/* Overflow hidden allows the widget to control the overflow */}
                <Modal.Body style={{ overflow: 'hidden' }}>

                    {selectedWidget && <selectedWidget.instance
                        dataPromise={selectedWidget.dataPromise}
                        compositeFilters={compositeFilters}
                        host={host}
                        item={selectedWidget.item}
                        chartColors={selectedWidget.colors}
                        userData={{ access_token: 'faked...' }}
                        fullscreen={true}
                    />}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowFullScreenModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Offcanvas
                placement="end"
                show={showLayouts}
                onEntered={() => console.log('opening layout selector offcanvas')}
                onExited={() => setShowLayouts(false)}
                onHide={() => setShowLayouts(false)}
            >
                <Offcanvas.Header style={{ backgroundColor: '#004b87' }}>
                    <Offcanvas.Title style={{ color: 'white' }}>Choose a Layout</Offcanvas.Title>
                    {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
                    <button
                        type="button"
                        class="btn-close btn-close-white"
                        aria-label="Close"
                        onClick={() => setShowLayouts(false)}
                    ></button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col>
                            <p>Choose a layout that has been designed for the {selectedDashboard && selectedDashboard.components.length} widgets you have selected.</p>
                            <LayoutMenu count={widgets ? widgets.length : 0} visible={true} onLayoutSelect={onLayoutSelect} selectedDashboard={selectedDashboard} />
                        </Col>
                    </Row>

                    <div className="mt-5">
                        <h5>Would you like to rearrange your widgets?</h5>
                        <p className="mt-3">When viewing your dashboard, you can rearrange the order of you widgets by using drag-and-drop from one widget to another. This gives you full control over the layout and placement of the widgets on your dashboard.</p>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>

            {showColorPalette && <OffcanvasColorPalette show={showColorPalette} onClose={onCloseColorPalette} onColorPaletteChange={onColorPaletteChange} onTriggerDisplayRefresh={onTriggerDisplayRefresh} />}

        </Container>

    );
}

export default Dashboards;