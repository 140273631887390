// color palette source
import paletteList from "../Fixtures/color-palettes.json";


export function getChartColors() {
    let colorArray = []
    let colorPalette = paletteList[0] // default 

    // if local storage exists
    const savedPalette = localStorage.getItem('colorPalette');
    if (savedPalette) {
        colorPalette = JSON.parse(savedPalette);
    }

    // prevent mutating paletteList
    colorArray = JSON.parse(JSON.stringify(colorPalette.colors));

    // lets extend the colors array by lightening them 15% at a time, forming new set to add 4 more sets.
    for (let i = 1; i <= 5; i++) {
        colorArray.forEach(function (color, ndx) {
            // console.log("color", color)
            let newColor = lightenColor(color, i * 15); // i*10 is the percent
            if (newColor.toLowerCase() !== "#ffffff") {
                colorArray.push(newColor);
            }
        })
    }


    //console.log("paletteList mutated?", paletteList)


    return colorArray
}

function lightenColor(color, percent) {
    let num = parseInt(color.replace("#", ""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = (num >> 8 & 0x00FF) + amt,
        G = (num & 0x0000FF) + amt;
    return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
};