import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Offcanvas,
    Button,
    Form,
    InputGroup,
    Tab,
    Tabs,
    Badge
} from "react-bootstrap";

import {
    Search
} from "react-feather";

import SideMenu from "../SideMenu/SideMenu";
import LayoutMenu from "../LayoutMenu/LayoutMenu";
import Carousel from "../Carousel/Carousel";

import sidemenuItems from "../SideMenu/SampleData/wizard-items.json";

import "../../Styles/OffcanvasShared.css";
import "./OffcanvasDashboardWizard.css";

import gridLayoutOptions from "../../Fixtures/grid-layouts.json";

const items = Array(12).fill(0);

const arr = [
    "bubble",
    "donut",
    "barv",
    "line",
    "barh",
    "pie",
    "table",
    "line",
    "barv",
    "bubble",
    "pie",
    "donut",
    "barh",
    "table",
    "line",
    "bubble",
    "barv",
    "donut",
    "pie",
    "barh",
    "table",
    "donut",
    "barv",
    "line",
    "bubble",
    "barh",
    "pie",
    "table",
    "barv",
    "donut"
]



function OffcanvasDashboardWizard(props) {

    const [show, setShow] = useState(props?.show);
    const [selectedCount, setSelectedCount] = useState(0);

    const [selectedGroup, setSelectedGroup] = useState(gridLayoutOptions[0]);
    const [selectedLayout, setSelectedLayout] = useState(gridLayoutOptions[0].layouts[0]);


    const [formTitle, setFormTitle] = useState("");
    const [formDescription, setFormDescription] = useState("");

    useEffect(() => {


    }, []);



    function onCategorySelection(category) {
        //console.log("category", category);
    }
    function onSubSelection(sub) {
        // console.log("sub", sub);
    }

    function onLayoutSelect() {

    }


    function onSelectWidget(itemId) {

        
        setSelectedCount(selectedCount+1);

        // const item = document.getElementById(`widget_${itemId}`);
        // console.log("item", item);


        // var destination = document.getElementById('tabs-tab-selected');


        // const itemCopy = item.cloneNode(true);
        // itemCopy.id = `${itemId}-copy-${Date.now()}`;
        // itemCopy.classList.add('animated-item');

        // console.log("copy", itemCopy);
      
        // document.body.appendChild(itemCopy);
      
        // const itemRect = item.getBoundingClientRect();
        // console.log("itemrect", itemRect);
        // const destinationRect = destination.getBoundingClientRect();
      
        // itemCopy.style.top = `${itemRect.top}px`;
        // itemCopy.style.left = `${itemRect.left}px`;
        // itemCopy.style.width = `${itemRect.width}px`;
        // itemCopy.style.height = `${itemRect.height}px`;
      
        // console.log("itemCopy", itemCopy);

        // requestAnimationFrame(() => {
        //   itemCopy.style.top = `${destinationRect.top + destination.scrollTop}px`;
        //   itemCopy.style.left = `${destinationRect.left + destination.scrollLeft}px`;
        // });
      
        // itemCopy.addEventListener('transitionend', () => {
        //     console.log("transitioned");
        //     itemCopy.style.position = 'static';
        //     itemCopy.style.transition = 'none';
        //     //destination.appendChild(itemCopy);
        //     itemCopy.classList.remove('animated-item');
        //     let copy = document.getElementById(itemCopy.id);
        //     // for some reason this function fires twice, so avoid error on second 
        //     if(copy) {
        //         copy.parentNode.removeChild(copy);
        //     }

        //     setSelectedCount(selectedCount+1);
        // });
    }



    function displayWidgets(count) {

        const items = arr.slice(0, count);

        return (

            <Row>
                {items.map((item, index) => {
                    return (
                        <Col key={index} className="col-4 gutterless-col">
                            <section className="mb-3" data={{ item }} style={{ minHeight: 200 }} id={`widget_${index}`} onClick={() => onSelectWidget(index) }>
                                <h6>{item} Widget Title Goes Here</h6>
                                <div className="image-placeholder">
                                    <img src={`/img/widget-placeholders/${item}.svg`} alt={item} style={{ maxHeight: 200 }} />
                                </div>
                            </section>
                        </Col>
                    );
                })}
            </Row>
        )
    }

    return (
        <Offcanvas
            show={props?.show}
            onClose={props?.onClose}
            // IMPORTANT: prevent closing when the user clicks on the background
            // onHide={props?.onClose} 
            placement="end"
            className="oc-shared oc-wizard"
        >
            <Offcanvas.Header>
                <Offcanvas.Title>
                    Dashboard Wizard
                    <div className="subtitle-short">Select up to 9 widgets for your dashboard.</div>
                </Offcanvas.Title>
                <button
                    type="button"
                    className="btn-close btn-close-white"
                    aria-label="Close"
                    onClick={props.onClose}
                ></button>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="content-container">

                    <Tabs
                        defaultActiveKey="available"
                        id="tabs"
                        className="mb-3"
                    >
                        <Tab eventKey="available" title="Available">


                            <Row>
                                <Col xs={12} sm={4} md={4} lg={4} xl={3}>

                                    <SideMenu
                                        // the category to select by default (0 based index)
                                        defaultCategoryIndex={2}
                                        // array of items in the format that the component expects
                                        items={sidemenuItems}
                                        // when a user selects a category
                                        onCategorySelection={(cat) => onCategorySelection(cat)}
                                        // default the selected subcategory item
                                        // IMPORTANT: make sure and set defaultCategoryIndex to match the index of the parent
                                        // defaultSub={selectedSub}
                                        // when a user selects a subcategory
                                        onSubSelection={(sub) => onSubSelection(sub)}
                                        // heading content can be customized
                                        headingContent={
                                            <>
                                                <h4>What kind of widget can we help you find?</h4>
                                                <Form className="d-flex search" style={{ marginTop: 12 }}>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="search"
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                        />
                                                        <Button className="search-icon" variant="link">
                                                            <Search size={24} />
                                                        </Button>
                                                    </InputGroup>
                                                </Form>
                                            </>
                                        }
                                    />
                                </Col>

                                <Col xl={9}>
                                    {displayWidgets(20)}
                                </Col>


                            </Row>


                        </Tab>
                        <Tab eventKey="selected"
                                title={
                                <React.Fragment>
                                    Selected
                                    <Badge pill variant="success" style={{ marginLeft: 10 }}>
                                        {selectedCount}
                                    </Badge>
                                </React.Fragment>
                            }>


                            <Row>
                                <Col md={4}>
                                    <section className="h-100">
                                        <h5 className="mb-3">Give your dashboard a name and optional description.</h5>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="dashboardTitle">
                                                {/* <Form.Label>Give your dashboard a meaningful name</Form.Label> */}
                                                <Form.Control type="text" maxLength={40} placeholder="Name goes here ..." value={formTitle} onChange={e => setFormTitle(e.target.value)} autoFocus />
                                                <Form.Text className={formTitle.length === 40 ? 'text-danger float-end' : 'text-muted float-end'}>
                                                    {formTitle.length} / 40 {formTitle.length === 40 && <span>(Max name reached)</span>}
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group controlId="dashboardDescription">
                                                {/* <Form.Label>Description is optional, but can be helpful when sharing with others</Form.Label> */}
                                                <Form.Control as="textarea" maxLength={350} rows={2} placeholder="Description goes here ..." value={formDescription} onChange={e => setFormDescription(e.target.value)} />
                                                <Form.Text className={formDescription.length >= 350 ? 'text-danger float-end' : 'text-muted float-end'}>
                                                    {formDescription.length} / 350 {formDescription.length >= 350 && <span>(Max description reached)</span>}
                                                </Form.Text>
                                            </Form.Group>

                                        </Form>
                                    </section>
                                </Col>
                                <Col md={8}>

                                    <section className="h-100">

                                        <h5>Choose a Layout</h5>

                                        {/* <LayoutMenu count={7} visible={true} onLayoutSelect={onLayoutSelect} selectedDashboard={selectedDashboard} /> */}


                                        <Carousel
                                            // increment can be based on the size of the items you want to display
                                            increment={100}
                                            longPressSlideIncrement={7}
                                            items= {selectedGroup &&
                                                selectedGroup.layouts.map((item, index) => {
                                                return (
                                                    <div key={"grid_" + index} className={"carousel-item"}>
                                                        <button type="button" onClick={() => onLayoutSelect(item)}>
                                                            <div className={item.selected ? "card layout selected" : "card layout"}>
                                                            <div className="row g-0">
                                                                {item.id.split(",").map((l, ndx) => (
                                                                <div key={"cell_" + ndx} className={`col-${l}`}>
                                                                    <div className={item.id === selectedLayout.id ? "layout-cell selected" : "layout-cell"}>&nbsp;</div>
                                                                </div>
                                                                ))}
                                                            </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        />
                                    </section>
                                </Col>
                            </Row>



                            <Row className="mt-5">
                                <Col>
                                    <h5 className="mb-3">Use drag-and-drop to rearrange the widgets.</h5>

                                    {displayWidgets(9)}
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>





                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default OffcanvasDashboardWizard;
