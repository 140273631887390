
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container
} from "react-bootstrap";


import "./Styles.css";

function Loading() {

  const navigate = useNavigate();

  useEffect(() => {

    const searchParams = new URLSearchParams(window.location.search);
    const stay = searchParams.get('stay');

    if(!stay) {
      setTimeout(() => {
        navigate('/home')
      }, 500);
    }

    document.title = 'Loading | PowerCenter';

  }, []);

  return (
    <Container fluid className="loading">


      <div className="ph-wrapper">

        {/* Top Nav */}
        <div className="ph-topnav ph-animated">
          <div className="ph-logo left"></div>
          <div className="right">
            <div className="ph-link"></div>
            <div className="ph-link"></div>
            <div className="ph-link"></div>
            <div className="ph-link"></div>
            <div className="ph-link"></div>
          </div>
        </div>

        {/* Small CTA buttons */}
        {/* <div className="d-flex gap-3 mx-3">
          <div className="ph-cta-small ph-animated"></div>
          <div className="ph-cta-small ph-animated"></div>
          <div className="ph-cta-small ph-animated"></div>
          <div className="ph-cta-small ph-animated"></div>
          <div className="ph-cta-small ph-animated"></div>
          <div className="ph-cta-small ph-animated"></div>
          <div className="ph-cta-small ph-animated"></div>
        </div> */}

        {/* Current CTA buttons */}
        {/* <div className="row px-3">
          <div className="col-lg-2">
            <div className="ph-cta ph-animated">
              <div className="ph-icon"></div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="ph-cta ph-animated">
              <div className="ph-icon"></div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="ph-cta ph-animated">
              <div className="ph-icon"></div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="ph-cta ph-animated">
              <div className="ph-icon"></div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="ph-cta ph-animated">
              <div className="ph-icon"></div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="ph-cta ph-animated">
              <div className="ph-icon"></div>
            </div>
          </div>
        </div> */}

        <div className="d-flex gap-3 mx-3">
          <div className="ph-cta ph-animated">
            <div className="ph-icon"></div>
          </div>
          <div className="ph-cta ph-animated">
            <div className="ph-icon"></div>
          </div>          
          <div className="ph-cta ph-animated">
            <div className="ph-icon"></div>
          </div>
          <div className="ph-cta ph-animated">
            <div className="ph-icon"></div>
          </div>
          <div className="ph-cta ph-animated">
            <div className="ph-icon"></div>
          </div>
          <div className="ph-cta ph-animated">
            <div className="ph-icon"></div>
          </div>
        </div>

        {/* <div className="d-flex gap-3 mx-3">
          <div className="ph-cta ph-animated"><div className="ph-icon"></div></div>
          <div className="ph-cta ph-animated"><div className="ph-icon"></div></div>
          <div className="ph-cta ph-animated"><div className="ph-icon"></div></div>
          <div className="ph-cta ph-animated"><div className="ph-icon"></div></div>
          <div className="ph-cta ph-animated"><div className="ph-icon"></div></div>
          <div className="ph-cta ph-animated"><div className="ph-icon"></div></div>
        </div> */}

        {/* Large KPIs */}
        <div className="row px-3">
          <div className="col-lg-6">
            <div className="ph-kpi-tall ph-animated">
              <img src="/img/placeholders/large-kpi.png" alt="graph" style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ph-kpi-tall ph-animated">
              <img src="/img/placeholders/large-kpi.png" alt="graph" style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>

        {/* Smaller KPIs */}
        <div className="row px-3">
          <div className="col-lg-4">
            <div className="ph-kpi ph-animated">
              <img src="/img/placeholders/kpibottom.png" alt="graph" style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ph-kpi ph-animated">
              <img src="/img/placeholders/kpibottom.png" alt="graph" style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ph-kpi ph-animated">
              <img src="/img/placeholders/kpibottom.png" alt="graph" style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>

        {/* <div className="ph-text shorter ph-animated"></div>
        <div className="ph-text ph-animated"></div>
        <div className="ph-text short ph-animated"></div> */}
      </div>


    </Container>
  );
}

export default Loading;
