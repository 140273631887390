import React, { useState, useEffect } from "react";

import { Modal, Button } from "react-bootstrap";

import DynamicSQLCaseBuilder from './DynamicSQLCaseBuilder';

function CaseBuilderModal({ show, onHide, onApply, onUpdate, selectedGroup }) {
    const [availableDates, setAvailableDates] = useState([]);
    const [conditionGroups, setConditionGroups] = useState([]);
    const [defaultReturnValue, setDefaultReturnValue] = useState('');
    const [sql, setSQL] = useState('');

    useEffect(() => {

    }, []);

    function handleApply() {
        onApply({ conditionGroups, defaultReturnValue, sql })
        onHide()
    }
    function handleUpdate() {
        onUpdate({ conditionGroups, defaultReturnValue, sql })
        onHide()
    }
    function handleOnChange(data) {
        setDefaultReturnValue(data.defaultReturnValue)
        setConditionGroups(data.conditionGroups)
        setSQL(data.sql)
    }


    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            scrollable={true}
            show={show} onHide={() => onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>Create a Custom Column</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Create a custom column by combining other columns, comparisons, and values.</p>

                <DynamicSQLCaseBuilder selectedGroup={selectedGroup} onChange={(data) => handleOnChange(data)} />

            </Modal.Body>
            <Modal.Footer>
                {!selectedGroup &&
                <Button variant="secondary" onClick={() => handleApply()}>
                    Add Column to Export
                </Button>
                }
                {selectedGroup &&
                <Button variant="secondary" onClick={() => handleUpdate()}>
                    Update Column
                </Button>
                }
                <Button variant="light" onClick={() => onHide()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CaseBuilderModal;
