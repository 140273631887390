import { useState, useEffect } from "react";
import * as Icon from "react-feather";

import {
    Container,
    Row,
    Col,
    Offcanvas,
    Button,
    Form,
    InputGroup,
    Tab,
    Tabs,
    Badge,
    Modal,
    Accordion,
    Pagination,
    Dropdown
} from "react-bootstrap";

import styles from "./LayoutMenuNewStyles.css";

import gridLayoutOptions from "../../Fixtures/grid-layouts.json";

export default function LayoutMenu({ visible, currentLayout, widgetCount, onSelectLayout }) {
    const [gridOptions, setGridOptions] = useState(gridLayoutOptions);
    const [selectedGroup, setSelectedGroup] = useState(gridLayoutOptions[0]);

    // this is used to allow the selectedDashboard to tell this component which one is currently selected
    useEffect(() => {
        if (widgetCount) {

            // find the grid options based on the number of dashboard widgets
            let group = gridOptions.find(element => element.count === widgetCount);

            if (group && group.layouts) {
                group.layouts.forEach(function (item) {
                    item.selected = false;
                    if (item.id === currentLayout.join(',')) {
                        item.selected = true;
                    }
                });

                setSelectedGroup({ ...group });
            }

            // pre-selected the first layout when the widget count changes
            console.log("group", group.layouts[0].id.split(','))
            onSelectLayout(group.layouts[0].id.split(','))
        }
    }, [widgetCount]);

    function onLayoutSelect(layout) {
        // this will refresh the visual selection in the UI
        let selectedGroupCopy = { ...selectedGroup };
        selectedGroupCopy.layouts.forEach(function (item) {
            item.selected = false;
            if (item.id === layout.id) {
                item.selected = true;
            }
        });
        setSelectedGroup({ ...selectedGroupCopy });

        // // tell the parent what has been selected...
        onSelectLayout(layout.id.split(','));
    }

    return (
        <div>

            <div className="d-flex flex-row flex-wrap gap-3">
                {selectedGroup &&
                    selectedGroup.layouts.map((item, index) => {
                        return (
                            <div key={index}>
                                    <Button variant="link" className="btn-link-off" onClick={() => onLayoutSelect(item)} style={{ textDecoration: "none" }}>
                                        <div className={item.selected ? "layout-item selected" : "layout-item"}>
                                            <div className="row g-0">
                                                {item.id.split(",").map((l, ndx) => (
                                                    <div key={"cell_" + ndx} className={`col-${l}`}>
                                                        <div className={item.selected ? "layout-cell selected" : "layout-cell"}></div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Button>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
