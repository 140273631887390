import React, { useEffect } from "react";

import { ChevronUp, ChevronDown } from "react-feather";

import "./Styles.css";

export default function Scrollers(props) {

    useEffect(() => {

        const scrollDownIndicator = document.getElementById('scrollDownIndicator');
        const scrollUpIndicator = document.getElementById('scrollUpIndicator');
        let scrollDownInterval, scrollUpInterval;  // To store the intervals for smooth scrolling
        const scrollAmount = 100;  // Scroll by 100px each time

        // Function to show/hide indicators based on scroll position
        function handleScroll() {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Show scroll down indicator only if not at the bottom
            if (scrollPosition + windowHeight < documentHeight - 20) {
                scrollDownIndicator.classList.add('visible');
            } else {
                scrollDownIndicator.classList.remove('visible');
            }

            // Show scroll up indicator only if not at the top
            if (scrollPosition > 100) {
                scrollUpIndicator.classList.add('visible');
            } else {
                scrollUpIndicator.classList.remove('visible');
            }
        }

        // Function to start smooth scrolling down
        function startScrollingDown() {
            scrollDownInterval = setInterval(() => {
                window.scrollBy({ top: scrollAmount, behavior: 'smooth' });
            }, 100);  // Scroll every 100ms for a smooth effect
        }

        // Function to stop scrolling down
        function stopScrollingDown() {
            clearInterval(scrollDownInterval);
        }

        // Function to start smooth scrolling up
        function startScrollingUp() {
            scrollUpInterval = setInterval(() => {
                window.scrollBy({ top: -scrollAmount, behavior: 'smooth' });
            }, 100);  // Scroll up by 100px every 100ms
        }

        // Function to stop scrolling up
        function stopScrollingUp() {
            clearInterval(scrollUpInterval);
        }

        // Scroll behavior for the down indicator (on hold)
        scrollDownIndicator.addEventListener('mousedown', startScrollingDown);
        scrollDownIndicator.addEventListener('mouseup', stopScrollingDown);
        scrollDownIndicator.addEventListener('mouseleave', stopScrollingDown);  // In case the user moves the mouse away

        // Scroll behavior for the up indicator (on hold)
        scrollUpIndicator.addEventListener('mousedown', startScrollingUp);
        scrollUpIndicator.addEventListener('mouseup', stopScrollingUp);
        scrollUpIndicator.addEventListener('mouseleave', stopScrollingUp);  // In case the user moves the mouse away

        // Listen to scroll events to update the indicators' visibility
        window.addEventListener('scroll', handleScroll);

        // Run once to ensure correct indicator visibility on load
        handleScroll();


    }, [])

    return (
        <div className="scrollers">
            <div className="scroll-indicator scroll-up" id="scrollUpIndicator">
                <ChevronUp size={28} />
            </div>
            <div className="scroll-indicator scroll-down" id="scrollDownIndicator">
                <ChevronDown size={28} />
            </div>
        </div>
    );
}
