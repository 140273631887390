import React, { useState, useRef } from "react";

import { ChevronLeft, ChevronRight } from "react-feather";

import "./Carousel.css";

export default function ScrollBox(props) {
  const intervalRef = React.useRef(null);
  let scrl = useRef(null);
  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);

  // *********************************************************
  // These functions are for clicking the right and left chevrons
  // *********************************************************
  //this shifts the content left and right
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setScrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  // this checks if the content is already scrolled to the end
  const scrollCheck = () => {
    setScrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  // *********************************************************
  // These functions are for the "long press" functionality that
  // deals with the user holding the mouse button down
  // *********************************************************
  const startLongPress = (increment) => {
    if (intervalRef.current) return;
    // this will set an interval to repeat every 10 milliseconds
    // and sets the intervalRef for later use when stopping the interval
    intervalRef.current = setInterval(() => {
      slide(props.longPressSlideIncrement * increment);
    }, 10);
  };

  const onStopLongPress = () => {
    if (intervalRef.current) {
      // this helps the slide not be jerky when the mouse is released
      slide(-props.increment);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <div className="scroll-wrapper prevent-text-select">
      {scrollX !== 0 && (
        <div className="prev-button">
          <ChevronLeft
            onClick={() => slide(-props.increment)}
            onMouseDown={() => startLongPress(-1)}
            onMouseUp={onStopLongPress}
            onMouseLeave={onStopLongPress}
          />
        </div>
      )}
      {!scrollEnd && (
        <div className="next-button">
          <ChevronRight
            onClick={() => slide(+props.increment)}
            onMouseDown={() => startLongPress(1)}
            onMouseUp={onStopLongPress}
            onMouseLeave={onStopLongPress}
          />
        </div>
      )}
      <div
        className="scroll-container cta-equal-columns"
        ref={scrl}
        onScroll={scrollCheck}
      >
        {props.items}
      </div>
    </div>
  );
}
