import { uomConverter } from "./UOMConverter"

const fractions = [
	"1/8=.125",
	"1/4=.25",
	"1/3=.33",
	"1/2=.5",
	"2/3=.66",
	"3/4=.75"
]

function replaceAll(find, replace, str) {
	if (str && str.length > 0) {
		return str.replace(new RegExp(find, 'g'), replace);
	}
	else return ''
}

export const parseNameQtyUom = function (spoken) {

	spoken = spoken.toLowerCase()

	fractions.forEach(function (item) {
		let parts = item.split('=')
		spoken = replaceAll(" " + parts[0], parts[1], spoken)
	})

	let textParts = spoken.split(' ')
	let count = textParts[textParts.length - 2] // 50
	let uom = uomConverter(textParts[textParts.length - 1]) // bottles to bottle, pounds to lb
	let nameParts = textParts.slice(0, textParts.length - 2)

	let result = {
		"spoken": spoken,
		"name": nameParts.join(' '),
		"keywords": nameParts,
		"count": count,
		"uom": uom,
	}

	return result
}