import { useState } from "react"
import { Check } from "react-feather";

import "./ColorPalette.css";

// Using React state for this during the drag state was not updating in time between onDragStart and onDragEnter
// the call of setState itself is synchronous, but it does not mean that the state is updated immediately after calling setState. 
// This update is determined according to the context of our current execution environment.
let selectedPaletteId = null;

function ColorPalette(props) {

	const [itemBeingDragged, setItemBeingDragged] = useState(null)
	// const [selectedPaletteId, setSelectedPaletteId] = useState(null)

    function onDragStart(e, item) {

        // props?.onColorPaletteChange(props?.palette);

        // avoiding React state here because of latency...
        selectedPaletteId = e.target.getAttribute('data-palette-id');

        //console.log("onDragStart", e.target.id, "palette", e.target.getAttribute('data-palette-id'));
		e.dataTransfer.setData("text", e.target.id);
		setItemBeingDragged(item)
	}

	function onDragOver(e) {
		e.stopPropagation();
		e.preventDefault();
		//console.log("onDragOver", e.target.id)

        // if trying to drag into a different palette
        //if(e.currentTarget.getAttribute('data-palette-id') !== e.target.getAttribute('data-palette-id')) {
        if(selectedPaletteId !== e.target.getAttribute('data-palette-id')) {
            e.dataTransfer.dropEffect = "none";
        }

	}

	function onDragEnter(e, item) {
		e.stopPropagation();
		e.preventDefault();
		//let container_ndx = e.target.dataset.container
		//console.log("onDrageEnter", "source", itemBeingDragged, "target", item)
	}

	function onDrop(e, item, index) {
        //console.log("onDrop", item, itemBeingDragged, index);
		e.preventDefault();
		e.stopPropagation();

        // tell the parent what has moved to what new index
        props.onMoveColor(props.palette, itemBeingDragged, index);
        props?.onColorPaletteChange(props.palette);

        setItemBeingDragged(null);
	}

    return (
        <div className={props?.selectedPalette?.id === props?.palette.id ? 'palette wrapper active' : 'palette wrapper'} onClick={() => props?.onColorPaletteChange(props?.palette)}>

            <div className="palette checkbox">
                <Check size={24} color={'#D3D3D3'} />
            </div>


            {props?.palette.colors.map((hexCode, index) => {
                return (
                    <div key={index} className={ hexCode === itemBeingDragged ? 'palette color dragged' : 'palette color' } style={{ backgroundColor: hexCode }} 
                        draggable={true} 
                        onDragStart={e => onDragStart(e, hexCode, index)}
                        onDrop={(e) => onDrop(e, hexCode, index)}
                        onDragOver={onDragOver}
                        onDragEnter={(e) => onDragEnter(e, hexCode, index)}
                        data-palette-id={props?.palette.id}
                        >
                        {/* {hexCode} */}
                    </div>
                );
            })}
     

        </div>
    )
};


export default ColorPalette;
