export function CurrencyFormatter(
  x,
  decimalPlaces,
  decimalSeparator,
  groupSeparator
) {
  //console.log("CurrencyFormatter", x);
  if (x) {
    var zeros = "00000000000";
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);
    if (parts.length > 1) {
      parts[1] = parts[1] + zeros;
      parts[1] = parts[1].substring(0, decimalPlaces);
    } else {
      parts[1] = zeros.substring(0, decimalPlaces);
    }
    return parts.join(decimalSeparator);
  } else {
    return x;
  }
}
