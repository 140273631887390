import React, { useState, useEffect } from "react";

import { Offcanvas, Button } from "react-bootstrap";

import { ChevronRight } from "react-feather";

import { BeatLoader } from "react-spinners";

// import "./App.css";
// import "./ListItem.css";

function OffcanvasEmployeeRequests(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      // console.log("isLoading false", new Date());
    }, 500);
  }, [props.forceRerender]);

  return (
    <Offcanvas
      show={props.show}
      onHide={props.onClose}
      placement="end"
      className="oc-custom"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>Employee Requests</Offcanvas.Title>
        {/* the closeButton attribute for Offcanvas.Header was difficult to change the color to white */}
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          onClick={props.onClose}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="help">
        <div className="sub-header-short">
          Employee requests for shift changes, time off, and availability
          changes.
        </div>
        {isLoading && (
          <div className="centered">
            <BeatLoader color={"#004b87"} />
          </div>
        )}

        {!isLoading && (
        <div className="list-container">
          <a href="#">
            <div className="list-item" href="#" target="_blank">
              <div className="item-container">
                <div className="avatar">
                  <img
                    src="./img/icon-schedule-change-requests.svg"
                    alt="Help"
                  />
                </div>
                <div className="title-container">
                  <div className="title">Schedule Change Requests</div>
                  <div className="description">
                    Employee requests to swap, drop, and pickup shifts
                  </div>
                </div>
                <div className="count">3</div>
                <Button variant="Hyperlink" className="chevron">
                  <ChevronRight color={"#004b87"} size={20} />
                </Button>
              </div>
            </div>
          </a>

          <a href="#">
            <div className="list-item" href="#" target="_blank">
              <div className="item-container">
                <div className="avatar">
                  <img src="./img/icon-emp-time-off-requests.svg" alt="Help" />
                </div>
                <div className="title-container">
                  <div className="title">Time Off Requests</div>
                  <div className="description">
                    Employee requests for time off
                  </div>
                </div>
                <div className="count">12</div>
                <Button variant="Hyperlink" className="chevron">
                  <ChevronRight color={"#004b87"} size={20} />
                </Button>
              </div>
            </div>
          </a>

          <a href="#">
            <div className="list-item" href="#" target="_blank">
              <div className="item-container">
                <div className="avatar">
                  <img src="./img/icon-avail-change-requests.svg" alt="Help" />
                </div>
                <div className="title-container">
                  <div className="title">Availability Change Requests</div>
                  <div className="description">
                    Employee requests change their scheduling availability
                  </div>
                </div>
                <div className="count">0</div>
                <Button variant="Hyperlink" className="chevron">
                  <ChevronRight color={"#004b87"} size={20} />
                </Button>
              </div>
            </div>
          </a>
        </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffcanvasEmployeeRequests;
