/*

Data format from API...

{
  "labels": [],
  "datasets": [
    {
      "label": "current",
      "data": [67745.21]
    },
    {
      "label": "comparison",
      "data": [59224.13]
    },
    {
      "label": "variance",
      "data": [8416.21]
    },
    {
      "label": "variancePercent",
      "data": [12.44]
    }
  ]
}

Format used by the UI...

{
  title: "Sales",
  // trend values up, down, or blank (while loading)
  trend: "up",
  trendColor: "success",
  currentValue: "$67,645.21",
  comparisonLabel: "Comparison",
  comparisonValue: "$59,224.13",
  varianceLabel: "Variance",
  variancePerc: "12.44%",
  varianceAmount: "$8,416.21",

  {
  title: "Labor",
  // trend values up, down, or blank (while loading)
  trend: "up",
  trendColor: "warning",
  currentValue: "26.59%",
  comparisonLabel: "Labor",
  comparisonValue: "$15,462.61",
  varianceLabel: "Scheduled",
  variancePerc: "6.46%",
  varianceAmount: "20.13%",

*/

import formats from "../Fixtures/kpi-formats.json";
import { CurrencyFormatter } from "./CurrencyFormatter";

export function FormatData(widgetLibraryId, title, data) {
  let current = 0;
  let comparison = 0;
  let variance = 0;
  let variancePercent = 0;

  // look up the format for this widget
  let format = formats[widgetLibraryId];
  if (format === null) {
    format = {
      comparisonLabel: "Comparison",
      varianceLabel: "Variance",
      formats: {
        current: "currency",
        comparison: "currency",
        variance: "currency"
      }
    };
  }

  let formatted = {
    title,
    trend: "up", // up or down
    trendColor: "success", // success or warning
    currentValue: "",
    comparisonLabel: format.comparisonLabel,
    comparisonValue: "",
    varianceLabel: format.varianceLabel,
    variancePerc: "",
    varianceAmount: ""
  };

  // loop through the dataset and pluck the values out of the array
  data.datasets.forEach(function (item, index) {
    if (item.label === "current") {
      current = item.data[0];
      formatted.currentValue = applyFormat(
        format.formats.current,
        item.data[0]
      );
    } else if (item.label === "comparison") {
      comparison = item.data[0];
      formatted.comparisonValue = applyFormat(
        format.formats.comparison,
        item.data[0]
      );
    } else if (item.label === "variance") {
      variance = item.data[0];
      formatted.varianceAmount = applyFormat(
        format.formats.variance,
        item.data[0]
      );
    } else if (item.label === "variancePercent") {
      variancePercent = item.data[0];
      formatted.variancePerc = item.data[0].toString() + "%";
    }
  });

  // ************************************************************************
  // Look at the "rule" in the trendCalc for the widget to determine how
  // arrive at an up/down trend.
  // For reverse-variancePercent (only Labor currently), up is down and
  // down is up (i.e. if labor is down, that is a good thing).
  // ************************************************************************
  if (format.trendCalc === "current-comparison") {
    if (current < comparison) {
      formatted.trend = "down";
      formatted.trendColor = "critical";
    }
  } else if (format.trendCalc === "reverse-variancePercent") {
    formatted.trend = "down"; // down is good
    formatted.trendColor = "success";
    if (variancePercent <= 0) {
      formatted.trend = "up"; // up is bad
      formatted.trendColor = "critical";
    }
  }

  // console.log("formatted", widgetLibraryId, formatted);
  return formatted;
}

function applyFormat(format, value) {
  if (format === "currency") {
    return "$" + CurrencyFormatter(value, 2, ".", ",");
  } else if (format === "numeric") {
    return CurrencyFormatter(value, 2, ".", ",");
  } else if (format === "percent") {
    return value.toString() + "%";
  } else return value;
}
